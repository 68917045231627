import {Formik} from "formik";
import {colors} from "styles";
import {Fragment, useEffect} from "react";
import {useTheme} from "styled-components";
import {useLocation} from "react-router-dom";
import {ArrowRight, Check} from "react-feather";
import {signUp} from "@redux/slices/auth/thunks";
import {BaseSignUpFormPayload} from "interfaces";
import {referralCodeSchema} from "./form.schema";
import {useAppDispatch, useAppSelector} from "hooks";
import {findUserByReferralCode} from "@redux/slices/users/thunks";
import {
  Row,
  Chip,
  Line,
  Input,
  Avatar,
  Button,
  Column,
  Typography,
} from "components";

interface ReferralCodeProps {
  data: BaseSignUpFormPayload;
}

export const ReferralCode: React.FC<ReferralCodeProps> = ({data}) => {
  const {search} = useLocation();
  const {white, disabledButtonTextColor, primary} = useTheme();
  const dispatch = useAppDispatch();
  const {referralUser, request} = useAppSelector("users");
  const referralCode: string | undefined = search.split("=")[1] ?? "";

  useEffect(() => {
    if (referralCode) dispatch(findUserByReferralCode(referralCode));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [referralCode]);

  return (
    <Column
      style={{
        padding: 20,
        width: "100%",
        margin: "auto",
        borderRadius: 5,
        backgroundColor: white,
      }}
    >
      <Formik
        initialValues={{referralCode: referralCode}}
        validationSchema={referralCodeSchema}
        onSubmit={(payload) => {
          dispatch(signUp({...data, ...payload}));
        }}
      >
        {({
          values,
          errors,
          touched,
          handleBlur,
          handleSubmit,
          handleChange,
        }) => {
          const disabled = !values["referralCode"];

          return (
            <Fragment>
              <Column width="100%">
                <Typography fontSize={16} fontFamily="Poppins-Medium">
                  {referralCode
                    ? "¡Código de referido encontrado!"
                    : "¿Tienes un código de referido?"}
                </Typography>
                {request["referralUser"]["success"] ? (
                  <Column gap={5}>
                    <Row alignItems="flex-start">
                      <Avatar
                        size={40}
                        border={`2px solid ${primary}`}
                        src={referralUser["picture"]}
                      />
                      <Column gap={0}>
                        <Chip backgroundColor={primary}>Te ha invitado</Chip>
                        <Typography fontFamily="Poppins-Medium">
                          {referralUser["name"]} {referralUser["lastname"]}
                        </Typography>
                        <Typography fontSize={13}>
                          Código de referido: {referralUser["referralCode"]}
                        </Typography>
                      </Column>
                    </Row>
                  </Column>
                ) : (
                  <Input
                    maxLength={7}
                    name="referralCode"
                    placeholder="HS2024"
                    label="Código de referido"
                    style={{textAlign: "center"}}
                    value={values["referralCode"]}
                    touched={touched["referralCode"]}
                    onBlur={handleBlur("referralCode")}
                    helperErrorText={errors["referralCode"]}
                    onChange={handleChange("referralCode")}
                  />
                )}
              </Column>
              <Column width="100%">
                <Button
                  loaderId="sign-up"
                  disabled={disabled}
                  style={{width: "100%"}}
                  backgroundColor={primary}
                  onClick={() => handleSubmit()}
                  label={
                    referralCode ? "Aceptar y registrarme" : "Confirmar código"
                  }
                  leftIcon={
                    <Check color={disabled ? disabledButtonTextColor : white} />
                  }
                />
                {!referralCode && (
                  <Fragment>
                    <Line margin={0} />
                    <Button
                      loaderId="sign-up"
                      label="Continuar sin código"
                      rightIcon={<ArrowRight color={colors["white"]} />}
                      style={{width: "100%"}}
                      onClick={() => {
                        dispatch(signUp({...data, referralCode: ""}));
                      }}
                    />
                  </Fragment>
                )}
              </Column>
            </Fragment>
          );
        }}
      </Formik>
    </Column>
  );
};
