import {Container} from "./style";
import {Image, X} from "react-feather";
import {useRef, useState} from "react";
import {useTheme} from "styled-components";
import {updatePhoto} from "@redux/slices/auth/thunks";
import {useModal, useAppDispatch, useAppSelector} from "hooks";
import {Avatar, Button, IconButton, Row, Typography} from "components";

interface UpdateAvatarPreviewProps {}

export const UpdateAvatarPreview: React.FC<UpdateAvatarPreviewProps> = () => {
  const {closeModal} = useModal();
  const dispatch = useAppDispatch();
  const {primary, black} = useTheme();
  const {user} = useAppSelector("auth");
  const [file, setFile] = useState<File>();
  const inputFile = useRef<HTMLInputElement>(null);
  const [preview, setPreview] = useState<string>(user["picture"]);

  return (
    <Container>
      <input
        id="file"
        type="file"
        ref={inputFile}
        style={{display: "none"}}
        accept="image/png, image/gif, image/jpg"
        onChange={({target}) => {
          const {files} = target;
          if (files) {
            const file = files[0];
            setFile(file);
            setPreview(URL.createObjectURL(file));
          }
        }}
      />
      <Row justifyContent="space-between">
        <Typography fontFamily="Poppins-Medium" fontSize={16}>
          Actualizar foto
        </Typography>
        <IconButton
          onClick={closeModal}
          icon={<X color={black} />}
          style={{borderRadius: 100, backgroundColor: "rgba(0, 0, 0, .03)"}}
        />
      </Row>
      <Row margin="1rem 0" justifyContent="center">
        <Avatar
          size={120}
          src={preview}
          borderWidth={2}
          borderColor={primary}
        />
      </Row>
      <Row justifyContent="center" gap={10}>
        <Button
          label={file ? "Elegir otra foto" : "Subir foto"}
          variant="outlined"
          borderColor={primary}
          typographyProps={{color: primary}}
          leftIcon={<Image color={primary} />}
          onClick={async () => {
            if (inputFile["current"]) inputFile["current"]["click"]();
          }}
        />
        <Button
          label="Actualizar"
          loaderId="update-photo"
          backgroundColor={primary}
          disabled={typeof file === "undefined"}
          onClick={() => {
            if (file) dispatch(updatePhoto({picture: file}));
          }}
        />
      </Row>
    </Container>
  );
};
