import {PaymentMethod} from "interfaces";

export const paymentMethodVariants: Record<
  number,
  {
    image: any;
    label: string;
    description: string;
    UIMethod: PaymentMethod;
  }
> = {
  0: {
    UIMethod: "bank",
    label: "Transferencia Bancaria",
    image: "/assets/payment_methods/bank.png",
    description: "Desde cualquier banco",
  },
  1: {
    UIMethod: "mobile-payment",
    label: "Pago móvil",
    image: "/assets/payment_methods/mobile-payment.png",
    description: "Rápido y confiable",
  },
  2: {
    UIMethod: "binance",
    label: "Binance",
    image: "/assets/payment_methods/binance.png",
    description: "Usando USDT",
  },
  3: {
    UIMethod: "zelle",
    label: "Zelle",
    image: "/assets/payment_methods/zelle.png",
    description: "Desde tu cuenta de Zelle",
  },
  4: {
    UIMethod: "credit",
    label: "Cupón",
    image: "/assets/tickets.png",
    description: "Usa tus cupones para pagar estos tickets",
  },
};
