import {api} from "api";
import {setLotteryRequestStatus} from ".";
import {createAsyncThunk} from "@reduxjs/toolkit";
import {thunkBadRequest} from "@redux/thunk-error";
import {setRequestStatus} from "@redux/request-status";
import {LotteryModel, LotteryNumber} from "interfaces";

export const get = createAsyncThunk(
  "lottery/get",
  async (
    config: {refresh?: boolean} | undefined = {refresh: true},
    {dispatch, rejectWithValue, getState}
  ) => {
    try {
      if (config["refresh"]) {
        dispatch(
          setLotteryRequestStatus({
            name: "lottery",
            status: setRequestStatus("initial"),
          })
        );
      }

      return await api.Get<LotteryModel[]>(
        "/lottery/getAll?limit=1000&position=0"
      );
    } catch (error) {
      thunkBadRequest(dispatch, error);
      return rejectWithValue(error);
    }
  }
);

export const getMyNumbers = createAsyncThunk(
  "lottery/get-my-numbers",
  async (lotteryId: string, {dispatch, rejectWithValue}) => {
    try {
      return await api.Get<LotteryNumber[]>(
        `/lottery/getMyNumbers/${lotteryId}`
      );
    } catch (error) {
      thunkBadRequest(dispatch, error);
      return rejectWithValue(error);
    }
  }
);
