import moment from "moment";
import {colors} from "styles";
import {getConfig} from "utils";
import {useAppSelector} from "hooks";
import {ReferralModel} from "interfaces";
import {Row, Column, Typography, Avatar, ProgressBar} from "components";

interface FriendProps {
  friend: ReferralModel;
}

export const Friend: React.FC<FriendProps> = ({friend}) => {
  const {configs} = useAppSelector("config");
  const {picture, name, lastname, createdAt, totalTicketsPurchased} = friend;
  const goal = Number(getConfig(configs, "referral_goal")["value"]);
  const displayValue =
    totalTicketsPurchased >= goal ? goal : totalTicketsPurchased;

  return (
    <Column gap={15}>
      <Row gap={5}>
        <Avatar size={40} src={picture} />
        <Column gap={0}>
          <Typography fontSize={14}>
            {name} {lastname}
          </Typography>
          <Typography fontFamily="Poppins-Medium" fontSize={12} color="#999999">
            Se registró {moment(createdAt).fromNow()}
          </Typography>
        </Column>
      </Row>
      <ProgressBar
        max={goal}
        value={displayValue ?? 0}
        progressBarColor={colors["primary"]}
        progressBarLabel="Tickets comprados"
      />
    </Column>
  );
};
