import moment from "moment";
import {colors} from "styles";
import {useModal} from "hooks";
import {rankColor} from "utils";
import {X} from "react-feather";
import {AwardModel} from "interfaces";
import {useTheme} from "styled-components";
import {
  Row,
  Line,
  Image,
  Avatar,
  Column,
  IconButton,
  RankCircle,
  Typography,
} from "components";

interface WinnerAwardProps {
  award: AwardModel;
}

export const WinnerAward: React.FC<WinnerAwardProps> = ({award}) => {
  const {white} = useTheme();
  const {closeModal} = useModal();
  const {number, title, description, media, position} = award;
  const {name, lastname, createdAt} = number["userFound"];

  return (
    <Column
      width="90%"
      margin="auto"
      padding="1rem"
      borderRadius={10}
      backgroundColor={white}
    >
      <Row justifyContent="space-between" width="100%">
        <Typography fontSize={17} fontFamily="Poppins-Medium">
          Ganador, Posición #{position}
        </Typography>
        <IconButton
          size={45}
          style={{borderRadius: 100}}
          onClick={() => closeModal()}
          icon={<X color={colors["black"]} />}
          backgroundColor="rgba(0, 0, 0, 0.03)"
        />
      </Row>
      <Row alignItems="flex-start" gap={10}>
        <Column>
          <Avatar
            size={50}
            borderWidth={2}
            borderColor={rankColor[1]}
            src={number["userFound"]["picture"]}
          />
          <Column alignItems="center" gap={0} transform="translateY(-20px)">
            <RankCircle
              size={25}
              rank={Number(position)}
              typographyProps={{fontSize: 12}}
            />
          </Column>
        </Column>
        <Column gap={0}>
          <Typography fontSize={16} fontFamily="Poppins-Medium">
            {name} {lastname}
          </Typography>
          <Typography color="#999999" fontSize={13}>
            Se registró {moment(createdAt).fromNow()}
          </Typography>
          <Typography>
            Ticket Ganador{" "}
            <Typography fontFamily="Poppins-Medium">
              #{number["number"]}
            </Typography>
          </Typography>
        </Column>
      </Row>
      <Column transform="translateY(-18px)">
        <Line margin=".3rem 0" />
        <Typography fontSize={12}>Premio ganado</Typography>
        <Row gap={10}>
          <Image src={media[0]} size={80} objectFit="contain" />
          <Typography
            fontSize={14}
            style={{width: "70%"}}
            className="line-clamp-2"
            fontFamily="Poppins-Medium"
          >
            {title}, {description}
          </Typography>
        </Row>
      </Column>
    </Column>
  );
};
