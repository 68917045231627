import * as Yup from "yup";
import {VerifyWithSMSPayload} from "interfaces";

export const initialValues: VerifyWithSMSPayload = {
  phone: "",
};

export const verifySMSSchema = Yup.object().shape({
  phone: Yup.string().required("El número telefónico es requerido"),
});
