import {colors} from "styles";
import {useListener} from "pages/Home/hooks";
import {Column, SpinnerLoader, Typography, Image} from "components";

interface WaitingForLiveProps {}

export const WaitingForLive: React.FC<WaitingForLiveProps> = () => {
  useListener();

  return (
    <Column gap={15} paddingTop={15} alignItems="center">
      <SpinnerLoader />
      <Column gap={5} alignItems="center">
        <Typography
          color={colors["white"]}
          fontFamily="Poppins-Medium"
          style={{textAlign: "center"}}
        >
          La transmisión en{" "}
          <Typography fontFamily="Poppins-Bold" color={colors["secondary"]}>
            VIVO
          </Typography>{" "}
          comenzará en breve...
        </Typography>
        <Typography
          color={colors["white"]}
          fontFamily="Poppins-SemiBold"
          style={{textAlign: "center", fontSize: 18}}
        >
          ¡El mayor de los éxitos a todos!
        </Typography>
        <Image src="/assets/logo.png" objectFit="contain" />
      </Column>
    </Column>
  );
};
