import {useEffect} from "react";
import {useAppDispatch, useAppSelector} from "hooks";
import {Input, Column, Row, Select} from "components";
import {onChangeUserPayloadReport} from "@redux/slices";
import {buildSelectOptions, dniPrefix, totalVES} from "utils";

interface BankProps {}

export const Bank: React.FC<BankProps> = () => {
  const dispatch = useAppDispatch();
  const {banks} = useAppSelector("payment");
  const {dni: defaultDNI} = useAppSelector("auth")["user"];
  const {participatedNumbers, userPayloadReport} = useAppSelector("payment");
  const {dni} = userPayloadReport;

  useEffect(() => {
    dispatch(onChangeUserPayloadReport({key: "dni", value: defaultDNI}));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Column width="100%">
      <Input
        disabled
        label="Monto (Bs)"
        value={totalVES(participatedNumbers)}
      />
      <Column>
        <Select
          label="Banco emisor"
          options={buildSelectOptions(banks, "Selecciona el banco", {
            keyLabel: "name",
            keyValue: "code",
          })}
          onChange={({target}) => {
            dispatch(
              onChangeUserPayloadReport({
                key: "codeBank",
                value: target["value"],
              })
            );
          }}
        />
        <Input
          inputMode="numeric"
          placeholder="0000000000"
          label="Nro de referencia"
          containerStyle={{width: "100%"}}
          onChange={({target}) =>
            dispatch(
              onChangeUserPayloadReport({key: "code", value: target["value"]})
            )
          }
        />
        <Row>
          <Select
            label="Tipo"
            options={dniPrefix}
            containerStyle={{width: "20%"}}
            onChange={({target}) => {
              dispatch(
                onChangeUserPayloadReport({
                  key: "dniPrefix",
                  value: target["value"],
                })
              );
            }}
          />
          <Input
            value={dni}
            label="Cédula"
            inputMode="numeric"
            placeholder="90111222"
            containerStyle={{width: "78.5%"}}
            onChange={({target}) =>
              dispatch(
                onChangeUserPayloadReport({key: "dni", value: target["value"]})
              )
            }
          />
        </Row>
      </Column>
    </Column>
  );
};
