import {api} from "api";
import {go} from "../navigation";
import {RootState} from "@redux/store";
import {LotteryWinnersModel} from "interfaces";
import {createAsyncThunk} from "@reduxjs/toolkit";
import {thunkBadRequest} from "@redux/thunk-error";

export const get = createAsyncThunk(
  "winners/get",
  async (_, {dispatch, rejectWithValue, getState}) => {
    const {seen} = (getState() as RootState)["winners"];
    try {
      const response = await api.Get<LotteryWinnersModel>("/lottery/winners");
      if (!seen) dispatch(go("/winners"));
      return response;
    } catch (error) {
      thunkBadRequest(dispatch, error);
      return rejectWithValue(error);
    }
  }
);
