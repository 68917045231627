import {X} from "react-feather";
import {motion} from "framer-motion";
import {CSSAlignment} from "./helpers";
import {toggleBodyScroll} from "utils";
import {Fragment, useEffect} from "react";
import {ModalConfig} from "interfaces/modal";
import {useKeydown} from "./hooks/useKeydown";
import {useModal, useAppSelector} from "hooks";
import {CloseButton, Backdrop, Container, motionStyle} from "./styled";

interface UIProps {
  config: ModalConfig;
  element: JSX.Element;
}

export const UI: React.FC<UIProps> = ({element, config}) => {
  useKeydown();
  const {closeModal} = useModal();
  const {
    style,
    alignment,
    animation,
    onBackdrop,
    closeButton,
    closeOnBackdrop,
    backgroundColor,
  } = config;
  const {
    icon,
    show,
    onClose,
    defaultIconColor = "#fff",
    style: closeButtonStyle,
  } = closeButton;

  return (
    <Container
      style={{
        ...style,
        backgroundColor,
        alignItems: CSSAlignment[alignment]["alignItems"],
        justifyContent: CSSAlignment[alignment]["justifyContent"],
      }}
    >
      <Backdrop
        onClick={() => {
          if (closeOnBackdrop) closeModal();
          if (typeof onBackdrop === "function") onBackdrop();
        }}
      />
      <motion.div
        style={motionStyle}
        animate={animation["animate"]}
        initial={animation["initial"]}
      >
        {element}
      </motion.div>
      {show && (
        <CloseButton
          style={closeButtonStyle}
          children={icon ?? <X strokeWidth={2.5} color={defaultIconColor} />}
          onClick={() => {
            closeModal();
            if (typeof onClose === "function") onClose();
          }}
        />
      )}
    </Container>
  );
};

export const Modal = () => {
  const {closeModal} = useModal();
  const {stack} = useAppSelector("modal");

  useEffect(() => {
    const currentModal = stack[stack.length - 1];
    if (currentModal) {
      const {autoCloseMS} = currentModal["config"];
      if (typeof autoCloseMS === "number") {
        setTimeout(() => {
          closeModal();
        }, autoCloseMS);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stack]);

  useEffect(() => {
    setTimeout(() => {
      toggleBodyScroll(!!stack["length"]);
    }, 50);
  }, [stack]);

  return (
    <Fragment>
      {stack.map(({element, config}, key) => (
        <UI key={key} element={element} config={config as ModalConfig} />
      ))}
    </Fragment>
  );
};
