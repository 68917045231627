import {Tickets} from "./lib";
import {useAppSelector} from "hooks";
import {ChevronRight} from "react-feather";
import {useTheme} from "styled-components";
import {useNavigate} from "react-router-dom";
import {Column, ProgressBar, Screen, Typography, Warning} from "components";

interface SelectTicketsProps {}

export const SelectTickets: React.FC<SelectTicketsProps> = () => {
  const push = useNavigate();
  const {lottery} = useAppSelector("lottery");
  const {disabledButtonTextColor, white} = useTheme();
  const {ticketsSelected} = useAppSelector("payment");
  const {totalTicketsSold, numbers, _id} = lottery;
  const disabled = !ticketsSelected["length"];

  return (
    <Screen
      header={{
        onBack: () => push(`/lottery/${_id}`),
        title: "Elegir Tickets",
        rightButton: {
          disabled,
          label: "Siguiente",
          style: {padding: "0 15px"},
          onClick: () => push(`/lottery/${_id}/payment-methods`),
          rightIcon: (
            <ChevronRight
              width={22}
              height={22}
              color={disabled ? disabledButtonTextColor : white}
            />
          ),
        },
      }}
    >
      <Column width="100%" padding="0 15px">
        <Warning
          title="Recordatorio"
          message="Puedes elegir cuantos tickets desees. Solamente recuerda que el costo es por ticket. Entre más tengas más posibilidades"
        />
        <Column width="100%">
          <Typography fontFamily="Poppins-Light" fontSize={14}>
            Progreso del sorteo
          </Typography>
          <ProgressBar value={totalTicketsSold} max={numbers} />
        </Column>
        <Tickets />
      </Column>
    </Screen>
  );
};
