import moment from "moment";
import {Row} from "components";
import {Countdown} from "components";
import {useAppSelector} from "hooks";

interface ActionsProps {}

export const Actions: React.FC<ActionsProps> = () => {
  const {end_date} = useAppSelector("lottery")["lottery"];
  return (
    <Row>
      <Countdown
        onEnded={() => {}}
        timestamp={moment(moment(end_date)).diff(moment(), "seconds")}
      />
    </Row>
  );
};
