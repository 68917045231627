import {api} from "api";
import {postFormData} from "utils";
import {RootState} from "@redux/store";
import {createAsyncThunk} from "@reduxjs/toolkit";
import {thunkSuccess} from "@redux/thunk-success";
import {thunkBadRequest} from "@redux/thunk-error";
import {close, go, showAlert, toggleButtonLoaderId} from "..";
import {
  APIResponse,
  CreditModel,
  LoginPayload,
  Authenticated,
  UpdateUserPayload,
  ReferralCodePayload,
  VerifyWithSMSPayload,
  BaseSignUpFormPayload,
  VerifyExtraDataPayload,
  RecoverPasswordPayload,
  UpdateUserPicturePayload,
  ValidateRecoverCodePayload,
  ChangePasswordWithCodePayload,
  ValidateAccountVerificationCodePayload,
} from "interfaces";

export const signUp = createAsyncThunk(
  "auth/sign-up",
  async (
    payload: BaseSignUpFormPayload & ReferralCodePayload,
    {rejectWithValue, dispatch}
  ) => {
    dispatch(toggleButtonLoaderId("sign-up"));

    try {
      const authenticated = await api.Post<Authenticated>(
        "/users/register",
        payload
      );
      dispatch(close());
      thunkSuccess(dispatch, {title: "Cuenta creada con éxito"});
      dispatch(go("/"));
      return authenticated;
    } catch (error) {
      dispatch(close());
      thunkBadRequest(dispatch, error);
      return rejectWithValue(error);
    } finally {
      dispatch(toggleButtonLoaderId("sign-up"));
    }
  }
);

export const login = createAsyncThunk(
  "auth/login",
  async (payload: LoginPayload, {rejectWithValue, dispatch}) => {
    dispatch(toggleButtonLoaderId("login"));

    const body: {email: string; password: string} = {
      email: payload["phone"],
      password: payload["password"],
    };

    try {
      const authenticated = await api.Post<Authenticated>("/users/login", body);

      thunkSuccess(dispatch, {title: "Inicio de sesión con éxito"});
      dispatch(go("/"));
      return authenticated;
    } catch (error) {
      thunkBadRequest(dispatch, error);
      return rejectWithValue(error);
    } finally {
      dispatch(toggleButtonLoaderId("login"));
    }
  }
);

export const recoverPassword = createAsyncThunk(
  "auth/recover-password",
  async (payload: RecoverPasswordPayload, {rejectWithValue, dispatch}) => {
    dispatch(toggleButtonLoaderId("forgot-password"));

    try {
      await api.Post<null>("/users/forgetPasswordPhone", {
        phone: payload["phone"],
      });
      thunkSuccess(dispatch, {
        title: "Código enviado con éxito",
        message: "Recibirás un mensaje de texto con el código",
      });
      dispatch(go(`/auth/validate-recover-code/${payload["phone"]}`));
    } catch (error) {
      thunkBadRequest(dispatch, error);
      return rejectWithValue(error);
    } finally {
      dispatch(toggleButtonLoaderId("forgot-password"));
    }
  }
);

export const validateRecoverCode = createAsyncThunk(
  "auth/validate-recover-code",
  async (payload: ValidateRecoverCodePayload, {rejectWithValue, dispatch}) => {
    dispatch(toggleButtonLoaderId("validate-recover-code"));

    try {
      const authenticated = await api.Post<Authenticated>(
        "/users/loginWithCodePhone",
        payload
      );
      thunkSuccess(dispatch, {
        title: "Código verificado",
        message: "Ahora puedes cambiar tu contraseña",
      });
      dispatch(
        go(`/auth/change-password/${payload["code"]}/${payload["phone"]}`)
      );
      return authenticated;
    } catch (error) {
      thunkBadRequest(dispatch, error);
      return rejectWithValue(error);
    } finally {
      dispatch(toggleButtonLoaderId("validate-recover-code"));
    }
  }
);

export const changePasswordWithCode = createAsyncThunk(
  "auth/change-password-with-code",
  async (
    {confirm_password, ...payload}: ChangePasswordWithCodePayload,
    {rejectWithValue, dispatch}
  ) => {
    dispatch(toggleButtonLoaderId("change-password-with-code"));

    try {
      await api.Put<null>("/users/changePasswordWithCodePhone", payload);
      thunkSuccess(dispatch, {
        title: "Contraseña actualizada con éxito",
        message:
          "Hemos iniciado tu sesión. Ya puedes participar en nuestros sorteos",
      });
      dispatch(go("/"));
    } catch (error) {
      thunkBadRequest(dispatch, error);
      return rejectWithValue(error);
    } finally {
      dispatch(toggleButtonLoaderId("change-password-with-code"));
    }
  }
);

export const updateUser = createAsyncThunk(
  "auth/update-user",
  async (payload: UpdateUserPayload, {rejectWithValue, dispatch}) => {
    dispatch(toggleButtonLoaderId("update-user"));

    try {
      const authenticated = await api.Put<Authenticated>(
        "/users/update",
        payload
      );
      thunkSuccess(dispatch, {title: "Tus datos han sido actualizados"});
      return authenticated;
    } catch (error) {
      thunkBadRequest(dispatch, error);
      return rejectWithValue(error);
    } finally {
      dispatch(toggleButtonLoaderId("update-user"));
    }
  }
);

export const getMyCredits = createAsyncThunk(
  "auth/get-my-credits",
  async (_, {rejectWithValue, dispatch, getState}) => {
    const {user} = (getState() as RootState)["auth"];

    try {
      return await api.Get<CreditModel>(`/users/getCredits/${user["_id"]}`);
    } catch (error) {
      thunkBadRequest(dispatch, error);
      return rejectWithValue(error);
    }
  }
);

export const sendVerificationSMS = createAsyncThunk(
  "auth/verification-sms",
  async (
    payload: VerifyWithSMSPayload,
    {rejectWithValue, dispatch, getState}
  ) => {
    dispatch(toggleButtonLoaderId("verify-with-sms"));
    const {SMSProvider} = (getState() as RootState)["auth"]["verification"];
    const isWhatsApp = SMSProvider === "whatsapp";

    try {
      await api.Post("/users/sendCode", {...payload, provider: SMSProvider});
      thunkSuccess(dispatch, {
        title: "Código enviado con éxito",
        message: `Recibirás un ${
          isWhatsApp ? "WhatsApp" : "mensaje de texto"
        } con el código`,
      });
      dispatch(go("/verification/validate-code"));
    } catch (error) {
      thunkBadRequest(dispatch, error);
      return rejectWithValue(error);
    } finally {
      dispatch(toggleButtonLoaderId("verify-with-sms"));
    }
  }
);

export const validateVerificationSMSCode = createAsyncThunk(
  "auth/validate-verification-sms-code",
  async (
    payload: ValidateAccountVerificationCodePayload,
    {rejectWithValue, dispatch}
  ) => {
    dispatch(toggleButtonLoaderId("validate-verification-sms-code"));

    try {
      const authenticated = await api.Post<Authenticated>(
        "/users/verifyCode",
        payload
      );
      thunkSuccess(dispatch, {
        title: "Código verificado con éxito",
        message: "Ya puedes continuar con tu compra de tickets",
      });
      dispatch(go("/"));
      return authenticated;
    } catch (error) {
      thunkBadRequest(dispatch, error);
      return rejectWithValue(error);
    } finally {
      dispatch(toggleButtonLoaderId("validate-verification-sms-code"));
    }
  }
);

export const verifyExtraData = createAsyncThunk(
  "auth/verify-extra-data",
  async (
    payload: VerifyExtraDataPayload,
    {rejectWithValue, dispatch, getState}
  ) => {
    dispatch(toggleButtonLoaderId("verify-extra-data"));
    const {user} = (getState() as RootState)["auth"];

    try {
      const authenticated = await api.Put<Authenticated>("/users/update", {
        ...user,
        ...payload,
      });
      thunkSuccess(dispatch, {
        title: "Datos actualizados",
        message: "Sólo un paso más...",
      });
      dispatch(go("/verification/select-sms-provider"));
      return authenticated;
    } catch (error) {
      thunkBadRequest(dispatch, error);
      return rejectWithValue(error);
    } finally {
      dispatch(toggleButtonLoaderId("verify-extra-data"));
    }
  }
);

export const updatePhoto = createAsyncThunk(
  "auth/update-photo",
  async (payload: UpdateUserPicturePayload, {rejectWithValue, dispatch}) => {
    dispatch(toggleButtonLoaderId("update-photo"));

    try {
      const formData = new FormData();
      formData.append("file", payload["picture"]);
      const authenticated = (await postFormData<Authenticated>(
        "/users/uploadPicture",
        formData,
        dispatch,
        "PUT"
      )) as APIResponse<Authenticated>;

      switch (Number(authenticated["code"])) {
        case 200:
          dispatch(close());
          thunkSuccess(dispatch, {
            title: "Tu foto de perfil ha sido actualizada",
          });
          return authenticated;
        default:
          dispatch(
            showAlert({
              type: "error",
              message: authenticated["message"],
              title: "Error al actualizar tu foto",
            })
          );
          return rejectWithValue(undefined);
      }
    } catch (error) {
      thunkBadRequest(dispatch, error);
      return rejectWithValue(error);
    } finally {
      dispatch(toggleButtonLoaderId("update-photo"));
    }
  }
);
