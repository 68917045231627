import {Container} from "./styled";
import {Button, ButtonProps, Typography, Row, Column, Line} from "components";

interface ConfirmProps {
  title?: string;
  message?: string;
  body?: JSX.Element;
  actions: ButtonProps[];
}

export const Confirm: React.FC<ConfirmProps> = ({
  body,
  title,
  message,
  actions,
}) => {
  return (
    <Container>
      {body ? (
        body
      ) : (
        <Column gap={4} marginBottom={15}>
          {title && (
            <Typography fontSize={18} fontFamily="Poppins-Medium">
              {title}
            </Typography>
          )}
          {message && <Typography>{message}</Typography>}
        </Column>
      )}
      <Line />
      <Row justifyContent="flex-end">
        {actions.map((button, key) => (
          <Button {...button} key={key} />
        ))}
      </Row>
    </Container>
  );
};
