import {api} from "api";
import {clearPurchase} from ".";
import {postFormData} from "utils";
import {get} from "../lottery/thunks";
import {RootState} from "@redux/store";
import {getMyCredits} from "../auth/thunks";
import {createAsyncThunk} from "@reduxjs/toolkit";
import {thunkSuccess} from "@redux/thunk-success";
import {thunkBadRequest} from "@redux/thunk-error";
import {
  go,
  close,
  showAlert,
  setLotteryUIVariant,
  toggleButtonLoaderId,
} from "../";
import {
  Pathname,
  BankModel,
  APIResponse,
  PaymentModel,
  PaymentMethod,
  SubscribePayload,
  PaymentMethodModel,
  ParticipatedNumberModel,
} from "interfaces";

export const subscribe = createAsyncThunk(
  "payment/subscribe",
  async (
    {
      numbers,
      lotteryId,
      showAlert = true,
    }: SubscribePayload & {showAlert?: boolean},
    {dispatch, getState, rejectWithValue}
  ) => {
    dispatch(toggleButtonLoaderId("subscribe"));
    const {paymentMethod} = (getState() as RootState)["payment"];

    try {
      const {data} = await api.Post<ParticipatedNumberModel[]>(
        `/lottery/participate/${lotteryId}`,
        {numbers}
      );

      if (showAlert) {
        thunkSuccess(dispatch, {
          title: "Tickets congelados",
          message:
            "Ahora sólo falta que reportes tu pago para completar la reservación",
        });
      }

      if (paymentMethod && paymentMethod === "credit") {
        await dispatch(reportPayment(data));
        await dispatch(getMyCredits());
      } else {
        dispatch(go(`/lottery/${lotteryId}/report`));
      }

      return data;
    } catch (error) {
      dispatch(get());
      dispatch(clearPurchase());
      dispatch(go(`/lottery/${lotteryId}/tickets`));

      thunkBadRequest(dispatch, error);
      return rejectWithValue(error);
    } finally {
      dispatch(close());
      dispatch(toggleButtonLoaderId("subscribe"));
    }
  }
);

export const getMethods = createAsyncThunk(
  "payment/get-methods",
  async (_, {dispatch, rejectWithValue}) => {
    dispatch(toggleButtonLoaderId("subscribe"));
    try {
      const {data} = await api.Get<PaymentMethodModel[]>(
        "/bank/getAllPaymentMethods?limit=100&position=0&search="
      );
      return data;
    } catch (error) {
      thunkBadRequest(dispatch, error);
      return rejectWithValue(error);
    } finally {
      dispatch(toggleButtonLoaderId("subscribe"));
    }
  }
);

export const getBanks = createAsyncThunk(
  "payment/get-banks",
  async (_, {dispatch, rejectWithValue}) => {
    dispatch(toggleButtonLoaderId("subscribe"));
    try {
      const {data} = await api.Get<BankModel[]>(
        "/bank/getAll?limit=100&position=0&search="
      );
      return data;
    } catch (error) {
      thunkBadRequest(dispatch, error);
      return rejectWithValue(error);
    } finally {
      dispatch(toggleButtonLoaderId("subscribe"));
    }
  }
);

export const reportPayment = createAsyncThunk(
  "payment/report",
  async (
    tempParticipatedNumbers: ParticipatedNumberModel[] | undefined = undefined,
    {dispatch, rejectWithValue, getState}
  ) => {
    dispatch(toggleButtonLoaderId("report-payment"));

    const {auth, payment, lottery} = getState() as RootState;
    const {_id, price} = lottery["lottery"];
    const {name, lastname, _id: userId} = auth["user"];
    let {
      methodId,
      paymentMethod,
      ticketsSelected,
      userPayloadReport,
      participatedNumbers,
    } = payment;
    const {dni, code, phone, email, codeBank, dniPrefix, screenshot} =
      userPayloadReport;
    const USDTotal = ticketsSelected["length"] * price;
    const amount = USDTotal;
    participatedNumbers = tempParticipatedNumbers ?? participatedNumbers;

    const formData: FormData = new FormData();
    formData.append("code", code);
    formData.append("userId", userId);
    formData.append("lotteryId", _id);
    formData.append("codeBank", codeBank);
    formData.append("email", email ?? "");
    formData.append("files", screenshot ?? "");
    formData.append("dni", `${dniPrefix}${dni}`);
    formData.append("methodId", methodId ?? "");
    formData.append("fullname", `${name} ${lastname}`);
    formData.append("amount", amount as unknown as string);
    formData.append("phone", phone ? `58${phone.substring(1)}` : "");
    participatedNumbers.forEach(({_id}) => {
      formData.append("numberId[]", _id);
    });

    try {
      const {code, message} = (await postFormData<PaymentModel>(
        "/payments/insert",
        formData,
        dispatch
      )) as APIResponse<PaymentModel>;

      dispatch(close());

      switch (Number(code)) {
        case 200:
          let nextRoute: Pathname = `/lottery/${_id}/success`;
          const waitingForAdminApproval: PaymentMethod[] = [
            "bank",
            "zelle",
            "binance",
            "mobile-payment",
          ];
          if (
            waitingForAdminApproval["includes"](paymentMethod as PaymentMethod)
          ) {
            nextRoute = `/lottery/${_id}/waiting`;
          }

          dispatch(get());
          dispatch(go(nextRoute as Pathname));
          dispatch(setLotteryUIVariant("subscribed"));
          break;
        default:
          dispatch(
            showAlert({title: "Error en tu reporte", message, type: "error"})
          );
          return rejectWithValue(undefined);
      }
    } finally {
      dispatch(toggleButtonLoaderId("report-payment"));
    }
  }
);
