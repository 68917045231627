import {colors} from "styles";
import {Info} from "react-feather";
import {useNavigate} from "react-router-dom";
import {Column, Row, Typography} from "components";

interface TermsAndConditionsProps {}

export const TermsAndConditions: React.FC<TermsAndConditionsProps> = () => {
  const navigate = useNavigate();
  return (
    <Row alignItems="flex-start" marginBottom={10}>
      <Info color={colors["black"]} width={23} height={23} />

      <Column
        gap={0}
        width="100%"
        onClick={() => navigate("/terms-and-conditions")}
      >
        <Typography fontSize={13}>
          Al presionar{" "}
          <Typography fontSize={13} fontFamily="Poppins-SemiBold">
            Registrarse
          </Typography>{" "}
          estoy aceptando los{" "}
          <Typography
            fontSize={13}
            color={colors["primary"]}
            fontFamily="Poppins-Bold"
          >
            Términos y Condiciones
          </Typography>{" "}
          y confirmo que soy mayor de edad.
        </Typography>
      </Column>
    </Row>
  );
};
