import moment from "moment";
import {colors} from "styles";
import {Container} from "./styled";
import {Referrals, Trophy} from "svg";
import {useTheme} from "styled-components";
import {useNavigate} from "react-router-dom";
import {useAppDispatch, useAppSelector} from "hooks";
import {
  logOut,
  setResetSlice,
  clearPurchase,
  toggleFullScreenLoader,
} from "@redux/slices";
import {
  Edit3,
  LogOut,
  Calendar,
  UserPlus,
  HelpCircle,
  ChevronLeft,
} from "react-feather";
import {
  Row,
  Line,
  Href,
  Column,
  Screen,
  Avatar,
  Typography,
  IconButton,
} from "components";

interface ProfileProps {}

export const Profile: React.FC<ProfileProps> = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const {black, white} = useTheme();
  const {name, lastname, picture, createdAt, description, username} =
    useAppSelector("auth")["user"];

  return (
    <Screen statusBarColor={colors["primary"]} style={{backgroundColor: white}}>
      <Column gap={5} paddingBottom={30} padding="1rem">
        <Row paddingTop={5}>
          <IconButton
            size={40}
            onClick={() => navigate(-1)}
            backgroundColor="transparent"
            icon={<ChevronLeft width={26} height={26} color={black} />}
          />
          <Row>
            <Avatar size={40} src={picture} />
            <Column gap={0}>
              <Typography fontSize={18} fontFamily="Poppins-SemiBold">
                {name} {lastname}
              </Typography>
              <Typography fontSize={14} fontFamily="Poppins-Light">
                @{username}
              </Typography>
            </Column>
          </Row>
        </Row>
        <Typography fontSize={15} fontFamily="Poppins-Medium">
          {description || "🏆 Nuevo en Centro de Apuesta"}
        </Typography>
        <Row>
          <Row>
            <Calendar width={20} height={20} />
            <Typography fontSize={13}>
              Se unió el {moment(createdAt).format("DD/MM/YYYY")}
            </Typography>
          </Row>
        </Row>
      </Column>
      <Container>
        <Typography fontSize={17} fontFamily="Poppins-SemiBold">
          Mis Accesos
        </Typography>
        <Column gap={8}>
          <Href
            label="Invitar amigo"
            onClick={() => navigate("/invite")}
            guide="Invita a tus amigos y gana tickets"
            icon={<UserPlus color={colors["black"]} />}
          />
          <Line margin={0} />
          <Href
            label="Editar perfil"
            guide="Actualiza tu información"
            onClick={() => navigate("/profile/edit")}
            icon={<Edit3 color={colors["black"]} />}
          />
          <Line margin={0} />
          <Href
            icon={<Referrals />}
            label="Mis referidos"
            guide="Todos los usuarios que has invitado"
            onClick={() => navigate("/profile/referrals")}
          />
          <Line margin={0} />
          <Href
            icon={<Trophy />}
            label="Sorteos ganados"
            guide="Historial de sorteos ganados"
            onClick={() => navigate("/profile/history")}
          />
          <Line margin={0} />
          <Href
            label="Equipo de Soporte"
            onClick={() => navigate("/support")}
            icon={<HelpCircle color={colors["black"]} />}
            guide="Te ayudamos con problemas e información"
          />
          <Line margin={0} />
          <Href
            textColor="#D11B1B"
            label="Cerrar sesión"
            icon={<LogOut color="#D11B1B" />}
            guide="Recuerda que para participar debes tener tu sesión abierta"
            onClick={() => {
              dispatch(toggleFullScreenLoader(true));
              setTimeout(() => {
                navigate("/");
                dispatch(setResetSlice());
                dispatch(clearPurchase());

                setTimeout(() => {
                  dispatch(logOut());
                  dispatch(toggleFullScreenLoader(false));
                }, 1000);
              }, 200);
            }}
          />
        </Column>
      </Container>
    </Screen>
  );
};
