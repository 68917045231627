import {phone} from "phone";
import {Formik} from "formik";
import {colors} from "styles";
import {useAppDispatch} from "hooks";
import {useTheme} from "styled-components";
import {useNavigate} from "react-router-dom";
import {recoverPassword} from "@redux/slices/auth/thunks";
import {initialValues, recoverPasswordSchema} from "./form.schema";
import {Line, Column, Screen, Button, Typography, PhoneInput} from "components";

export const RecoverPassword = () => {
  const {primary} = useTheme();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  return (
    <Screen
      bodyStyle={{padding: "1rem"}}
      header={{
        onBack: () => navigate(-1),
        title: "Encontremos tu cuenta",
        guide: "Con tu teléfono podremos identificarte",
      }}
    >
      <Column gap={15}>
        <Formik
          validateOnChange={true}
          initialValues={initialValues}
          validationSchema={recoverPasswordSchema}
          onSubmit={(payload) => {
            dispatch(recoverPassword(payload));
          }}
        >
          {({dirty, values, isValid, handleSubmit, handleChange}) => {
            const disabled = !phone(values["phone"]).isValid;

            return (
              <Column width="100%">
                <PhoneInput
                  value={values["phone"]}
                  helperText="Recibirás un SMS con un código de confirmación que nos permitirá comprobar que es tu cuenta"
                  onChangePhoneNumber={(value) => {
                    handleChange({target: {name: "phone", value}});
                  }}
                />
                <Button
                  label="Continuar"
                  disabled={disabled}
                  style={{width: "100%"}}
                  loaderId="forgot-password"
                  onClick={() => handleSubmit()}
                />
              </Column>
            );
          }}
        </Formik>
        <Line margin={0} marginTop={5} />
        <Column width="80%" margin="auto">
          <Typography>¿Ya recordaste tu cuenta?</Typography>
          <Button
            variant="outlined"
            label="Iniciar sesión"
            borderColor={colors["primary"]}
            typographyProps={{color: primary}}
            style={{width: "100%"}}
            onClick={() => navigate("/auth/login")}
          />
        </Column>
      </Column>
    </Screen>
  );
};
