import moment from "moment";
import {Fragment} from "react";
import {useAppSelector} from "hooks";
import {Column, Line, RankCircle, Row, Typography} from "components";

interface TableProps {}

export const Table: React.FC<TableProps> = () => {
  const {history} = useAppSelector("history");
  const {_id} = useAppSelector("auth")["user"];

  return (
    <Column width="100%">
      <Row gap={10} width="100%">
        <Row width="14%" justifyContent="center">
          <Typography
            fontSize={15}
            className="line-clamp-1"
            fontFamily="Poppins-Medium"
          >
            Sorteo
          </Typography>
        </Row>
        <Row width="23%" justifyContent="center">
          <Typography
            fontSize={15}
            className="line-clamp-1"
            fontFamily="Poppins-Medium"
          >
            Fecha
          </Typography>
        </Row>
        <Row width="20%" justifyContent="center">
          <Typography
            fontSize={15}
            className="line-clamp-1"
            fontFamily="Poppins-Medium"
          >
            Lugar
          </Typography>
        </Row>
        <Row width="25%" justifyContent="center">
          <Typography
            fontSize={15}
            className="line-clamp-1"
            fontFamily="Poppins-Medium"
          >
            Ticket ganad.
          </Typography>
        </Row>
      </Row>
      {history.map(({number, end_date, awards}, key) => {
        const awardPositions = awards.filter(
          ({number}) => number && (number["user"] as unknown as string) === _id
        );
        return (
          <Fragment key={key}>
            <Row gap={10} width="100%">
              <Row width="14%" justifyContent="center">
                <Typography className="line-clamp-1">#{number}</Typography>
              </Row>
              <Row width="23%" justifyContent="center">
                <Typography className="line-clamp-1">
                  {moment(end_date).format("DD/MM/YYYY")}
                </Typography>
              </Row>
              <Row width="20%" justifyContent="center">
                {awardPositions.map(({position}, key) => (
                  <RankCircle
                    key={key}
                    size={22}
                    rank={Number(position)}
                    typographyProps={{fontSize: 13}}
                  />
                ))}
              </Row>
              <Row width="30%">
                {awardPositions.map(({number: ticket}, key) => (
                  <Typography key={key} className="line-clamp-1">
                    {ticket ? `Nro. ${ticket["number"]} ` : "-"}
                  </Typography>
                ))}
              </Row>
            </Row>
            {key + 1 !== history["length"] && <Line />}
          </Fragment>
        );
      })}
    </Column>
  );
};
