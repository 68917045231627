import {api} from "api";
import {go} from "../navigation";
import {toggleButtonLoaderId} from "../layout";
import {createAsyncThunk} from "@reduxjs/toolkit";
import {thunkSuccess} from "@redux/thunk-success";
import {thunkBadRequest} from "@redux/thunk-error";
import {CreateSupportTicketPayload, SupportTicketModel} from "interfaces";

export const get = createAsyncThunk(
  "support/get",
  async (_, {dispatch, rejectWithValue}) => {
    try {
      return await api.Get<SupportTicketModel[]>(
        "/tickets/getAll?limit=10&position=0&search="
      );
    } catch (error) {
      thunkBadRequest(dispatch, error);
      return rejectWithValue(error);
    }
  }
);

export const getById = createAsyncThunk(
  "support/get-by-id",
  async (supportTicketId: string, {dispatch, rejectWithValue}) => {
    try {
      return await api.Get<SupportTicketModel>(
        `/tickets/get/${supportTicketId}`
      );
    } catch (error) {
      thunkBadRequest(dispatch, error);
      return rejectWithValue(error);
    }
  }
);

export const createTicket = createAsyncThunk(
  "support/create-ticket",
  async (payload: CreateSupportTicketPayload, {dispatch, rejectWithValue}) => {
    dispatch(toggleButtonLoaderId("create-support-ticket"));
    try {
      const {data} = await api.Post<SupportTicketModel>(
        "/tickets/insert",
        payload
      );

      thunkSuccess(dispatch, {
        title: "Ticket creado con éxito",
        message: "Nuestro equipo te estará contactando en breve",
      });
      dispatch(go("/support"));
      return data;
    } catch (error) {
      thunkBadRequest(dispatch, error);
      return rejectWithValue(error);
    } finally {
      dispatch(toggleButtonLoaderId("create-support-ticket"));
    }
  }
);
