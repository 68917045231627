import {Column, Row, Skeleton} from "components";

export const SkeletonScreen = () => (
  <Column margin="1rem 0">
    <Skeleton width={240} height={25} marginBottom={10} highlightMode="dark" />
    <Column gap={15}>
      <Column>
        <Row>
          <Skeleton
            width={50}
            height={50}
            borderRadius={100}
            highlightMode="dark"
          />
          <Column gap={5}>
            <Skeleton width={100} height={25} highlightMode="dark" />
            <Skeleton width={150} height={20} highlightMode="dark" />
          </Column>
        </Row>
        <Skeleton
          height={42}
          width="100%"
          borderRadius={100}
          highlightMode="dark"
        />
      </Column>
      <Column>
        <Row>
          <Skeleton
            width={50}
            height={50}
            borderRadius={100}
            highlightMode="dark"
          />
          <Column gap={5}>
            <Skeleton width={100} height={25} highlightMode="dark" />
            <Skeleton width={150} height={20} highlightMode="dark" />
          </Column>
        </Row>
        <Skeleton
          height={42}
          width="100%"
          borderRadius={100}
          highlightMode="dark"
        />
      </Column>
      <Column>
        <Row>
          <Skeleton
            width={50}
            height={50}
            borderRadius={100}
            highlightMode="dark"
          />
          <Column gap={5}>
            <Skeleton width={100} height={25} highlightMode="dark" />
            <Skeleton width={150} height={20} highlightMode="dark" />
          </Column>
        </Row>
        <Skeleton
          height={42}
          width="100%"
          borderRadius={100}
          highlightMode="dark"
        />
      </Column>
    </Column>
  </Column>
);
