import {colors} from "styles";
import {Fragment} from "react";
import {Container} from "./style";
import {Copy} from "react-feather";
import {showAlert} from "@redux/slices";
import {useAppDispatch, useAppSelector} from "hooks";
import {Column, Row, Typography, Image} from "components";
import {paymentMethodVariants} from "constants/payment-method-variants";
import {PaymentMethod, ShowAlertPayload, PaymentMethodModel} from "interfaces";

interface PaymentInfoProps {
  data: PaymentMethodModel;
}

function renderUI(
  method: PaymentMethod,
  data: PaymentMethodModel,
  onAlert: (payload: ShowAlertPayload) => void
): JSX.Element {
  switch (method) {
    case "bank":
      return (
        <Column gap={8}>
          <Row>
            <Image size={25} objectFit="contain" src="/assets/bnc.png" />
            <Typography fontFamily="Poppins-SemiBold" fontSize={16}>
              {data["name"]}
            </Typography>
          </Row>
          <Column gap={0}>
            <Row>
              <Typography>Tipo:</Typography>
              <Typography fontFamily="Poppins-SemiBold">Corriente</Typography>
            </Row>

            <Row
              onClick={() => {
                navigator.clipboard.writeText(data["rif"]).then(() => {
                  onAlert({
                    type: "success",
                    title: "RIF copiado con éxito",
                  });
                });
              }}
            >
              <Typography>RIF:</Typography>
              <Typography fontFamily="Poppins-SemiBold">
                {data["rif"]}
              </Typography>
              <Copy width={20} height={20} color={colors["black"]} />
            </Row>
            <Row
              onClick={() => {
                navigator.clipboard.writeText(data["account"]).then(() => {
                  onAlert({
                    type: "success",
                    title: "Cuenta copiada con éxito",
                  });
                });
              }}
            >
              <Typography>Cuenta:</Typography>
              <Typography fontFamily="Poppins-SemiBold">
                {data["account"]}
              </Typography>
              <Copy width={20} height={20} color={colors["black"]} />
            </Row>
          </Column>
        </Column>
      );
    case "binance":
      return (
        <Row
          justifyContent="space-between"
          onClick={() => {
            navigator.clipboard.writeText(data["account"]).then(() => {
              onAlert({
                type: "success",
                title: "Correo Binance copiado",
                message:
                  "Ve a tu Aplicación de Binance y realiza tu transferencia (en Binance Pay) con el monto indicado",
              });
            });
          }}
        >
          <Column gap={5}>
            <Row>
              <Image
                size={25}
                objectFit="contain"
                src={paymentMethodVariants[2]["image"]}
              />
              <Typography fontFamily="Poppins-SemiBold" fontSize={16}>
                Binance
              </Typography>
            </Row>
            <Typography fontSize={16} fontFamily="Poppins-Medium">
              {data["account"]}
            </Typography>
          </Column>
          <Column alignItems="center" gap={2}>
            <Copy width={20} height={20} color={colors["black"]} />
          </Column>
        </Row>
      );
    case "zelle":
      return (
        <Row
          justifyContent="space-between"
          onClick={() => {
            navigator.clipboard.writeText(data["account"]).then(() => {
              onAlert({
                type: "success",
                title: "Correo Zelle copiado",
                message:
                  "Ve a tu Aplicación de Zelle y realiza tu transferencia con el monto indicado",
              });
            });
          }}
        >
          <Column gap={5}>
            <Row>
              <Image
                size={25}
                objectFit="contain"
                src={paymentMethodVariants[3]["image"]}
              />
              <Typography fontFamily="Poppins-SemiBold" fontSize={16}>
                Correo Zelle
              </Typography>
            </Row>
            <Typography fontSize={15} fontFamily="Poppins-Medium">
              {data["account"]}
            </Typography>
          </Column>
          <Column alignItems="center" gap={2}>
            <Copy width={20} height={20} color={colors["black"]} />
          </Column>
        </Row>
      );
    case "mobile-payment":
      return (
        <Column gap={8}>
          <Row>
            <Image size={25} objectFit="contain" src="/assets/bnc.png" />
            <Typography fontFamily="Poppins-SemiBold" fontSize={16}>
              {data["account"]}
            </Typography>
          </Row>
          <Column gap={0}>
            <Row
              onClick={() => {
                navigator.clipboard.writeText(data["rif"]).then(() => {
                  onAlert({
                    type: "success",
                    title: "RIF copiado con éxito",
                  });
                });
              }}
            >
              <Typography>RIF:</Typography>
              <Typography fontFamily="Poppins-SemiBold">
                {data["rif"]}
              </Typography>
              <Copy width={20} height={20} color={colors["black"]} />
            </Row>
            <Row
              onClick={() => {
                navigator.clipboard.writeText(data["phone"]).then(() => {
                  onAlert({
                    type: "success",
                    title: "Teléfono copiado con éxito",
                  });
                });
              }}
            >
              <Typography>Teléfono:</Typography>
              <Typography fontFamily="Poppins-SemiBold">
                {data["phone"]}
              </Typography>
              <Copy width={20} height={20} color={colors["black"]} />
            </Row>
          </Column>
        </Column>
      );
    default:
      return <Fragment />;
  }
}

export const PaymentInfo: React.FC<PaymentInfoProps> = ({data}) => {
  const {paymentMethod} = useAppSelector("payment");
  const dispatch = useAppDispatch();
  return data ? (
    <Container>
      {renderUI(paymentMethod as PaymentMethod, data, (payload) => {
        dispatch(showAlert(payload));
      })}
    </Container>
  ) : (
    <Fragment />
  );
};
