import {colors} from "styles";
import {useAppSelector} from "hooks";
import {ButtonStyled} from "./styled";
import {getSpinnerColors} from "./helper";
import {ButtonLoaderId} from "interfaces";
import {useTheme} from "styled-components";
import {ColorRing} from "react-loader-spinner";
import {ButtonHTMLAttributes, Fragment} from "react";
import {Row, Typography, TypographyProps} from "../..";

type ButtonVariant = "filled" | "outlined";

export interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  label: string;
  color?: string;
  borderColor?: string;
  leftIcon?: JSX.Element;
  variant?: ButtonVariant;
  rightIcon?: JSX.Element;
  backgroundColor?: string;
  loaderId?: ButtonLoaderId;
  typographyProps?: Omit<TypographyProps, "children">;
}

export const Button: React.FC<ButtonProps> = (props) => {
  const {
    label,
    loaderId,
    leftIcon,
    rightIcon,
    style = {},
    typographyProps,
    disabled = false,
    variant = "filled",
    color = colors["white"],
    borderColor = colors["secondary"],
    backgroundColor = colors["secondary"],
    ...buttonProps
  } = props;
  const {buttonLoaderIds} = useAppSelector("layout");
  const {isDark, primary, disabledButtonTextColor} = useTheme();
  const buttonTextColor = disabled ? disabledButtonTextColor : color;
  const isOutlined = variant === "outlined";
  const loading = buttonLoaderIds["includes"](loaderId as ButtonLoaderId);
  const loaderColor = getSpinnerColors(
    style && style["backgroundColor"] ? style["backgroundColor"] : primary
  );

  return (
    <ButtonStyled
      $darkMode={isDark}
      disabled={disabled || loading}
      $disabled={disabled || loading}
      style={{
        ...style,
        ...{
          border: loading
            ? undefined
            : isOutlined
            ? `1px solid ${
                loading ? "transparent" : isOutlined ? borderColor : undefined
              }`
            : undefined,
          backgroundColor:
            disabled || loading
              ? colors["disabled"]
              : isOutlined
              ? "transparent"
              : backgroundColor,
          justifyContent:
            disabled || loading
              ? "center"
              : typeof rightIcon === "undefined"
              ? "center"
              : "space-between",
        },
      }}
      children={
        <Row
          gap={5}
          justifyContent={
            loading ? "center" : rightIcon ? "space-between" : "center"
          }
        >
          {loading ? (
            <ColorRing
              width="40"
              height="40"
              visible={loading}
              colors={loaderColor}
            />
          ) : (
            <Fragment>
              {leftIcon && leftIcon}
              <Typography color={buttonTextColor} {...typographyProps}>
                {label}
              </Typography>
              {rightIcon && rightIcon}
            </Fragment>
          )}
        </Row>
      }
      {...buttonProps}
    />
  );
};
