import {useEffect} from "react";
import {Fragment} from "react/jsx-runtime";
import {getRate} from "@redux/slices/config/thunks";
import {useAppDispatch, useInitializeApp} from "hooks";
import {
  Modal,
  Alert,
  Overlap,
  StatusBar,
  AutoScrollTop,
  ReduxNavigation,
  FullScreenLoader,
} from "components";

interface GlobalProps {}

export const Global: React.FC<GlobalProps> = () => {
  useInitializeApp();
  const dispatch = useAppDispatch();
  useEffect(() => {
    dispatch(getRate());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Fragment>
      <Alert />
      <Modal />
      <Overlap />
      <StatusBar />
      <AutoScrollTop />
      <ReduxNavigation />
      <FullScreenLoader />
    </Fragment>
  );
};
