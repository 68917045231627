import {MemberUserModel} from "interfaces";
import {Avatar, Column, Row, Typography} from "components";

interface ParticipantProps {
  rank: number;
  participant: MemberUserModel;
}

export const Participant: React.FC<ParticipantProps> = ({
  participant,
  rank,
}) => {
  const {user, number} = participant;
  return (
    <Row
      width="100%"
      display="grid"
      justifyContent="space-between"
      gridTemplateColumns="80% 20%"
    >
      <Row gap={15}>
        <Typography>{rank}</Typography>
        <Row gap={8}>
          <Avatar size={35} src={user["picture"]} />
          <Column gap={0}>
            <Typography>
              {user["name"]} {user["lastname"]}
            </Typography>
            <Typography color="#999999" fontSize={12}>
              {user["address"]}
            </Typography>
          </Column>
        </Row>
      </Row>
      <Column gap={0} alignItems="flex-end">
        <Typography fontFamily="Poppins-Medium" fontSize={18}>
          #{number}
        </Typography>
        <Typography textAlign="end" fontSize={12}>
          nro. jugado
        </Typography>
      </Column>
    </Row>
  );
};
