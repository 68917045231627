import styled from "styled-components";

export const Container = styled.div`
  gap: 5px;
  display: flex;
  padding: 0 20px 25px;
  flex-direction: column;
  background-color: #fff;
  transform: translateY(-15);
  border-top-left-radius: 15;
  border-top-right-radius: 15;
`;
