import {Table} from "./lib";
import {colors} from "styles";
import {useEffect} from "react";
import {Award} from "react-feather";
import {useNavigate} from "react-router-dom";
import {get} from "@redux/slices/history/thunks";
import {useAppDispatch, useAppSelector} from "hooks";
import {
  Column,
  Screen,
  Warning,
  Skeleton,
  ComponentLoader,
  CommonOutlinedErrorBody,
} from "components";

export const ParticipationHistory = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const {request, history} = useAppSelector("history");

  useEffect(() => {
    dispatch(get());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Screen
      bodyStyle={{padding: "1rem"}}
      header={{title: "Sorteos ganados", onBack: () => navigate(-1)}}
    >
      <ComponentLoader
        requestStatus={request["history"]}
        errorUI={{
          body: <CommonOutlinedErrorBody callback={() => dispatch(get())} />,
        }}
        emptyUI={{
          data: history,
          icon: <Award color={colors["black"]} width={40} height={40} />,
          message:
            "No has ganado ningún sorteo aún. Síguelo intentando y cumple tus sueños",
        }}
        skeleton={
          <Column width="100%">
            <Column gap={2} width="100%">
              <Skeleton
                height={30}
                width="100%"
                marginBottom={5}
                highlightMode="dark"
              />
              <Skeleton
                count={8}
                height={45}
                width="100%"
                highlightMode="dark"
              />
            </Column>
            <Skeleton height={130} width="100%" highlightMode="dark" />
          </Column>
        }
      >
        <Table />
        <Warning
          title="Recordatorio"
          style={{marginTop: 25}}
          message="Tus videos testimoniales son subidos al centro de apuesta para mostrar nuestra transparencia a los usuarios que participan en nuestros sorteos"
        />
      </ComponentLoader>
    </Screen>
  );
};
