import {rankColor} from "utils";
import {Column, Typography, TypographyProps} from "components";

interface RankCircleProps {
  size?: number;
  rank: keyof typeof rankColor;
  typographyProps?: Omit<TypographyProps, "children">;
}

export const RankCircle: React.FC<RankCircleProps> = ({
  rank,
  size = 35,
  typographyProps,
}) => {
  return (
    <Column
      width={size}
      height={size}
      borderRadius={100}
      alignItems="center"
      justifyContent="center"
      backgroundColor={rankColor[rank]}
      boxShadow="1px 1px 1px 1px rgba(0,0,0, .1)"
    >
      <Typography
        color="#fff"
        fontSize={15}
        fontFamily="Poppins-SemiBold"
        {...typographyProps}
      >
        {rank}
      </Typography>
    </Column>
  );
};
