import styled from "styled-components";

export const Container = styled.div`
  gap: 4px;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  flex-direction: row;
  justify-content: center;
`;

export const IndicatorGuide = styled.div`
  width: 15px;
  height: 15px;
  border-radius: 100px;
  border: 1px solid rgba(0, 0, 0, 0.3);
`;
