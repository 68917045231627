import {PaymentMethod, UserPayloadReport} from "interfaces";

export function toggleDisableState(
  paymentMethod: PaymentMethod,
  {dni, screenshot, code, phone, codeBank}: UserPayloadReport
): boolean {
  switch (paymentMethod) {
    case "bank":
      return !(dni && code && codeBank);
    case "zelle":
    case "binance":
      return !(code && screenshot);
    case "mobile-payment":
      return !(code && phone && dni && codeBank && /^58\d{10}$/.test(phone));
    default:
      return false;
  }
}
