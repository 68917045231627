import {Container} from "./styled";
import {Children, Style} from "interfaces";
import {useTheme} from "styled-components";
import {CSSProperties, ReactNode} from "react";
import {Typography, TypographyProps} from "../Typography";

interface ChipProps extends Children<ReactNode>, CSSProperties, Style {
  labelStyle?: Omit<TypographyProps, "children">;
}

export const Chip: React.FC<ChipProps> = (props) => {
  const {white} = useTheme();
  const {children, labelStyle, style, ...cssStyle} = props;
  return (
    <Container
      style={{...cssStyle, ...style}}
      children={
        <Typography color={white} {...labelStyle} fontSize={13}>
          {children}
        </Typography>
      }
    />
  );
};
