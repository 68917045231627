import {Container} from "./styled";
import {useAppSelector} from "hooks";
import {Typography} from "components";
import {useTheme} from "styled-components";
import {LotteryNumber, Clickable} from "interfaces";

interface TicketProps extends Clickable<LotteryNumber> {
  ticket: LotteryNumber;
}

export const Ticket: React.FC<TicketProps> = ({ticket, onClick}) => {
  const {number, user, payment} = ticket;
  const {reserved, frozen, white, primary} = useTheme();
  const {ticketsSelected} = useAppSelector("payment");
  const added =
    typeof ticketsSelected.find((number) => number === ticket["number"]) !==
    "undefined";

  return (
    <Container
      onClick={() => onClick(ticket)}
      style={{
        backgroundColor: added
          ? primary
          : payment
          ? reserved
          : user
          ? frozen
          : white,
      }}
    >
      <Typography
        fontSize={16}
        fontFamily="Poppins-Medium"
        color={
          added
            ? white
            : payment
            ? "rgba(0,0,0, .15)"
            : user
            ? white
            : undefined
        }
      >
        {number}
      </Typography>
    </Container>
  );
};
