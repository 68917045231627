import * as Yup from "yup";
import {CreateSupportTicketPayload} from "interfaces";

export const initialValues: CreateSupportTicketPayload = {
  title: "",
  description: "",
};

export const createSupportTicketSchema = Yup.object().shape({
  title: Yup.string().required("Este campo es requerido"),
  description: Yup.string().required("Este campo es requerido"),
});
