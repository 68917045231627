import {PaymentMethod} from "interfaces";
import {useTheme} from "styled-components";
import {Close, Image, Upload} from "./style";
import {Column, Typography} from "components";
import {Fragment, useRef, useState} from "react";
import {Image as ImageIcon, X} from "react-feather";
import {useAppDispatch, useAppSelector} from "hooks";
import {onChangeUserPayloadReport} from "@redux/slices";

interface ScreenShotProps {}

const electronicPayments: PaymentMethod[] = ["binance", "zelle"];

export const ScreenShot: React.FC<ScreenShotProps> = () => {
  const {black} = useTheme();
  const dispatch = useAppDispatch();
  const inputFile = useRef<HTMLInputElement>(null);
  const [preview, setPreview] = useState<string>();
  const {paymentMethod} = useAppSelector("payment");
  const isElectronic = electronicPayments.includes(
    paymentMethod as PaymentMethod
  );

  return (
    <Column marginTop={10}>
      <Typography fontSize={13}>
        Evidencia {isElectronic ? "(obligatoria)" : "(opcional)"}{" "}
      </Typography>
      <input
        id="file"
        type="file"
        ref={inputFile}
        style={{display: "none"}}
        accept="image/png, image/gif, image/jpg"
        onChange={({target}) => {
          const {files} = target;
          if (files) {
            const file = files[0];
            setPreview(URL.createObjectURL(file));
            dispatch(
              onChangeUserPayloadReport({key: "screenshot", value: file})
            );
          }
        }}
      />
      <Upload
        onClick={() => {
          if (inputFile["current"]) inputFile["current"]["click"]();
        }}
      >
        {preview ? (
          <Fragment>
            <Image src={preview} />
            <Close
              onClick={() => {
                setPreview(undefined);
                dispatch(
                  onChangeUserPayloadReport({key: "screenshot", value: ""})
                );
              }}
            >
              <X width={20} height={20} color={black} />
            </Close>
          </Fragment>
        ) : (
          <ImageIcon color={black} />
        )}
      </Upload>
    </Column>
  );
};
