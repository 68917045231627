import {Column, Row, Skeleton} from "components";

export const SkeletonScreen = () => (
  <Column>
    <Row>
      <Skeleton
        width={30}
        height={30}
        borderRadius={100}
        highlightMode="dark"
      />
      <Skeleton width={100} height={25} highlightMode="dark" />
    </Row>
    <Column gap={5}>
      <Skeleton height={25} width="55%" borderRadius={5} highlightMode="dark" />
      <Skeleton
        height={65}
        width="100%"
        borderRadius={5}
        highlightMode="dark"
      />
    </Column>
    <Skeleton height={120} width="100%" borderRadius={5} highlightMode="dark" />
  </Column>
);
