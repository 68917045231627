import {colors} from "styles";
import {State} from "./types";

export const initialState: State = {
  showOverlap: false,
  buttonLoaderIds: [],
  showFullScreenLoader: true,
  promotionalVideoDisplayed: false,
  statusBarColor: colors["primary"],
};
