import {Typography} from "..";
import {Style} from "interfaces";
import {Container} from "./style";
import React, {
  useRef,
  Fragment,
  useState,
  useEffect,
  CSSProperties,
} from "react";

interface CountdownProps extends Style {
  timestamp: number;
  icon?: JSX.Element;
  onEnded: () => void;
  typographyStyle?: CSSProperties;
  timeLabel?: {
    d?: string;
    h?: string;
    m?: string;
    s?: string;
  };
}

export const Countdown: React.FC<CountdownProps> = ({
  icon,
  onEnded,
  timestamp,
  typographyStyle,
  style: styleProps,
  timeLabel = {
    d: " dias ",
    h: " horas ",
    m: " minutos ",
    s: " segundos ",
  },
}) => {
  const [delay] = useState<number>(1000);
  const [sendOnce, setSendOnce] = useState<boolean>(true);
  const [timeStamp, setTimeStamp] = useState(timestamp ?? 0);
  const [displayTime, setDisplayTime] = useState<string>("");

  useInterval(() => {
    if (timeStamp > 0) {
      setTimeStamp(timeStamp - 1);
    } else if (sendOnce) {
      onEnded();
      setSendOnce(false);
    }
    setDisplayTime(secondsToDhms(timeStamp));
  }, delay);

  function secondsToDhms(seconds: number): string {
    seconds = Number(seconds);
    const d = Math.floor(seconds / (3600 * 24));
    const h = Math.floor((seconds % (3600 * 24)) / 3600);
    const m = Math.floor((seconds % 3600) / 60);
    const s = Math.floor(seconds % 60);

    const dDisplay = d > 0 ? `${d}${timeLabel["d"]}` : "";
    const hDisplay = h > 0 ? `${h}${timeLabel["h"]}` : "";
    const mDisplay = m > 0 ? `${m}${timeLabel["m"]}` : "";
    const sDisplay = s > 0 ? `${s}${timeLabel["s"]}` : "";

    return `${dDisplay}${hDisplay}${mDisplay}${d < 1 ? sDisplay : ""}`;
  }

  return displayTime ? (
    <Container style={styleProps}>
      {icon && icon}
      <Typography color="#fff" style={typographyStyle}>
        {sendOnce ? displayTime : "0"}
      </Typography>
    </Container>
  ) : (
    <Fragment />
  );
};

function useInterval(callback: () => void, delay: number) {
  const savedCallback = useRef<() => void>(() => undefined);

  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  useEffect(() => {
    function tick() {
      savedCallback.current();
    }
    if (delay) {
      const id = setInterval(tick, delay);
      return () => {
        clearInterval(id);
      };
    }
  }, [delay]);
}
