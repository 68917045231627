import moment from "moment";
import {colors} from "styles";
import {useAppSelector} from "hooks";
import {ArrowRight} from "react-feather";
import {useNavigate} from "react-router-dom";
import {SupportTicketModel} from "interfaces";
import {Avatar, Column, Row, Typography} from "components";

interface TicketProps {
  ticket: SupportTicketModel;
}

export const Ticket: React.FC<TicketProps> = ({ticket}) => {
  const navigate = useNavigate();
  const {user} = useAppSelector("auth");
  const {description, _id, responses, title, name, lastname, createdAt} =
    ticket;

  return (
    <Column gap={10} onClick={() => navigate(`/support/tickets/${_id}`)}>
      <Row>
        <Avatar size={30} src={user["picture"]} />
        <Column gap={0}>
          <Typography fontFamily="Poppins-Medium">
            {name} {lastname} (Yo)
          </Typography>
          <Typography fontSize={12} color="rgba(0, 0, 0, .6)">
            {moment(createdAt).fromNow()}
          </Typography>
        </Column>
      </Row>
      <Column gap={5}>
        <Typography fontFamily="Poppins-SemiBold" fontSize={13}>
          {title}
        </Typography>
        <Typography color="rgba(0,0,0, .8)">{description}</Typography>
      </Column>
      <Row>
        <Typography>({responses["length"]}) respuestas</Typography>
        <ArrowRight width={15} height={15} color={colors["black"]} />
      </Row>
    </Column>
  );
};
