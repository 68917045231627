import {State} from "./types";
import {RateModel} from "interfaces";
import {setRequestStatus} from "@redux/request-status";

export const initialState: State = {
  configs: [],
  rate: {} as RateModel,
  request: {
    rate: setRequestStatus("initial"),
    configs: setRequestStatus("initial"),
  },
};
