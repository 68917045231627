import moment from "moment";
import {Formik} from "formik";
import {colors} from "styles";
import {useModal} from "hooks";
import {useTheme} from "styled-components";
import {useNavigate} from "react-router-dom";
import {ReferralCode, TermsAndConditions} from "./lib";
import {initialValues, signUpSchema} from "./form.schema";
import {
  Line,
  Input,
  Column,
  Screen,
  Button,
  PhoneInput,
  PasswordInput,
} from "components";

interface SignUpProps {}

export const SignUp: React.FC<SignUpProps> = () => {
  const {white} = useTheme();
  const navigate = useNavigate();
  const {openModal} = useModal();

  return (
    <Screen
      statusBarColor={colors["primary"]}
      bodyStyle={{backgroundColor: white, paddingBottom: 35}}
      header={{
        title: "Registrarse",
        onBack: () => navigate(-1),
        guide: "Bienvenido al centro de Apuesta",
      }}
    >
      <Formik
        validateOnChange={true}
        initialValues={initialValues}
        validationSchema={signUpSchema}
        onSubmit={(payload) => {
          openModal(<ReferralCode data={payload} />);
        }}
      >
        {({
          dirty,
          values,
          errors,
          isValid,
          touched,
          handleBlur,
          handleSubmit,
          handleChange,
        }) => {
          const disabled = !(dirty && isValid);

          return (
            <Column paddingBottom={50} padding="0 1rem">
              <Column gap={5}>
                <Input
                  autoFocus
                  label="Nombre"
                  placeholder="Centro de"
                  value={values["name"]}
                  touched={touched["name"]}
                  onBlur={handleBlur("name")}
                  helperErrorText={errors["name"]}
                  onChange={handleChange("name")}
                />
                <Input
                  label="Apellido"
                  placeholder="Apuestas"
                  value={values["lastname"]}
                  touched={touched["lastname"]}
                  onBlur={handleBlur("lastname")}
                  helperErrorText={errors["lastname"]}
                  onChange={handleChange("lastname")}
                />
                <PhoneInput
                  value={values["phone"]}
                  helperText="Verifica tu número, lo usaremos para enviarte un código y verificar tu cuenta"
                  onChangePhoneNumber={(value) => {
                    handleChange({target: {name: "phone", value}});
                  }}
                />
                <PasswordInput
                  label="Contraseña"
                  placeholder="*********"
                  value={values["password"]}
                  touched={touched["password"]}
                  onBlur={handleBlur("password")}
                  helperErrorText={errors["password"]}
                  onChange={handleChange("password")}
                />
                <Input
                  type="date"
                  placeholder="DD/MM/AAAA"
                  value={values["birthday"]}
                  label="Fecha de nacimiento"
                  onBlur={handleBlur("birthday")}
                  onChange={handleChange("birthday")}
                  helperErrorText={errors["birthday"]}
                  max={moment().subtract(18, "years").format("YYYY-MM-DD")}
                />
              </Column>
              <Line margin=".5rem 0" />
              <TermsAndConditions />
              <Button
                loaderId="sign-up"
                label="Registrarse"
                disabled={disabled}
                style={{width: "100%"}}
                onClick={() => handleSubmit()}
                backgroundColor={colors["primary"]}
              />
            </Column>
          );
        }}
      </Formik>
    </Screen>
  );
};
