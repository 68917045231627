import {State} from "./types";
import {setRequestStatus} from "@redux/request-status";

export const initialState: State = {
  unread: 0,
  notifications: [],
  request: {
    notifications: setRequestStatus("initial"),
  },
};
