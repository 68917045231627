import {Column, Row, Typography} from "components";

interface InfoFieldProps {
  title: string;
  guide: string;
  icon?: JSX.Element;
}

export const InfoField: React.FC<InfoFieldProps> = ({title, guide, icon}) => {
  return (
    <Row gap={10}>
      {icon && icon}
      <Column gap={0}>
        <Typography fontFamily="Poppins-SemiBold" fontSize={15}>
          {title}
        </Typography>
        <Typography fontFamily="Poppins-Light" fontSize={14}>
          {guide}
        </Typography>
      </Column>
    </Row>
  );
};
