import {colors} from "./colors";
import {ThemeMode} from "interfaces";

export function pickColor(mode: ThemeMode, reverse: boolean = false): string {
  switch (mode) {
    case "dark":
      return reverse ? colors["dark"] : colors["light"];
    default:
      return reverse ? colors["light"] : colors["dark"];
  }
}

export function pickButtonColor(
  disabled: boolean,
  isDarkTheme: boolean,
  defaultColor: string = colors["secondary"]
): string {
  return disabled
    ? isDarkTheme
      ? colors["white003"]
      : colors["black003"]
    : defaultColor;
}

export function pickIconColor(
  disabled: boolean,
  isDarkTheme: boolean,
  defaultColor: string = colors["white"]
): string {
  return disabled
    ? isDarkTheme
      ? colors["white05"]
      : colors["black02"]
    : defaultColor;
}
