import {colors} from "styles";
import {Button} from "components";
import {Repeat} from "react-feather";

interface CommonOutlinedErrorBodyProps {
  color?: string;
  label?: string;
  callback: () => void;
}

export const CommonOutlinedErrorBody: React.FC<
  CommonOutlinedErrorBodyProps
> = ({callback, label = "Intentar otra vez", color = colors["error"]}) => {
  return (
    <Button
      label={label}
      onClick={callback}
      typographyProps={{color}}
      leftIcon={<Repeat width={18} height={18} color={color} />}
      style={{
        width: "auto",
        marginTop: 10,
        border: `1px solid ${color}`,
        backgroundColor: "transparent",
      }}
    />
  );
};
