import moment from "moment";
import {useEffect} from "react";
import {Container} from "./styled";
import {useTheme} from "styled-components";
import {clearPurchase} from "@redux/slices";
import {useNavigate} from "react-router-dom";
import {useAppDispatch, useAppSelector} from "hooks";
import {Column, Screen, Button, Typography} from "components";

interface SuccessProps {}

export const Success: React.FC<SuccessProps> = () => {
  const push = useNavigate();
  const {secondary} = useTheme();
  const {end_date, _id} = useAppSelector("lottery")["lottery"];
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(clearPurchase());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Screen bodyStyle={{paddingTop: 25}}>
      <img
        alt="success"
        src={"/assets/success.png"}
        style={{width: 155, height: 185, objectFit: "contain"}}
      />
      <Container>
        <Typography fontFamily="Poppins-SemiBold" fontSize={26}>
          Reservación {"\n"}realizada con éxito
        </Typography>
        <Typography style={{fontSize: 16}}>
          Ahora estos números te pertenecen a ti. Sólo queda esperar que se
          anuncie el ganador el día{" "}
          <Typography fontFamily="Poppins-SemiBold" fontSize={17}>
            {moment(end_date).format("DD/MM/YYYY")}
          </Typography>{" "}
          a través de la transmisión en VIVO que se realizará
        </Typography>
      </Container>
      <Column width="100%" padding="0 10px">
        <Button
          style={{width: "100%", backgroundColor: secondary}}
          label="Volver al inicio"
          onClick={() => push("/")}
        />
        <Button
          style={{width: "100%"}}
          label="Reservar más tickets"
          onClick={() => push(`/lottery/${_id}/tickets`)}
        />
      </Column>
    </Screen>
  );
};
