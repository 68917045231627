import {configureStore} from "@reduxjs/toolkit";
import {
  auth,
  alert,
  modal,
  lives,
  users,
  config,
  layout,
  support,
  history,
  winners,
  ranking,
  payment,
  lottery,
  location,
  referral,
  navigation,
  testimonials,
  notifications,
} from "./slices";

export const store = configureStore({
  reducer: {
    auth,
    modal,
    users,
    alert,
    lives,
    config,
    layout,
    ranking,
    lottery,
    winners,
    support,
    payment,
    history,
    location,
    referral,
    navigation,
    testimonials,
    notifications,
  },
  devTools: process.env.NODE_ENV === "development",
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({serializableCheck: false}),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
