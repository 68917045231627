import {SvgProps} from "interfaces";

export const Trophy = ({
  width = 24,
  height = 24,
  strokeWidth,
  color = "#000",
}: SvgProps) => (
  <svg
    fill="none"
    width={width}
    height={height}
    viewBox="0 0 24 24"
    strokeWidth={strokeWidth}
  >
    <path
      fill={color}
      strokeWidth={strokeWidth}
      d="M19 5H17V3H7V5H5C3.9 5 3 5.9 3 7V8C3 10.55 4.92 12.63 7.39 12.94C8.02 14.44 9.37 15.57 11 15.9V19H7V21H17V19H13V15.9C14.63 15.57 15.98 14.44 16.61 12.94C19.08 12.63 21 10.55 21 8V7C21 5.9 20.1 5 19 5ZM5 8V7H7V10.82C5.84 10.4 5 9.3 5 8ZM12 14C10.35 14 9 12.65 9 11V5H15V11C15 12.65 13.65 14 12 14ZM19 8C19 9.3 18.16 10.4 17 10.82V7H19V8Z"
    />
  </svg>
);
