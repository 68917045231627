import {Live} from "svg";
import {colors} from "styles";
import {useAppSelector} from "hooks";
import {MyBoard} from "pages/Home/lib";
import {useTheme} from "styled-components";
import {useListener} from "pages/Home/hooks";
import {ExternalLink, Info} from "react-feather";
import {
  Row,
  Button,
  Column,
  Skeleton,
  Typography,
  ComponentLoader,
  TypographyProps,
} from "components";

const loginInfoTextStyle: Omit<TypographyProps, "children"> = {
  fontSize: 15,
  color: colors["white"],
  fontFamily: "Poppins-Medium",
};

interface OnLiveProps {}

export const OnLive: React.FC<OnLiveProps> = () => {
  useListener();
  const {primary} = useTheme();
  const {isLogged} = useAppSelector("auth");
  const {lottery} = useAppSelector("lottery");
  const {live, request} = useAppSelector("lives");
  return (
    <Column>
      <Column gap={0} alignItems="center">
        <Live width={40} height={40} color={colors["white"]} />
        <Typography
          fontSize={18}
          color={colors["white"]}
          fontFamily="Poppins-Bold"
          style={{textAlign: "center"}}
        >
          Estamos en VIVO
        </Typography>
        <Typography
          color={colors["white"]}
          style={{textAlign: "center"}}
          fontFamily="Poppins-Medium"
        >
          Conectate con nosotros y descubre los ganadores del sorteo #
          {lottery ? lottery["number"] : ""}
        </Typography>
      </Column>
      <ComponentLoader
        requestStatus={request["live"]}
        skeleton={
          <Skeleton width={200} height={50} margin="auto" borderRadius={100} />
        }
      >
        <Button
          label="Unirme al EN VIVO"
          style={{backgroundColor: primary, margin: "auto"}}
          onClick={() => {
            if (live) window.open(live["url"], "_blank");
          }}
          leftIcon={
            <ExternalLink width={20} height={20} color={colors["white"]} />
          }
        />
      </ComponentLoader>
      {isLogged ? (
        <MyBoard />
      ) : (
        <Column gap={0} width="100%" marginTop={20}>
          <Row>
            <Info width={18} height={18} color={colors["white"]} />
            <Typography {...loginInfoTextStyle} fontFamily="Poppins-SemiBold">
              Inicia sesión
            </Typography>
          </Row>
          <Typography {...loginInfoTextStyle}>
            para poder determinar tu progreso en este sorteo
          </Typography>
        </Column>
      )}
    </Column>
  );
};
