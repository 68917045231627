import {Typography} from "../..";
import {CSSProperties, TextareaHTMLAttributes} from "react";
import {Container, TextareaStyled, typographyErrorProps} from "./styled";

export interface TextareaProps
  extends TextareaHTMLAttributes<HTMLTextAreaElement> {
  label?: string;
  height?: number;
  touched?: boolean;
  helperErrorText?: string;
  containerStyle?: CSSProperties;
}

export const Textarea: React.FC<TextareaProps> = (props) => {
  const {
    label,
    height = 85,
    containerStyle,
    touched = false,
    helperErrorText,
    ...textareaProps
  } = props;
  const {placeholder = "", style} = textareaProps;
  return (
    <Container style={containerStyle}>
      {label && <Typography fontWeight="500">{label}</Typography>}
      <TextareaStyled
        placeholder={placeholder}
        style={{...style, height}}
        {...textareaProps}
      />
      {helperErrorText && touched && (
        <Typography {...typographyErrorProps}>{helperErrorText}</Typography>
      )}
    </Container>
  );
};
