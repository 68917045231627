import {FC} from "react";
import {colors} from "styles";
import {Typography} from "components";
import {LotteryNumber} from "interfaces";
import {Container, Absolute} from "./style";
import {IconProps, Check} from "react-feather";

interface TicketProps {
  Icon?: FC<IconProps>;
  ticket: LotteryNumber;
}

export const Ticket: React.FC<TicketProps> = ({ticket, Icon = Check}) => {
  const {number} = ticket;

  return (
    <Container>
      <Typography fontSize={18} fontFamily="Poppins-SemiBold">
        {number}
      </Typography>
      <Absolute>
        <Icon strokeWidth={3} width={13} height={13} color={colors["white"]} />
      </Absolute>
    </Container>
  );
};
