import {Friend} from "./lib";
import {colors} from "styles";
import {showAlert} from "@redux/slices";
import {buildInviteMessage} from "utils";
import {Fragment, useEffect} from "react";
import {useNavigate} from "react-router-dom";
import {SkeletonScreen} from "./SkeletonScreen";
import {get} from "@redux/slices/referrals/thunks";
import {BarChart2, Link, Users} from "react-feather";
import {useAppDispatch, useAppSelector} from "hooks";
import {
  Row,
  Line,
  Screen,
  Column,
  Warning,
  Typography,
  ComponentLoader,
  CommonOutlinedErrorBody,
} from "components";

export const MyReferrals = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const {user} = useAppSelector("auth");
  const {referrals, request} = useAppSelector("referral");

  useEffect(() => {
    dispatch(get());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Screen
      bodyStyle={{padding: "1rem"}}
      header={{
        title: "Mis Referidos",
        onBack: () => navigate(-1),
        rightButton: {
          label: "Invitar",
          leftIcon: <Link width={22} height={22} color={colors["white"]} />,
          onClick: () => {
            navigator.clipboard
              .writeText(buildInviteMessage(user["referralCode"]))
              .then(() => {
                dispatch(
                  showAlert({
                    type: "success",
                    title: "Invitación enviada",
                    message:
                      "Una vez tu invitado se registre y compre su primer ticket se incrementará tus puntos",
                  })
                );
              });
          },
        },
      }}
    >
      <Typography fontFamily="Poppins-Medium" fontSize={14}>
        Cada ticket que compran tus referidos estás más cerca de ganar
        beneficios
      </Typography>
      <Warning
        title="Consejo"
        message="Anima a tus amigos/as a participar en concursos para que ganes tickets que podrás usar en los próximos sorteos"
      />
      <ComponentLoader
        skeleton={<SkeletonScreen />}
        requestStatus={request["referrals"]}
        errorUI={{
          body: <CommonOutlinedErrorBody callback={() => dispatch(get())} />,
        }}
        emptyUI={{
          data: referrals,
          message: "No has invitado amigos aún",
          icon: <Users color={colors["black"]} width={40} height={40} />,
        }}
      >
        <Row marginTop={20} marginBottom={15}>
          <BarChart2 color={colors["black"]} />
          <Typography fontFamily="Poppins-Medium" fontSize={15}>
            Progreso de tus referidos ({referrals["length"]})
          </Typography>
        </Row>
        <Column gap={15}>
          {referrals.map((friend, key) => (
            <Fragment key={key}>
              <Friend friend={friend} />
              {key + 1 !== referrals["length"] && <Line />}
            </Fragment>
          ))}
        </Column>
      </ComponentLoader>
    </Screen>
  );
};
