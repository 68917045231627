import {useNavigate} from "react-router-dom";
import {Column, Screen, Typography} from "components";

export const TermsAndConditions = () => {
  const navigate = useNavigate();

  return (
    <Screen
      header={{onBack: () => navigate(-1), title: "Términos y Condiciones"}}
    >
      <Column padding="1rem 2rem">
        <Column>
          <Typography fontFamily="Poppins-SemiBold">
            1. Aspectos Generales
          </Typography>
          <Typography>
            1.1. Al participar en los sorteos realizados a través de la
            aplicación Centro de Apuesta, los usuarios aceptan y se comprometen
            a cumplir con estos términos y condiciones.
          </Typography>
          <Typography>
            1.2. Estos términos podrán ser actualizados en cualquier momento, y
            los cambios serán notificados a los usuarios a través de la
            aplicación y la página web oficial.
          </Typography>
        </Column>
        <Column>
          <Typography fontFamily="Poppins-SemiBold">
            2. Participación en Sorteos
          </Typography>
          <Typography>
            2.1. Solo podrán participar personas mayores de edad, con documentos
            de identidad válidos y vigentes, registrados correctamente en la
            plataforma.
          </Typography>
          <Typography>
            2.2. El participante es responsable de proporcionar información
            verídica y actualizada durante su registro.
          </Typography>
        </Column>
        <Column>
          <Typography fontFamily="Poppins-SemiBold">
            3. Aspectos Legales del Sorteo
          </Typography>
          <Typography>
            3.1. Errores Técnicos o Humanos: En caso de un error técnico o
            humano durante el desarrollo del sorteo, la empresa se reserva el
            derecho de anular el sorteo afectado y programar uno nuevo para
            garantizar la transparencia del proceso.
          </Typography>
          <Typography>
            3.2. Testimonios Obligatorios: El ganador o ganadora deberá
            participar en un video donde comparta su testimonio sobre su
            experiencia y confirmación de haber ganado el sorteo. Si el ganador
            se niega a realizar esta actividad, la empresa se reserva el derecho
            de retener la entrega del premio hasta que se cumpla este requisito.
          </Typography>
          <Typography>
            3.3. Tiempo de Acreditación: El ganador o ganadora tendrá un lapso
            de (X horas/días) para acreditar que ha sido seleccionado como
            ganador, proporcionando la documentación requerida. De no cumplir
            con este requisito en el tiempo estipulado, el sorteo será declarado
            nulo y se procederá a realizar uno nuevo, descalificando al
            participante en cuestión de futuros sorteos.
          </Typography>
          <Typography>
            3.4. Validación de Identidad: El ganador o ganadora deberá demostrar
            su identidad mediante documentación oficial válida. Los datos
            proporcionados deberán coincidir con los registrados en la
            plataforma durante la inscripción al sorteo.
          </Typography>
          <Typography>
            3.5. Ganadores Fuera del País: En caso de que el ganador se
            encuentre fuera del territorio nacional, podrá designar a un
            apoderado legalmente autorizado para reclamar el premio. Todos los
            gastos asociados a esta gestión correrán por cuenta del ganador.
          </Typography>
          <Typography>
            3.6. Entrega de Premios: La responsabilidad de Centro de Apuesta se
            limita únicamente a la entrega del bien sorteado. Cualquier gasto
            adicional, como transporte, impuestos o trámites legales, será
            responsabilidad exclusiva del ganador.
          </Typography>
        </Column>
        <Column>
          <Typography fontFamily="Poppins-SemiBold">
            4. Relación con Terceros
          </Typography>
          <Typography>
            4.1. Acuerdos con Terceros: Los bienes puestos a disposición para
            los sorteos a través de Centro de Apuesta pueden provenir de
            acuerdos con personas o empresas externas. Estos acuerdos se
            formalizarán mediante contratos que garanticen la ejecución del
            sorteo según lo establecido.
          </Typography>
          <Typography>4.2. Compromisos de los Terceros:</Typography>
          <Column gap={0}>
            <Typography>
              • Los terceros propietarios de los bienes cedidos para sorteos
              aceptan que, una vez iniciado el sorteo, no podrán modificar los
              términos ni retractarse del acuerdo.
            </Typography>
            <Typography>
              • En caso de incumplimiento por parte de terceros, Centro de
              Apuestas se reserva el derecho de tomar acciones legales para
              resarcir los daños ocasionados.
            </Typography>
          </Column>
          <Typography>
            4.3. Exclusión de Responsabilidad: Centro de Apuesta no será
            responsable de disputas entre los terceros y los participantes del
            sorteo una vez entregado el bien sorteado.
          </Typography>
          <Typography>
            4.4. Promoción de Sorteos: Los terceros involucrados en la cesión de
            bienes para sorteos deberán abstenerse de realizar actividades
            promocionales no autorizadas que puedan afectar la reputación de
            Centro de Apuesta.
          </Typography>
        </Column>
        <Column>
          <Typography fontFamily="Poppins-SemiBold">
            5. Derechos y Responsabilidades de la Empresa
          </Typography>
          <Typography>
            5.1. Centro de Apuesta se compromete a garantizar la transparencia y
            la legalidad en todos los sorteos realizados.
          </Typography>
          <Typography>
            5.2. La empresa no se hace responsable por interrupciones en los
            servicios causadas por fallas técnicas, fuerza mayor o eventos fuera
            de su control.
          </Typography>
          <Typography>
            5.3. En caso de controversias, las decisiones tomadas por Centro de
            Apuestas serán finales y vinculantes.
          </Typography>
        </Column>
        <Column>
          <Typography fontFamily="Poppins-SemiBold">
            6. Penalizaciones y Descalificaciones
          </Typography>
          <Typography>
            6.1. Cualquier intento de fraude, manipulación o incumplimiento de
            estos términos y condiciones resultará en la descalificación
            inmediata del participante y, si aplica, acciones legales
            correspondientes.
          </Typography>
        </Column>
        <Column>
          <Typography fontFamily="Poppins-SemiBold">
            7. Resolución de Controversias
          </Typography>
          <Typography>
            7.1. Para cualquier conflicto relacionado con el uso de la
            aplicación o los sorteos, las partes aceptan someterse a la
            jurisdicción de los tribunales del territorio nacional. INVESTIGAR.
          </Typography>
        </Column>

        <Column>
          <Typography fontFamily="Poppins-SemiBold">
            8. Uso de la Aplicación
          </Typography>
          <Typography>
            8.1. Licencia de Uso: Al registrarse, los usuarios reciben una
            licencia limitada, no exclusiva e intransferible para usar la
            aplicación según lo estipulado en estos términos.
          </Typography>
          <Typography>
            8.2. Prohibiciones de Uso: Los usuarios no podrán:
          </Typography>
          <Column gap={0}>
            <Typography>
              • Manipular o intentar alterar el sistema de sorteos.
            </Typography>
            <Typography>
              • Utilizar la aplicación para fines fraudulentos, ilegales o no
              autorizados.
            </Typography>
            <Typography>• Compartir o vender su cuenta a terceros.</Typography>
          </Column>
          <Typography>
            8.3. Acceso y Compatibilidad: La empresa no garantiza que la
            aplicación sea compatible con todos los dispositivos o sistemas
            operativos. Es responsabilidad del usuario asegurarse de contar con
            los requisitos técnicos necesarios.
          </Typography>
          <Typography>
            8.4. Actualizaciones: La aplicación puede requerir actualizaciones
            ocasionales para mejorar el servicio, corregir errores o implementar
            nuevas funciones. Es responsabilidad del usuario mantener la
            aplicación actualizada.
          </Typography>
        </Column>

        <Column>
          <Typography fontFamily="Poppins-SemiBold">
            9. Protección de Datos y Privacidad
          </Typography>
          <Typography>
            9.1. Recolección de Datos: La aplicación recopilará y almacenará los
            datos personales de los usuarios exclusivamente para la gestión de
            los sorteos y el cumplimiento de las obligaciones legales.
          </Typography>
          <Typography>
            9.2. Consentimiento: Al registrarse, los usuarios autorizan a la
            empresa a utilizar sus datos para fines relacionados con los
            sorteos, promociones y campañas de marketing.
          </Typography>
          <Typography>
            9.3. Derechos de los Usuarios: Los usuarios tienen derecho a
            solicitar la actualización, rectificación o eliminación de sus datos
            personales según las leyes de protección de datos aplicables.
          </Typography>
        </Column>

        <Column>
          <Typography fontFamily="Poppins-SemiBold">
            10. Garantías y Limitación de Responsabilidad
          </Typography>
          <Typography>
            10.1. Uso de la Aplicación: Centro de Apuesta no garantiza la
            disponibilidad ininterrumpida de la aplicación ni se hace
            responsable de pérdidas ocasionadas por interrupciones en el
            servicio.
          </Typography>
          <Typography>
            10.2. Exactitud de la Información: Aunque se realizan esfuerzos para
            garantizar la precisión de la información proporcionada, la empresa
            no se responsabiliza por errores de contenido, siempre que no sean
            intencionales.
          </Typography>
        </Column>

        <Column>
          <Typography fontFamily="Poppins-SemiBold">
            11. Gestión de Cuentas
          </Typography>
          <Typography>
            11.1. Suspensión o Eliminación de Cuentas: La empresa podrá
            suspender o eliminar cuentas de usuarios que incumplan estos
            términos y condiciones o realicen actividades sospechosas.
          </Typography>
          <Typography>
            11.2. Transferencia de Cuentas: No está permitido transferir cuentas
            entre usuarios sin autorización expresa de la empresa.
          </Typography>
          <Typography>
            11.3. Restablecimiento de Cuentas: Los usuarios son responsables de
            mantener sus credenciales seguras. En caso de pérdida, podrán
            solicitar el restablecimiento de acceso a través de los canales
            oficiales.
          </Typography>
        </Column>

        <Column>
          <Typography fontFamily="Poppins-SemiBold">
            12. Sistema de Pagos
          </Typography>
          <Typography>
            12.1. Métodos de Pago: La aplicación aceptará únicamente los métodos
            de pago autorizados y descritos en la plataforma.
          </Typography>
          <Typography>
            12.2. Devoluciones: No se realizarán devoluciones de dinero una vez
            que el ticket ha sido comprado, salvo en casos de errores imputables
            a la empresa.
          </Typography>
          <Typography>
            12.3. Comisiones o Cargos Adicionales: La empresa podrá aplicar
            comisiones por transacciones electrónicas realizadas a través de la
            plataforma, lo cual será informado previamente al usuario.
          </Typography>
        </Column>

        <Column>
          <Typography fontFamily="Poppins-SemiBold">
            13. Comunicación y Soporte
          </Typography>
          <Typography>
            13.1. Canales de Contacto: Los usuarios podrán comunicarse con la
            empresa a través de los canales oficiales para consultas, reclamos o
            sugerencias.
          </Typography>
          <Typography>
            13.2. Tiempo de Respuesta: La empresa se compromete a responder a
            las solicitudes de los usuarios en un plazo máximo de (X horas/días)
            hábiles.
          </Typography>
        </Column>

        <Column>
          <Typography fontFamily="Poppins-SemiBold">
            14. Reglas de Participación en los Sorteos
          </Typography>
          <Typography>
            14.1. Elegibilidad: Especificar claramente quiénes pueden participar
            (edad mínima, residencia, etc.).
          </Typography>
          <Typography>
            14.2. Cancelación o Modificación: La empresa se reserva el derecho
            de cancelar o modificar un sorteo por causas de fuerza mayor o
            errores técnicos.
          </Typography>
          <Typography>
            14.3. Responsabilidad del Usuario: Es responsabilidad del usuario
            proporcionar información veraz y actualizada durante el registro y
            compra de tickets.
          </Typography>
        </Column>

        <Column>
          <Typography fontFamily="Poppins-SemiBold">
            15. Propiedad Intelectual
          </Typography>
          <Typography>
            15.1. Derechos de la Empresa: Todo el contenido de la aplicación,
            incluidos logos, diseños, textos, y software, es propiedad de la
            empresa y está protegido por leyes de propiedad intelectual.
          </Typography>
          <Typography>
            15.2. Restricciones: Está prohibido copiar, reproducir, modificar o
            distribuir cualquier elemento de la aplicación sin autorización
            expresa.
          </Typography>
        </Column>

        <Column>
          <Typography fontFamily="Poppins-SemiBold">
            16. Conducta del Usuario
          </Typography>
          <Typography>
            16.1. Comportamiento Aceptable: Los usuarios deben utilizar la
            aplicación de manera ética, respetuosa y en cumplimiento de la ley.
          </Typography>
          <Typography>
            16.2. Sanciones: La empresa podrá suspender o eliminar cuentas de
            usuarios que:
          </Typography>
          <Column gap={0}>
            <Typography>
              • Intenten manipular los resultados de los sorteos.
            </Typography>
            <Typography>
              • Realicen ataques al sistema (hacking, phishing, etc.).
            </Typography>
            <Typography>
              • Difundan contenido ofensivo o inapropiado a través de la
              plataforma.
            </Typography>
          </Column>
        </Column>
        <Column>
          <Typography fontFamily="Poppins-SemiBold">
            17. Responsabilidad por Terceros
          </Typography>
          <Typography>
            17.1. Bienes de Terceros: Si un bien ofrecido en sorteo pertenece a
            un tercero, este debe garantizar que está en condiciones óptimas y
            libre de disputas legales.
          </Typography>
          <Typography>
            17.1. Relación Contractual: La empresa no será responsable si el
            tercero incumple condiciones previamente acordadas, pero se
            compromete a actuar como mediador en caso de conflictos.
          </Typography>
        </Column>

        <Column>
          <Typography fontFamily="Poppins-SemiBold">
            18. Resolución de Disputas
          </Typography>
          <Typography>
            18.1. Arbitraje o Mediación: Las disputas entre usuarios y la
            empresa serán resueltas mediante arbitraje o mediación, según lo
            estipulado por las leyes locales.
          </Typography>
          <Typography>
            18.2. Jurisdicción Aplicable: Definir la jurisdicción específica
            para cualquier disputa legal.
          </Typography>
        </Column>

        <Column>
          <Typography fontFamily="Poppins-SemiBold">
            19. Consentimiento para Uso de Imágenes
          </Typography>
          <Typography>
            19.1. Al participar en los sorteos, los usuarios aceptan que la
            empresa pueda usar su imagen, nombre y testimonio para fines
            promocionales, sin compensación adicional, en caso de resultar
            ganadores.
          </Typography>
        </Column>
        <Column>
          <Typography fontFamily="Poppins-SemiBold">
            20. Uso de Datos
          </Typography>
          <Typography>
            20.1. Cookies: Notificar el uso de cookies para mejorar la
            experiencia del usuario y cumplir con normativas de privacidad.
          </Typography>
          <Typography>
            20.2. Análisis de Datos: Informar que los datos recopilados pueden
            ser usados para análisis internos, como entender patrones de uso y
            mejorar la aplicación.
          </Typography>
        </Column>
        <Column>
          <Typography fontFamily="Poppins-SemiBold">
            21. Devoluciones y Reembolsos
          </Typography>
          <Typography>
            21.1. Política de No Reembolso: Dejar claro que, una vez realizada
            la compra de tickets, no habrá devoluciones excepto por errores
            comprobados de la empresa.
          </Typography>
          <Typography>
            21.2. Reembolsos Automáticos: Especificar bajo qué circunstancias
            (ej. cancelación de un sorteo) la empresa procesará reembolsos
            automáticamente.
          </Typography>
        </Column>
        <Column>
          <Typography fontFamily="Poppins-SemiBold">
            22. Actualizaciones de los Términos
          </Typography>
          <Typography>
            22.1. La empresa se reserva el derecho de actualizar estos términos
            y condiciones en cualquier momento. Se notificará a los usuarios de
            los cambios mediante la aplicación, y su uso continuado se
            interpretará como aceptación de los nuevos términos.
          </Typography>
        </Column>
        <Column>
          <Typography fontFamily="Poppins-SemiBold">
            23. Limitación de Garantías
          </Typography>
          <Typography>
            23.1. La empresa no garantiza que la aplicación esté libre de
            errores o interrupciones y no se responsabiliza por pérdidas
            derivadas de fallos técnicos, conexiones a internet o eventos de
            fuerza mayor.
          </Typography>
        </Column>
        <Column>
          <Typography fontFamily="Poppins-SemiBold">
            24. Terminación del Servicio
          </Typography>
          <Typography>
            24.1. La empresa puede suspender o cancelar el acceso a la
            aplicación en cualquier momento si el usuario incumple los términos
            o si la empresa decide cesar sus operaciones, previa notificación
            razonable.
          </Typography>
        </Column>
        <Column>
          <Typography fontFamily="Poppins-SemiBold">
            25. Contacto y Soporte Técnico
          </Typography>
          <Typography>
            25.1. Incluir información clara sobre cómo los usuarios pueden
            comunicarse con el equipo de soporte técnico, así como los tiempos
            estimados de respuesta.
          </Typography>
        </Column>
      </Column>
    </Screen>
  );
};
