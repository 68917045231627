import styled from "styled-components";

export const Container = styled.div`
  left: 0;
  bottom: 0;
  width: 100%;
  height: 100px;
  display: flex;
  position: fixed;
  padding: 0 1rem;
  align-items: center;
  background-color: #fff;
  border-radius: 15px 15px 0 0;
  justify-content: space-between;
  box-shadow: 0px -2px 3px 1px rgba(0, 0, 0, 0.1);
`;

export const Tab = styled.button`
  gap: 8px;
  height: 75px;
  display: flex;
  border-radius: 5px;
  align-items: center;
  flex-direction: column;
  justify-content: center;
`;
