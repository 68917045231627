import * as Yup from "yup";
import {ValidateRecoverCodePayload} from "interfaces";

export const initialValues: ValidateRecoverCodePayload = {
  phone: "",
  code: "",
};

export const validateRecoverCodeSchema = Yup.object().shape({
  code: Yup.string().required("El código es requerido"),
});
