import {motion} from "framer-motion";
import styled from "styled-components";

export const Container = styled(motion.div)`
  left: 50%;
  width: 95vw;
  top: 1rem;
  display: grid;
  position: fixed;
  border-radius: 8px;
  z-index: 9999999999;
  align-items: center;
  grid-template-columns: 42px auto;
  padding: 0.8rem 1.5rem 0.8rem 1rem;
  box-shadow: 0px 2px 3px 1px rgba(0, 0, 0, 0.2);
  background-color: ${({theme}) => theme["container"]};

  ${({theme}) => theme["breakpoints"].up("sm")} {
    left: 20px;
    width: auto;
    max-width: 420px;
  }
`;
