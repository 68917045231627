import {useEffect} from "react";
import {useAppDispatch} from "hooks";
import {get as getConfigs} from "@redux/slices/config/thunks";
import {get as getLottery} from "@redux/slices/lottery/thunks";

export const useListener = () => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    const listener = setInterval(() => {
      dispatch(getLottery({refresh: false}));
      dispatch(getConfigs());
    }, 10000);

    return () => {
      clearInterval(listener);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};
