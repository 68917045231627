import {Formik} from "formik";
import {colors} from "styles";
import {useEffect} from "react";
import {EditableAvatar} from "./lib";
import {buildSelectOptions} from "utils";
import {UpdateUserPayload} from "interfaces";
import {useNavigate} from "react-router-dom";
import {editUserSchema} from "./form.schema";
import {updateUser} from "@redux/slices/auth/thunks";
import {useAppDispatch, useAppSelector} from "hooks";
import {
  Line,
  Input,
  Column,
  Screen,
  Button,
  Select,
  Textarea,
} from "components";
import {
  getCities,
  getStates,
  getCountries,
} from "@redux/slices/location/thunks";

export const EditProfile = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const {states, cities, countries, request} = useAppSelector("location");
  const {myReferrals, deleted, createdAt, updatedAt, ...initialValues} =
    useAppSelector("auth")["user"];

  useEffect(() => {
    dispatch(getCountries());

    const {stateId, countryId} = initialValues;
    if (stateId && countryId) {
      dispatch(getCities(initialValues["stateId"]));
      dispatch(getStates(initialValues["countryId"]));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <Screen header={{title: "Editar perfil", onBack: () => navigate(-1)}}>
      <EditableAvatar />
      <Column margin="1rem 0">
        <Formik
          enableReinitialize
          validateOnChange={true}
          initialValues={initialValues as UpdateUserPayload}
          validationSchema={editUserSchema}
          onSubmit={(payload) => {
            dispatch(updateUser(payload));
          }}
        >
          {({
            dirty,
            values,
            errors,
            isValid,
            touched,
            handleBlur,
            handleSubmit,
            handleChange,
          }) => {
            const disabled = !(dirty && isValid);

            return (
              <Column width="100%" padding="0 1rem">
                <Column gap={5} width="100%">
                  <Textarea
                    label="Biografía"
                    value={values["description"]}
                    touched={touched["description"]}
                    onBlur={handleBlur("description")}
                    helperErrorText={errors["description"]}
                    placeholder="🏆 Soy nuevo en el Centro de Apuesta"
                    onChange={handleChange("description")}
                  />
                  <Line marginBottom={5} marginTop={0} />
                  <Input
                    label="Nombre"
                    placeholder="Centro de"
                    value={values["name"]}
                    touched={touched["name"]}
                    onBlur={handleBlur("name")}
                    helperErrorText={errors["name"]}
                    onChange={handleChange("name")}
                  />
                  <Input
                    label="Apellido"
                    placeholder="Apuestas"
                    value={values["lastname"]}
                    touched={touched["lastname"]}
                    onBlur={handleBlur("lastname")}
                    helperErrorText={errors["lastname"]}
                    onChange={handleChange("lastname")}
                  />
                  <Input
                    disabled
                    label="Correo"
                    inputMode="email"
                    autoCapitalize="none"
                    value={values["email"]}
                    placeholder="tu@correo.com"
                    touched={touched["email"]}
                    onBlur={handleBlur("email")}
                    helperErrorText={errors["email"]}
                    onChange={handleChange("email")}
                  />
                  <Input
                    disabled
                    inputMode="numeric"
                    value={values["dni"]}
                    placeholder="20111222"
                    touched={touched["dni"]}
                    label="Cédula de Identidad"
                    onBlur={handleBlur("dni")}
                    helperErrorText={errors["dni"]}
                    onChange={handleChange("dni")}
                  />
                  <Input
                    disabled
                    inputMode="tel"
                    label="Teléfono móvil"
                    value={values["phone"]}
                    touched={touched["phone"]}
                    placeholder="04242223344"
                    onBlur={handleBlur("phone")}
                    helperErrorText={errors["phone"]}
                    onChange={handleChange("phone")}
                  />
                  <Input
                    disabled
                    type="date"
                    placeholder="DD/MM/AAAA"
                    value={values["birthday"]}
                    label="Fecha de nacimiento"
                    onBlur={handleBlur("birthday")}
                    helperErrorText={errors["birthday"]}
                    onChange={handleChange("birthday")}
                  />
                  <Select
                    label="País"
                    touched={touched["countryId"]}
                    onBlur={handleBlur("countryId")}
                    helperErrorText={errors["countryId"]}
                    value={values["countryId"] as string}
                    onChange={({target}) => {
                      const value = target["value"];
                      dispatch(getStates(value as string));
                      handleChange({target: {name: "countryId", value}});
                      handleChange({target: {name: "stateId", value: ""}});
                      handleChange({target: {name: "cityId", value: ""}});
                    }}
                    options={buildSelectOptions(
                      countries,
                      "Selecciona tu país",
                      {
                        keyLabel: "name",
                        keyValue: "_id",
                      }
                    )}
                  />
                  <Select
                    label="Estado"
                    touched={touched["stateId"]}
                    onBlur={handleBlur("stateId")}
                    helperErrorText={errors["stateId"]}
                    value={values["stateId"] as string}
                    disabled={
                      !!values["countryId"] && request["states"]["success"]
                    }
                    onChange={({target}) => {
                      const value = target["value"];
                      dispatch(getCities(value as string));
                      handleChange({target: {name: "stateId", value}});
                      handleChange({target: {name: "cityId", value: ""}});
                    }}
                    options={buildSelectOptions(
                      states,
                      "Selecciona tu estado",
                      {
                        keyValue: "_id",
                        keyLabel: "name",
                      }
                    )}
                  />
                  <Select
                    label="Ciudad"
                    touched={touched["cityId"]}
                    onBlur={handleBlur("cityId")}
                    helperErrorText={errors["cityId"]}
                    value={values["cityId"] as string}
                    disabled={
                      !!values["stateId"] && request["cities"]["success"]
                    }
                    onChange={({target}) => {
                      const value = target["value"];
                      handleChange({target: {name: "cityId", value}});
                    }}
                    options={buildSelectOptions(
                      cities,
                      "Selecciona tu ciudad",
                      {
                        keyValue: "_id",
                        keyLabel: "name",
                      }
                    )}
                  />
                </Column>
                <Button
                  disabled={disabled}
                  loaderId="update-user"
                  style={{width: "100%"}}
                  label="Actualizar perfil"
                  onClick={() => handleSubmit()}
                  backgroundColor={colors["primary"]}
                />
              </Column>
            );
          }}
        </Formik>
      </Column>
    </Screen>
  );
};
