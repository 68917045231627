import {Ticket} from "svg";
import {Container} from "./styled";
import {showAlert} from "@redux/slices";
import {ChevronLeft} from "react-feather";
import {useTheme} from "styled-components";
import {useNavigate} from "react-router-dom";
import {useAppDispatch, useAppSelector} from "hooks";
import {Button, Chip, Column, IconButton, Row, Typography} from "components";

interface HeaderProps {}

export const Header: React.FC<HeaderProps> = () => {
  const {error} = useTheme();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const {isLogged, user} = useAppSelector("auth");
  const {number, _id, numbers, totalTicketsSold} =
    useAppSelector("lottery")["lottery"];
  const lotteryCompleted = numbers === totalTicketsSold;

  return (
    <Container>
      <Row padding="10px 15px 5px" justifyContent="space-between">
        <Row width="60%">
          <IconButton
            size={40}
            onClick={() => navigate("/")}
            backgroundColor="transparent"
            icon={<ChevronLeft width={26} height={26} color="#fff" />}
          />
          <Column gap={0}>
            <Typography
              color="#fff"
              fontSize={18}
              fontFamily="Poppins-SemiBold"
            >
              Sorteo #{number}
            </Typography>
          </Column>
        </Row>
        {!lotteryCompleted ? (
          <Button
            label="Comprar"
            leftIcon={<Ticket />}
            onClick={() => {
              if (isLogged) {
                navigate(
                  user["verified"]
                    ? `/lottery/${_id}/tickets`
                    : "/verification/init"
                );
              } else {
                dispatch(
                  showAlert({
                    type: "error",
                    title: "Necesitas iniciar sesión",
                    message: "para poder participar en nuestros sorteos",
                  })
                );
                navigate("/auth/login");
              }
            }}
          />
        ) : (
          <Chip backgroundColor={error}>Tickets Vendidos</Chip>
        )}
      </Row>
    </Container>
  );
};
