import {storage} from "utils";
import {SMSProvider} from "./types";
import {UserModel} from "interfaces";
import {initialState} from "./initial-state";
import {userInitialState} from "@redux/initial-states";
import {createSlice, isAnyOf, PayloadAction} from "@reduxjs/toolkit";
import {
  login,
  signUp,
  updateUser,
  updatePhoto,
  getMyCredits,
  verifyExtraData,
  validateRecoverCode,
  validateVerificationSMSCode,
} from "./thunks";

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setVerificationSMSProvider: function (
      state,
      {payload}: PayloadAction<SMSProvider | undefined>
    ) {
      state["verification"]["SMSProvider"] = payload;
    },
    setLoggedUser: function (state, action: PayloadAction<UserModel>) {
      state["isLogged"] = true;
      state["user"] = action["payload"];
    },
    logOut: function (state) {
      state["isLogged"] = false;
      state["user"] = userInitialState;

      storage["delete"]("[user]");
      storage["delete"]("[auth-token]");
    },
  },
  extraReducers: function (builder) {
    builder.addCase(getMyCredits.fulfilled, function (state, action) {
      state["myCredit"] = action["payload"]["data"];
    });

    builder.addMatcher(
      isAnyOf(
        login.fulfilled,
        signUp.fulfilled,
        updateUser.fulfilled,
        updatePhoto.fulfilled,
        verifyExtraData.fulfilled,
        validateRecoverCode.fulfilled,
        validateVerificationSMSCode.fulfilled
      ),
      function (state, {payload}) {
        const {data} = payload;
        const {token, ...user} = data;

        state["user"] = user;
        state["isLogged"] = true;

        storage["set"]("[user]", user);
        storage["set"]("[auth-token]", token);
      }
    );
  },
});

export const {setLoggedUser, setVerificationSMSProvider, logOut} =
  authSlice["actions"];
export const {reducer: auth} = authSlice;
