import {Formik} from "formik";
import {colors} from "styles";
import {ArrowRightCircle} from "react-feather";
import {initialValues, schema} from "./form.schema";
import {useAppDispatch, useAppSelector} from "hooks";
import {validateVerificationSMSCode} from "@redux/slices/auth/thunks";
import {
  Row,
  Input,
  Column,
  Screen,
  Button,
  Stepper,
  Typography,
} from "components";

export const ValidateVerificationCode = () => {
  const dispatch = useAppDispatch();
  const {user, verification} = useAppSelector("auth");
  const {phone} = user;
  const {SMSProvider} = verification;
  const isWhatsApp = SMSProvider === "whatsapp";

  return (
    <Screen bodyStyle={{padding: 20}}>
      <Stepper steps={4} current={4} />
      <Column gap={15} marginTop={10}>
        <Column gap={5} marginBottom={5}>
          <Row>
            <ArrowRightCircle color={colors["black"]} />
            <Typography fontSize={22} fontFamily="Poppins-Medium">
              {phone}
            </Typography>
          </Row>
          <Typography>
            recibirá un {isWhatsApp ? "WhatsApp" : "mensaje de texto"}
            {""}
            {isWhatsApp ? (
              ""
            ) : (
              <Typography fontFamily="Poppins-SemiBold"> (SMS)</Typography>
            )}
            . Esto puede demorar unos segundos
          </Typography>
        </Column>
        <Formik
          validateOnChange={true}
          validationSchema={schema}
          initialValues={initialValues}
          onSubmit={(payload) => {
            dispatch(validateVerificationSMSCode(payload));
          }}
        >
          {({
            dirty,
            values,
            errors,
            isValid,
            touched,
            handleBlur,
            handleSubmit,
            handleChange,
          }) => {
            const disabled = !(dirty && isValid);
            return (
              <Column width="100%">
                <Input
                  autoFocus
                  maxLength={7}
                  inputMode="numeric"
                  placeholder="0001112"
                  value={values["code"]}
                  touched={touched["code"]}
                  onBlur={handleBlur("code")}
                  style={{textAlign: "center"}}
                  onChange={handleChange("code")}
                  helperErrorText={errors["code"]}
                  label="Introduce el código recibido"
                />
                <Button
                  disabled={disabled}
                  label="Validar código"
                  style={{width: "100%"}}
                  onClick={() => handleSubmit()}
                  backgroundColor={colors["primary"]}
                  loaderId="validate-verification-sms-code"
                />
              </Column>
            );
          }}
        </Formik>
      </Column>
    </Screen>
  );
};
