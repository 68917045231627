import {Column, Row, Skeleton} from "components";

export const SkeletonScreen = () => (
  <Column gap={0} margin="5px 0">
    <Skeleton width={140} height={25} marginBottom={10} highlightMode="dark" />
    <Column gap={15}>
      <Column>
        <Row>
          <Skeleton
            width={30}
            height={30}
            borderRadius={100}
            highlightMode="dark"
          />
          <Skeleton width={100} height={25} highlightMode="dark" />
        </Row>
        <Column gap={5}>
          <Skeleton
            height={25}
            width="40%"
            borderRadius={5}
            highlightMode="dark"
          />
          <Skeleton
            height={65}
            width="100%"
            borderRadius={5}
            highlightMode="dark"
          />
          <Skeleton
            height={25}
            width="40%"
            borderRadius={5}
            highlightMode="dark"
          />
        </Column>
      </Column>
      <Column>
        <Row>
          <Skeleton
            width={30}
            height={30}
            borderRadius={100}
            highlightMode="dark"
          />
          <Skeleton width={100} height={25} highlightMode="dark" />
        </Row>
        <Column gap={5}>
          <Skeleton
            height={25}
            width="40%"
            borderRadius={5}
            highlightMode="dark"
          />
          <Skeleton
            height={65}
            width="100%"
            borderRadius={5}
            highlightMode="dark"
          />
          <Skeleton
            height={25}
            width="40%"
            borderRadius={5}
            highlightMode="dark"
          />
        </Column>
      </Column>
      <Column>
        <Row>
          <Skeleton
            width={30}
            height={30}
            borderRadius={100}
            highlightMode="dark"
          />
          <Skeleton width={100} height={25} highlightMode="dark" />
        </Row>
        <Column gap={5}>
          <Skeleton
            height={25}
            width="40%"
            borderRadius={5}
            highlightMode="dark"
          />
          <Skeleton
            height={65}
            width="100%"
            borderRadius={5}
            highlightMode="dark"
          />
          <Skeleton
            height={25}
            width="40%"
            borderRadius={5}
            highlightMode="dark"
          />
        </Column>
      </Column>
      <Column>
        <Row>
          <Skeleton
            width={30}
            height={30}
            borderRadius={100}
            highlightMode="dark"
          />
          <Skeleton width={100} height={25} highlightMode="dark" />
        </Row>
        <Column gap={5}>
          <Skeleton
            height={25}
            width="40%"
            borderRadius={5}
            highlightMode="dark"
          />
          <Skeleton
            height={65}
            width="100%"
            borderRadius={5}
            highlightMode="dark"
          />
          <Skeleton
            height={25}
            width="40%"
            borderRadius={5}
            highlightMode="dark"
          />
        </Column>
      </Column>
    </Column>
  </Column>
);
