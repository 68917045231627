import {api} from "api";
import {LiveModel} from "interfaces";
import {createAsyncThunk} from "@reduxjs/toolkit";
import {thunkBadRequest} from "@redux/thunk-error";

export const getLive = createAsyncThunk(
  "lives/get-one",
  async (lotteryId: string, {dispatch, rejectWithValue}) => {
    try {
      return await api.Get<LiveModel[]>(`/live/get/${lotteryId}`);
    } catch (error) {
      thunkBadRequest(dispatch, error);
      return rejectWithValue(error);
    }
  }
);

export const getPreviousLive = createAsyncThunk(
  "lives/get-previous-live",
  async (lotteryId: string, {dispatch, rejectWithValue}) => {
    try {
      return await api.Get<LiveModel[]>(`/live/get/${lotteryId}`);
    } catch (error) {
      thunkBadRequest(dispatch, error);
      return rejectWithValue(error);
    }
  }
);
