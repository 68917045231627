import {Typography} from "../..";
import {useTheme} from "styled-components";
import {Eye, EyeOff, Icon} from "react-feather";
import {CSSProperties, InputHTMLAttributes, useState} from "react";
import {
  Container,
  IconLabel,
  InputStyled,
  SecureEntryIcon,
  typographyErrorProps,
} from "./styled";

export interface PasswordInputProps
  extends InputHTMLAttributes<HTMLInputElement> {
  icon?: Icon;
  label?: string;
  touched?: boolean;
  helperErrorText?: string;
  containerStyle?: CSSProperties;
}

export const PasswordInput: React.FC<PasswordInputProps> = (props) => {
  const {placeholderTextColor, blackWhite} = useTheme();
  const [tempValue, setTempValue] = useState<string>("");
  const [secureTextEntry, setSecureTextEntry] = useState<boolean>(true);
  const ShowIcon = secureTextEntry ? EyeOff : Eye;
  const {
    label,
    style,
    icon: Icon,
    containerStyle,
    touched = false,
    helperErrorText,
    ...inputProps
  } = props;
  const {placeholder = "", name, onChange} = inputProps;

  return (
    <Container style={containerStyle}>
      {label && <Typography fontWeight="500">{label}</Typography>}
      <div style={{position: "relative"}}>
        <InputStyled
          id={name}
          placeholder={placeholder}
          className="appearance-none"
          style={{
            ...style,
            padding: Icon ? "0.8rem 0.8rem 0.8rem 45px" : "0.8rem",
          }}
          {...inputProps}
          type={secureTextEntry ? "password" : "text"}
          onChange={(event) => {
            setTempValue(event["target"]["value"]);
            if (onChange) onChange(event);
          }}
        />
        {Icon && (
          <IconLabel htmlFor={name}>
            <Icon
              size={20}
              color={tempValue ? blackWhite : placeholderTextColor}
            />
          </IconLabel>
        )}
        <SecureEntryIcon>
          <ShowIcon
            color="rgba(0, 0, 0, .4)"
            onClick={() => setSecureTextEntry((secured) => !secured)}
          />
        </SecureEntryIcon>
      </div>
      {helperErrorText && touched && (
        <Typography {...typographyErrorProps}>{helperErrorText}</Typography>
      )}
    </Container>
  );
};
