import {colors} from "styles";
import {useAppDispatch} from "hooks";
import {ChevronLeft} from "react-feather";
import {Children, Style} from "interfaces";
import {useTheme} from "styled-components";
import {CSSProperties, useEffect} from "react";
import {setStatusBarColor} from "@redux/slices";
import {
  Row,
  Column,
  Button,
  Typography,
  IconButton,
  ButtonProps,
} from "components";

interface ScreenProps extends Children, Style {
  statusBarColor?: string;
  bodyStyle?: CSSProperties;
  header?: {
    title: string;
    guide?: string;
    textColor?: string;
    onBack?: () => void;
    rightButton?: ButtonProps;
  };
}

export const Screen: React.FC<ScreenProps> = ({
  header,
  children,
  bodyStyle,
  statusBarColor,
  style = {backgroundColor: colors["white"]},
}) => {
  const dispatch = useAppDispatch();
  const {primary, black} = useTheme();

  useEffect(() => {
    dispatch(setStatusBarColor(statusBarColor ?? primary));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [statusBarColor]);

  return (
    <div style={style}>
      {header && (
        <Row paddingTop={15} padding="5px 15px" justifyContent="space-between">
          <Row width={header["rightButton"] ? "80%" : "100%"}>
            {header["onBack"] && (
              <IconButton
                size={40}
                style={{borderRadius: 0}}
                onClick={header["onBack"]}
                backgroundColor="transparent"
                icon={
                  <ChevronLeft
                    color={header["textColor"] ?? black}
                    width={26}
                    height={26}
                  />
                }
              />
            )}
            <Column gap={0}>
              <Typography
                fontSize={17}
                fontFamily="Poppins-SemiBold"
                color={header["textColor"] ?? black}
              >
                {header["title"]}
              </Typography>
              {header["guide"] && (
                <Typography fontSize={12} color={header["textColor"] ?? black}>
                  {header["guide"]}
                </Typography>
              )}
            </Column>
          </Row>
          {header["rightButton"] && <Button {...header["rightButton"]} />}
        </Row>
      )}
      <div style={bodyStyle}>{children}</div>
    </div>
  );
};
