import {useTheme} from "styled-components";
import {useModal, useAppDispatch} from "hooks";
import {reportPayment} from "@redux/slices/payment/thunks";
import {Column, Confirm, Image, Typography} from "components";

export const useReport = () => {
  const {gray} = useTheme();
  const dispatch = useAppDispatch();
  const {openModal, closeModal} = useModal();

  function report(): void {
    openModal(
      <Confirm
        body={
          <Column>
            <Image size={55} objectFit="contain" src="/assets/invoice.png" />
            <Typography fontSize={15} fontFamily="Poppins-SemiBold">
              Enviar reporte de pago
            </Typography>
            <Typography>
              Nuestro sistema confimará si tu pago ya fue recibido con éxito.
            </Typography>
            <Typography>
              Recomendamos revisar los datos de tu reporte antes de continuar.
              En caso de estar todo en orden presiona{" "}
              <Typography fontFamily="Poppins-SemiBold">Reportar</Typography>
            </Typography>
          </Column>
        }
        actions={[
          {
            borderColor: gray,
            variant: "outlined",
            label: "Revisar datos",
            onClick: () => closeModal(),
            typographyProps: {color: gray},
            style: {backgroundColor: "transparent"},
          },
          {
            label: "Reportar",
            loaderId: "report-payment",
            onClick: () => dispatch(reportPayment()),
          },
        ]}
      />
    );
  }

  return {report};
};
