import {tabs} from "./tabs";
import {useAppSelector} from "hooks";
import {Container, Tab} from "./styled";
import {useTheme} from "styled-components";
import {useNavigate} from "react-router-dom";
import {Avatar, Typography} from "components";

interface BottomTabProps {}

export const BottomTab: React.FC<BottomTabProps> = () => {
  const push = useNavigate();
  const {primary} = useTheme();
  const {isLogged, user} = useAppSelector("auth");
  const {picture, name} = user;

  return (
    <Container>
      {tabs.map(({label, path, icon: Icon}, key) => {
        const highlighted = key === 0;
        const displayUserTab = path === "/profile" && isLogged;
        const backgroundColor = highlighted
          ? "rgba(214, 219, 227, .4)"
          : "transparent";

        return (
          <Tab
            key={key}
            style={{
              backgroundColor,
              width: `calc(100vw / ${tabs["length"] + 1})`,
            }}
            onClick={() => {
              if (!isLogged && path === "/profile") {
                push("/auth/login");
              } else {
                push(path);
              }
            }}
          >
            {displayUserTab ? (
              <Avatar
                size={28}
                src={picture}
                border={`2.5px solid ${primary}`}
              />
            ) : (
              <Icon
                width={28}
                height={28}
                strokeWidth={highlighted ? 2.4 : 1.5}
                color={highlighted ? "#334B74" : "#70819D"}
              />
            )}
            <Typography
              fontSize={13}
              style={{color: highlighted ? "#334B74" : "#70819D"}}
              fontFamily={highlighted ? "Poppins-SemiBold" : undefined}
            >
              {displayUserTab ? name : label}
            </Typography>
          </Tab>
        );
      })}
    </Container>
  );
};
