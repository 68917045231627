import {api} from "api";
import {NotificationModel} from "interfaces";
import {createAsyncThunk} from "@reduxjs/toolkit";
import {thunkBadRequest} from "@redux/thunk-error";

export const get = createAsyncThunk(
  "notifications/get",
  async (_, {dispatch, rejectWithValue}) => {
    try {
      return await api.Get<NotificationModel[]>("/notification/getAll");
    } catch (error) {
      thunkBadRequest(dispatch, error);
      return rejectWithValue(error);
    }
  }
);

export const read = createAsyncThunk(
  "notifications/read",
  async (_, {dispatch}): Promise<void> => {
    try {
      await api.Get("/notification/read");
    } catch (error) {
      thunkBadRequest(dispatch, error);
    }
  }
);
