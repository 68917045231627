import {get, read} from "./thunks";
import {createSlice} from "@reduxjs/toolkit";
import {initialState} from "./initial-state";
import {setRequestStatus} from "@redux/request-status";

export const notificationSlice = createSlice({
  name: "notifications",
  initialState,
  reducers: {},
  extraReducers: function (builder) {
    builder.addCase(get.pending, function (state) {
      state["request"]["notifications"] = setRequestStatus("initial");
    });
    builder.addCase(get.fulfilled, function (state, {payload}) {
      const {data} = payload;
      state["notifications"] = data;
      state["unread"] = data.filter((notification) => !notification["read"])[
        "length"
      ];
      state["request"]["notifications"] = setRequestStatus("success");
    });
    builder.addCase(get.rejected, function (state) {
      state["request"]["notifications"] = setRequestStatus("error");
    });

    builder.addCase(read.fulfilled, function (state) {
      const {notifications} = state;
      state["unread"] = 0;
      state["notifications"] = notifications.map((notification) => {
        notification["read"] = true;
        return notification;
      });
    });
  },
});

export const {reducer: notifications} = notificationSlice;
