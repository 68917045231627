import styled from "styled-components";

export const Container = styled.div`
  gap: 5px;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  background-color: #fff;
  transform: translateY(-14px);
  border-radius: 15px 15px 0 0;
`;
