import {colors} from "styles";
import {Check} from "react-feather";
import {useTheme} from "styled-components";
import {useNavigate} from "react-router-dom";
import {Column, Screen, Button, Typography, Image} from "components";

export const InitVerification = () => {
  const navigate = useNavigate();
  const {primary} = useTheme();

  return (
    <Screen bodyStyle={{padding: "2rem"}}>
      <Column gap={0} marginTop={10}>
        <Image
          width={95}
          height={100}
          objectFit="contain"
          src="/assets/init-verification.png"
        />
        <Column gap={5} marginTop={15}>
          <Typography fontFamily="Poppins-SemiBold" fontSize={26}>
            Verificación de cuenta
          </Typography>
          <Typography style={{fontSize: 16}}>
            Necesitas{" "}
            <Typography fontFamily="Poppins-SemiBold" fontSize={16}>
              verificar tu cuenta
            </Typography>{" "}
            para poder participar en todos nuestros sorteos.
          </Typography>
          <Typography fontSize={16} fontFamily="Poppins-Medium">
            ✅ Esto nos ayuda a mantener segura nuestra comunidad.
          </Typography>
        </Column>
      </Column>
      <Column marginTop={30}>
        <Button
          label="Verificarme"
          style={{width: "100%"}}
          backgroundColor={primary}
          leftIcon={<Check color={colors["white"]} />}
          onClick={() => navigate("/verification/verify-extra-data")}
        />
        <Button
          variant="outlined"
          label=" No, luego"
          borderColor={primary}
          onClick={() => navigate("/")}
          typographyProps={{color: primary}}
          style={{borderColor: primary, width: "100%"}}
        />
      </Column>
    </Screen>
  );
};
