import {storage} from "utils";
import {State} from "./types";
import {creditInitialState, userInitialState} from "@redux/initial-states";

export const initialState: State = {
  isLogged: !!storage["read"]("[user]"),
  user: storage["read"]("[user]") || userInitialState,
  myCredit: creditInitialState,
  verification: {
    SMSProvider: undefined,
  },
};
