import {Ticket} from "svg";
import {useEffect} from "react";
import {useTheme} from "styled-components";
import {clearPurchase} from "@redux/slices";
import {useNavigate} from "react-router-dom";
import {useAppDispatch, useAppSelector} from "hooks";
import {Column, Screen, Button, Typography, Image} from "components";

export const WaitingForAdminApproval = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const {white, primary} = useTheme();
  const {lottery} = useAppSelector("lottery");

  useEffect(() => {
    dispatch(clearPurchase());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Screen
      style={{padding: "2rem", backgroundColor: primary, height: "100vh"}}
    >
      <Image
        width={135}
        height={165}
        objectFit="contain"
        src="/assets/waiting.png"
      />
      <Column gap={10} width="100%">
        <Typography color={white} fontFamily="Poppins-SemiBold" fontSize={22}>
          Reservación en proceso de revisión
        </Typography>
        <Column gap={5}>
          <Typography color={white} style={{fontSize: 16}}>
            Actualmente los pagos son verificados por un administrador.
          </Typography>
          <Typography color={white} style={{fontSize: 16}}>
            Una vez que confirmemos tu pago reservaremos tus tickets y te lo
            haremos saber.
          </Typography>
        </Column>
      </Column>
      <Column width="85%" margin="auto" marginTop="1rem">
        <Button
          leftIcon={<Ticket />}
          style={{width: "100%"}}
          label="Reservar más tickets"
          onClick={() => {
            navigate(`/lottery/${lottery["_id"]}/tickets`);
          }}
        />
        <Button
          variant="outlined"
          borderColor={white}
          style={{width: "100%"}}
          label="Volver al inicio"
          onClick={() => navigate("/")}
          typographyProps={{color: white}}
        />
      </Column>
    </Screen>
  );
};
