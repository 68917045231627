import {CSSProperties} from "styled-components";

export const countdownStyle: CSSProperties = {
  padding: 0,
  height: "auto",
  backgroundColor: "transparent",
};

export const countdownLabelStyle: CSSProperties = {
  fontSize: 22,
  color: "#000",
  fontFamily: "Poppins-SemiBold",
};
