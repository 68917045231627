import {Trophy} from "svg";
import {colors} from "styles";
import {useAppDispatch} from "hooks";
import {Repeat} from "react-feather";
import {get} from "@redux/slices/lottery/thunks";
import {Button, Column, Typography} from "components";

interface NoActiveLotteryProps {}

export const NoActiveLottery: React.FC<NoActiveLotteryProps> = () => {
  const dispatch = useAppDispatch();
  return (
    <Column paddingTop={40} alignItems="center" height="calc(100vh - 250px)">
      <Trophy color={colors["white"]} width={50} height={50} />
      <Column gap={5} marginBottom={10} alignItems="center">
        <Typography
          fontSize={16}
          color={colors["white"]}
          fontFamily="Poppins-SemiBold"
          style={{textAlign: "center"}}
        >
          No hay ningún sorteo actualmente
        </Typography>
        <Typography
          color={colors["white"]}
          fontFamily="Poppins-Medium"
          style={{textAlign: "center"}}
        >
          Estamos preparando uno nuevo para ti, regresa más tarde y sigue
          ganando
        </Typography>
      </Column>
      <Button
        label="Actualizar"
        leftIcon={<Repeat width={18} height={18} color={colors["white"]} />}
        onClick={() => {
          dispatch(get());
        }}
      />
    </Column>
  );
};
