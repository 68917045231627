import {Ticket} from "./lib";
import {colors} from "styles";
import {Fragment, useEffect} from "react";
import {useNavigate} from "react-router-dom";
import {List, Plus, Smile} from "react-feather";
import {SkeletonScreen} from "./SkeletonScreen";
import {get} from "@redux/slices/support/thunks";
import {useAppDispatch, useAppSelector} from "hooks";
import {
  Row,
  Line,
  Screen,
  Column,
  Warning,
  Typography,
  ComponentLoader,
  CommonOutlinedErrorBody,
} from "components";

export const Support = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const {tickets, request} = useAppSelector("support");

  useEffect(() => {
    dispatch(get());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Screen
      bodyStyle={{padding: "1rem"}}
      header={{
        title: "Soporte",
        onBack: () => navigate(-1),
        rightButton: {
          label: "Crear Ticket",
          style: {width: "190px"},
          leftIcon: <Plus width={22} height={22} color={colors["white"]} />,
          onClick: () => {
            navigate("/support/create");
          },
        },
      }}
    >
      <Warning
        title="Recordatorio"
        message={`Nuestro equipo te atenderá a la brevedad posible. Por favor, ten paciencia.\n\nPara un contacto directo puedes escribir a soporte@centrodeapuesta.com`}
      />
      <ComponentLoader
        skeleton={<SkeletonScreen />}
        requestStatus={request["tickets"]}
        errorUI={{
          body: <CommonOutlinedErrorBody callback={() => dispatch(get())} />,
        }}
        emptyUI={{
          data: tickets,
          message: "No hay tickets creados",
          icon: <Smile color={colors["black"]} width={40} height={40} />,
        }}
      >
        <Row marginTop={10}>
          <List width={18} height={18} color={colors["black"]} />
          <Typography fontFamily="Poppins-Medium" fontSize={13}>
            Todos tus tickets
          </Typography>
        </Row>
        <Column gap={15} marginTop={15}>
          {tickets.map((ticket, key) => (
            <Fragment key={key}>
              <Ticket ticket={ticket} />
              {key + 1 !== tickets["length"] && <Line />}
            </Fragment>
          ))}
        </Column>
      </ComponentLoader>
    </Screen>
  );
};
