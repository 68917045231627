import {colors} from "styles";
import {useState} from "react";
import {IconProps} from "react-feather";
import {Row, Typography} from "components";
import {Container, Switch} from "./styled";

type Value = string | number;

interface SwitchButtonProps {
  defaultValue?: Value;
  onSelect: (value: Value) => void;
  options: {label: string; icon?: React.FC<IconProps>; value: Value}[];
}

export const SwitchButton: React.FC<SwitchButtonProps> = ({
  options,
  onSelect,
  defaultValue,
}) => {
  const [selected, setSelected] = useState<Value>(
    defaultValue ?? options[0]["value"]
  );
  return (
    <Container>
      {options.map(({label, value, icon: Icon}, key) => {
        const highlighted = value === selected;
        const backgroundColor = highlighted ? colors["secondary"] : "#efefee";
        const selectedColor = highlighted
          ? colors["white"]
          : "rgba(0, 0, 0, .4)";
        return (
          <Switch
            key={key}
            style={{backgroundColor, width: `${100 / options["length"]}%`}}
            onClick={() => {
              onSelect(value);
              setSelected(value);
            }}
          >
            <Row>
              {Icon && <Icon color={selectedColor} />}
              <Typography
                color={selectedColor}
                fontFamily={highlighted ? "Poppins-SemiBold" : "Poppins"}
              >
                {label}
              </Typography>
            </Row>
          </Switch>
        );
      })}
    </Container>
  );
};
