import {colors} from "styles";
import {useEffect} from "react";
import {Container} from "./style";
import {Film} from "react-feather";
import {useTheme} from "styled-components";
import {getMyCredits} from "@redux/slices/auth/thunks";
import {useModal, useAppDispatch, useAppSelector} from "hooks";
import {Row, Image, Button, Column, Typography, VideoModal} from "components";

interface MyTicketsProps {}

export const MyTickets: React.FC<MyTicketsProps> = () => {
  const {white} = useTheme();
  const dispatch = useAppDispatch();
  const {openModal, closeModal} = useModal();
  const {myCredit, isLogged} = useAppSelector("auth");
  const {tickets} = myCredit;

  useEffect(() => {
    setTimeout(() => {
      if (isLogged) dispatch(getMyCredits());
    }, 1200);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLogged]);

  return (
    <Container
      onClick={() => {
        openModal(
          <Column
            gap={0}
            width="85%"
            margin="auto"
            padding="1.5rem"
            borderRadius={10}
            backgroundColor={white}
          >
            <Row>
              <Image size={60} src="/assets/tickets.png" />
              <Typography fontFamily="Poppins-SemiBold" fontSize={18}>
                Cupones ganados
              </Typography>
            </Row>
            <Column gap={8} marginBottom={15}>
              <Typography fontFamily="Poppins-Medium">
                Con estos cupones puedes reservar tickets en los sorteos sin
                tener que pagar nada.
              </Typography>
              <Typography fontSize={16} fontFamily="Poppins-Medium">
                1 cupón = 1 ticket
              </Typography>
            </Column>
            <Button
              label="Descubre cómo ganar cupones"
              leftIcon={<Film color={colors["white"]} />}
              onClick={() => {
                closeModal();
                openModal(
                  <VideoModal source="https://centrodeapuesta.com/public_uploads/referidosc1.mp4" />,
                  {
                    closeButton: {show: true},
                    backgroundColor: "rgba(0,0,0, .7)",
                  }
                );
              }}
            />
          </Column>
        );
      }}
    >
      <Image
        width={20}
        height={20}
        objectFit="contain"
        src="/assets/tickets.png"
      />
      <Typography
        fontSize={18}
        color={colors["white"]}
        fontFamily="Poppins-SemiBold"
      >
        {tickets}
      </Typography>
    </Container>
  );
};
