import {SvgProps} from "interfaces";

export const Live = ({
  width = 24,
  height = 24,
  strokeWidth,
  color = "#000",
}: SvgProps) => (
  <svg
    fill={color}
    width={width}
    height={height}
    viewBox="0 0 24 24"
    strokeWidth={strokeWidth}
  >
    <path d="M21 6h-7.59l3.29-3.29L16 2l-4 4-4-4-.71.71L10.59 6H3c-1.1 0-2 .89-2 2v12c0 1.1.9 2 2 2h18c1.1 0 2-.9 2-2V8c0-1.11-.9-2-2-2m0 14H3V8h18zM9 10v8l7-4z"></path>
  </svg>
);
