import styled from "styled-components";

export const Number = styled.div`
  width: 30px;
  height: 30px;
  display: flex;
  border-radius: 5px;
  align-items: center;
  justify-content: center;
  background-color: ${({theme}) => theme["primary"]};
`;
