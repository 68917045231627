import {useAppSelector} from "hooks";
import {useTheme} from "styled-components";
import {Avatar, Row, Typography} from "components";

interface SubscribersProps {}

export const Subscribers: React.FC<SubscribersProps> = () => {
  const {white} = useTheme();
  const {usersWithoutRepeating} = useAppSelector("lottery")["lottery"];
  const length = usersWithoutRepeating["length"];

  return (
    <Row justifyContent="space-between" gap={0} width="100%">
      {!!length && (
        <Row gap={5}>
          {usersWithoutRepeating.slice(0, 4).map(({picture}, key) => (
            <Avatar
              key={key}
              size={35}
              src={picture}
              borderWidth={2}
              borderColor={white}
              transform={`translateX(${-(15 * key)})`}
            />
          ))}
        </Row>
      )}
      <Typography fontSize={15} fontFamily="Poppins-Medium">
        {length} personas inscritas
      </Typography>
    </Row>
  );
};
