import styled from "styled-components";

export const Container = styled.div`
  width: 55px;
  height: 55px;
  display: flex;
  border-radius: 5px;
  align-items: center;
  justify-content: center;
  box-shadow: 1px 1px 5px 0px rgba(0, 0, 0, 0.1);
`;
