import {Column, Row, Skeleton as UI} from "components";

export const Skeleton = () => (
  <Column width="100%">
    <Row width="100%">
      <UI height={25} width={25} borderRadius={100} highlightMode="dark" />
      <UI height={70} width="100%" highlightMode="dark" />
    </Row>
    <Row width="100%">
      <UI height={25} width={25} borderRadius={100} highlightMode="dark" />
      <UI height={70} width="100%" highlightMode="dark" />
    </Row>
    <Row width="100%">
      <UI height={25} width={25} borderRadius={100} highlightMode="dark" />
      <UI height={70} width="100%" highlightMode="dark" />
    </Row>
    <Row width="100%">
      <UI height={25} width={25} borderRadius={100} highlightMode="dark" />
      <UI height={70} width="100%" highlightMode="dark" />
    </Row>
  </Column>
);
