import {api} from "api";
import {LotteryModel} from "interfaces";
import {createAsyncThunk} from "@reduxjs/toolkit";
import {thunkBadRequest} from "@redux/thunk-error";

export const get = createAsyncThunk(
  "ranking/get",
  async (_, {dispatch, rejectWithValue}) => {
    try {
      return await api.Get<LotteryModel>("/lottery/lastLottery");
    } catch (error) {
      thunkBadRequest(dispatch, error);
      return rejectWithValue(error);
    }
  }
);
