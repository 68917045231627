import {useEffect} from "react";
import {Container} from "./styled";
import {useAppSelector} from "hooks";
import {toggleBodyScroll} from "utils";
import {Fragment} from "react/jsx-runtime";
import {Logo, SpinnerLoader} from "components";

interface FullScreenLoaderProps {}

export const FullScreenLoader: React.FC<FullScreenLoaderProps> = () => {
  const {showFullScreenLoader} = useAppSelector("layout");

  useEffect(() => {
    toggleBodyScroll(showFullScreenLoader);
  }, [showFullScreenLoader]);

  return showFullScreenLoader ? (
    <Container>
      <Logo width={125} height={48} objectFit="cover" />
      <SpinnerLoader size={60} />
    </Container>
  ) : (
    <Fragment />
  );
};
