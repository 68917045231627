import styled from "styled-components";

export const Container = styled.div`
  gap: 5px;
  display: flex;
  height: 40px;
  padding: 0 20px;
  border-radius: 100px;
  flex-direction: row;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.3);
`;
