import {useEffect} from "react";
import {getConfig} from "utils";
import {timeIsExpired} from "../helper";
import {LotteryUIVariant} from "interfaces";
import {setLotteryUIVariant} from "@redux/slices";
import {getLive} from "@redux/slices/lives/thunks";
import {useAppDispatch, useAppSelector} from "hooks";
import {get as getWinners} from "@redux/slices/winners/thunks";

export const useHomeUIVariant = () => {
  const dispatch = useAppDispatch();
  const {live} = useAppSelector("lives");
  const {configs} = useAppSelector("config");
  const {request, lottery, myNumbers} = useAppSelector("lottery");
  const {success} = request["lottery"];

  useEffect(() => {
    const {value: onLiveConfigValue} = getConfig(configs, "on_live");
    let UIVariant: LotteryUIVariant;

    if (lottery && onLiveConfigValue && success) {
      if (onLiveConfigValue === "1") {
        UIVariant = "on-live";
        if (typeof live === "undefined") dispatch(getLive(lottery["_id"]));
      } else {
        if (timeIsExpired(lottery["end_date"])) {
          UIVariant = "waiting-for-live";
        } else if (myNumbers["length"] > 0) {
          UIVariant = "subscribed";
        } else {
          UIVariant = "initial";
        }
      }
    } else {
      UIVariant = "no-active-lottery";
      if (success) dispatch(getWinners());
    }

    dispatch(setLotteryUIVariant(UIVariant));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lottery, myNumbers, configs, live, success]);
};
