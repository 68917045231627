import {colors} from "styles";
import {Notification} from "./lib";
import {Bell} from "react-feather";
import {Fragment, useEffect} from "react";
import {useNavigate} from "react-router-dom";
import {useAppDispatch, useAppSelector} from "hooks";
import {get, read} from "@redux/slices/notifications/thunks";
import {
  Line,
  Column,
  Screen,
  Skeleton,
  ComponentLoader,
  CommonOutlinedErrorBody,
} from "components";

export const Notifications = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const {request, notifications} = useAppSelector("notifications");

  useEffect(() => {
    dispatch(get());
    setTimeout(() => {
      dispatch(read());
    }, 1000);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Screen header={{title: "Notificaciones", onBack: () => navigate(-1)}}>
      <ComponentLoader
        requestStatus={request["notifications"]}
        errorUI={{
          body: <CommonOutlinedErrorBody callback={() => dispatch(get())} />,
        }}
        emptyUI={{
          data: notifications,
          message: "No tienes notificaciones actualmente",
          icon: <Bell color={colors["black"]} width={40} height={40} />,
        }}
        skeleton={
          <Column gap={5}>
            <Skeleton
              count={10}
              height={60}
              width="100%"
              highlightMode="dark"
            />
          </Column>
        }
      >
        <Column gap={0}>
          {notifications.map((notification, key) => (
            <Fragment key={key}>
              <Notification notification={notification} />
              <Line margin={0} />
            </Fragment>
          ))}
        </Column>
      </ComponentLoader>
    </Screen>
  );
};
