import moment from "moment";
import {useState} from "react";
import {Clock} from "react-feather";
import {useAppSelector} from "hooks";
import {useTheme} from "styled-components";
import {Column, Row, Image, Countdown} from "components";
import MaterialCarousel from "react-material-ui-carousel";

interface AwardsProps {}

export const Awards: React.FC<AwardsProps> = () => {
  const {white} = useTheme();
  const [activeIndex, setActiveIndex] = useState<number>(0);
  const {awards, end_date} = useAppSelector("lottery")["lottery"];
  const dots = Array(awards["length"])
    .fill(null)
    .map((_, key) => key);

  return (
    <Column
      width="100%"
      alignItems="center"
      position="relative"
      margin="1rem 0 2rem"
    >
      <Countdown
        onEnded={() => {}}
        icon={<Clock width={20} height={20} color="#fff" />}
        timestamp={moment(moment(end_date)).diff(moment(), "seconds")}
        style={{
          top: -15,
          left: 0,
          zIndex: 5,
          position: "absolute",
        }}
      />
      <div style={{width: "100%", marginTop: 35}}>
        <MaterialCarousel
          swipe
          interval={8000}
          duration={1000}
          animation="slide"
          indicators={false}
          navButtonsAlwaysVisible={false}
          onChange={(now) => setActiveIndex(now as number)}
        >
          {awards.map(({media}, key) => (
            <Column
              key={key}
              height={310}
              position="relative"
              alignItems="center"
              justifyContent="center"
            >
              <Image
                width="100%"
                height={310}
                src={media[0]}
                objectFit="contain"
              />
            </Column>
          ))}
        </MaterialCarousel>
      </div>
      <Row
        left={0}
        zIndex={5}
        height={20}
        bottom={-25}
        width="100%"
        position="absolute"
        justifyContent="center"
      >
        {dots.map((key) => (
          <div
            key={key}
            style={{
              width: 6,
              height: 6,
              borderRadius: 100,
              backgroundColor:
                key === activeIndex ? white : "rgba(255, 255, 255, .4)",
            }}
          />
        ))}
      </Row>
    </Column>
  );
};
