import {Children, Clickable} from "interfaces";
import {CSSProperties, ReactNode} from "react";

interface ContainerProps
  extends Partial<Children<ReactNode>>,
    CSSProperties,
    Partial<Clickable> {}

export const Container: React.FC<ContainerProps> = (props) => {
  const {children, onClick, ...style} = props;
  const {display = "flex", alignItems = "center", gap = 5} = style;
  return (
    <div
      onClick={onClick}
      children={children}
      style={{...style, display, alignItems, gap}}
    />
  );
};
