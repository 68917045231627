import {Column, Line, Typography} from "components";
import {Characteristic as ICharacteristic} from "interfaces";

interface CharacteristicProps {
  showLine?: boolean;
  characteristic: ICharacteristic;
}

export const Characteristic: React.FC<CharacteristicProps> = ({
  characteristic,
  showLine = true,
}) => {
  const {title, description} = characteristic;
  return (
    <Column>
      <Column gap={0}>
        <Typography fontFamily="Poppins-SemiBold" fontSize={15}>
          {title}
        </Typography>
        <Typography fontFamily="Poppins-Light">{description}</Typography>
      </Column>
      {showLine && <Line margin={0} />}
    </Column>
  );
};
