import {Clickable} from "interfaces";
import {Row, Column, Typography} from "components";

interface HrefProps extends Clickable {
  label: string;
  guide?: string;
  icon: JSX.Element;
  textColor?: string;
}

export const Href: React.FC<HrefProps> = ({
  icon,
  label,
  guide,
  textColor,
  ...touchableProps
}) => {
  return (
    <div style={{padding: "5px 0"}} {...touchableProps}>
      <Row justifyContent="space-between">
        <Row gap={15}>
          {icon}
          <Column gap={0} width="90%">
            <Typography
              fontSize={16}
              color={textColor}
              fontFamily="Poppins-Medium"
            >
              {label}
            </Typography>
            {guide && (
              <Typography color={textColor} fontSize={13}>
                {guide}
              </Typography>
            )}
          </Column>
        </Row>
      </Row>
    </div>
  );
};
