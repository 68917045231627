import {Ticket} from "svg";
import {colors} from "styles";
import {showAlert} from "@redux/slices";
import {buildInviteMessage} from "utils";
import {useTheme} from "styled-components";
import {useNavigate} from "react-router-dom";
import {useAppDispatch, useAppSelector} from "hooks";
import {
  Link,
  Globe,
  Check,
  ArrowRight,
  Clipboard as ClipIcon,
} from "react-feather";
import {
  Row,
  Line,
  Button,
  Column,
  Screen,
  Warning,
  Container,
  Typography,
} from "components";

export const Invite = () => {
  const {white} = useTheme();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const {referralCode} = useAppSelector("auth")["user"];

  return (
    <Screen header={{title: "Invitar amigo", onBack: () => navigate(-1)}}>
      <Column padding="1rem">
        <Column alignItems="center">
          <Row justifyContent="center">
            <Typography
              fontSize={28}
              color={colors["black"]}
              fontFamily="Poppins-SemiBold"
            >
              {referralCode}
            </Typography>
            <Container
              width={25}
              height={25}
              borderRadius={100}
              justifyContent="center"
              backgroundColor={colors["black"]}
            >
              <ClipIcon
                width={18}
                height={18}
                color={colors["white"]}
                strokeWidth={2}
              />
            </Container>
          </Row>
          <Typography fontSize={13} style={{textAlign: "center"}}>
            Este es tu código de referido. Todo usuario que se registre usándolo
            te dará beneficios en el Centro de Apuesta
          </Typography>
        </Column>
        <Column marginTop={10} width="100%">
          <Button
            style={{width: "100%"}}
            label="Copiar invitación"
            leftIcon={<Link color={colors["white"]} />}
            onClick={() => {
              navigator.clipboard
                .writeText(buildInviteMessage(referralCode))
                .then(() => {
                  dispatch(
                    showAlert({
                      type: "success",
                      title: "Invitación copiada. Ve y envíasela a tus amigos",
                    })
                  );
                });
            }}
          />
          <Button
            style={{width: "100%"}}
            label="Ver mis referidos"
            backgroundColor="#F1EFF6"
            onClick={() => navigate("/profile/referrals")}
            typographyProps={{color: colors["black"]}}
            rightIcon={<ArrowRight color={colors["black"]} />}
          />
        </Column>
        <Line marginTop={10} />
        <Warning
          title="Recordatorio"
          style={{marginBottom: 30}}
          message="Solamente los usuarios que compran tickets aportan a tu progreso en el sistema de referidos para así ganar beneficios"
        />
        <Column gap={15} marginBottom={50}>
          <Typography fontFamily="Poppins-Medium" fontSize={16}>
            ¿Qué sucederá cuando tu amigo/a abra el enlace que has enviado?
          </Typography>
          <Column position="relative" gap={25}>
            <Row alignItems="flex-start" gap={20}>
              <Container
                width={35}
                height={35}
                borderRadius={100}
                justifyContent="center"
                backgroundColor={colors["primary"]}
              >
                <Globe width={20} height={20} strokeWidth={2} color={white} />
              </Container>
              <Column gap={0} width="85%">
                <Typography fontFamily="Poppins-Medium" fontSize={16}>
                  Ingresará a nuestra Aplicación Web
                </Typography>
                <Typography fontSize={13}>
                  Donde verá el formulario de registro para llenar con sus datos
                </Typography>
              </Column>
            </Row>
            <Row alignItems="flex-start" gap={20}>
              <Container
                width={35}
                height={35}
                borderRadius={100}
                justifyContent="center"
                backgroundColor={colors["primary"]}
              >
                <Check width={20} height={20} color={white} strokeWidth={2} />
              </Container>
              <Column gap={0} width="85%">
                <Typography fontFamily="Poppins-Medium" fontSize={16}>
                  Una vez registrado se contará como referido a tu lista
                </Typography>
                <Typography fontSize={13}>
                  Esto se hará automáticamente, así que no debes preocuparte
                </Typography>
              </Column>
            </Row>
            <Row alignItems="flex-start" gap={20}>
              <Container
                width={35}
                height={35}
                borderRadius={100}
                justifyContent="center"
                backgroundColor={colors["primary"]}
              >
                <Ticket width={20} height={20} color={white} strokeWidth={2} />
              </Container>
              <Column gap={0} width="85%">
                <Typography fontFamily="Poppins-Medium" fontSize={16}>
                  Incentiva a tu amigo a comprar tickets
                </Typography>
                <Typography fontSize={13}>
                  De esta forma comenzarás a ganar premios dentro del Centro de
                  Apuesta
                </Typography>
              </Column>
            </Row>
          </Column>
        </Column>
      </Column>
    </Screen>
  );
};
