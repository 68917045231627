import {State} from "./types";
import {get, getMyNumbers} from "./thunks";
import {initialState} from "./initial-state";
import {setRequestStatus} from "@redux/request-status";
import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {LotteryUIVariant, RequestStatus} from "interfaces";

export const lotterySlice = createSlice({
  name: "lottery",
  initialState,
  reducers: {
    setLotteryRequestStatus: function (
      state,
      {
        payload,
      }: PayloadAction<{
        name: keyof State["request"];
        status: RequestStatus;
      }>
    ) {
      const {name, status} = payload;
      state["request"][name] = status;
    },
    setLotteryUIVariant: function (
      state,
      {payload}: PayloadAction<LotteryUIVariant>
    ) {
      state["UIVariant"] = payload;
    },
    setResetSlice: function (state) {
      state["myNumbers"] = [];
      state["UIVariant"] =
        state["UIVariant"] === "subscribed" ? "initial" : state["UIVariant"];
    },
  },
  extraReducers: function (builder) {
    builder.addCase(get.fulfilled, function (state, {payload}) {
      const {data} = payload;
      state["lottery"] = data[0];
      state["request"]["lottery"] = setRequestStatus("success");
    });
    builder.addCase(get.rejected, function (state) {
      state["request"]["lottery"] = setRequestStatus("error");
    });

    builder.addCase(getMyNumbers.fulfilled, function (state, {payload}) {
      const {data} = payload;
      state["myNumbers"] = data;
      state["request"]["myNumbers"] = setRequestStatus("success");
    });
  },
});

export const {reducer: lottery} = lotterySlice;
export const {setLotteryRequestStatus, setResetSlice, setLotteryUIVariant} =
  lotterySlice["actions"];
