import {colors} from "styles";
import {Container} from "./styled";
import {Typography, Column, Image} from "../";
import {CSSProperties, ReactNode} from "react";

export interface ErrorUIStateProps {
  icon?: ReactNode;
  body?: ReactNode;
  message?: string;
  style?: CSSProperties;
  defaultMessageColor?: string;
}

export const ErrorUIState: React.FC<ErrorUIStateProps> = ({
  icon,
  body,
  message,
  style: customStyle,
  defaultMessageColor = colors["black"],
}) => {
  return (
    <Container style={customStyle}>
      {icon ?? (
        <Image
          width={50}
          height={50}
          marginBottom={10}
          src="/assets/placeholder/error.png"
        />
      )}
      {message ? (
        <Typography
          fontSize={15}
          color={defaultMessageColor}
          fontFamily="Poppins-Medium"
          style={{textAlign: "center"}}
        >
          {message}
        </Typography>
      ) : (
        <Column gap={5} alignItems="center">
          <Typography
            fontSize={15}
            color={defaultMessageColor}
            fontFamily="Poppins-Medium"
            style={{textAlign: "center"}}
          >
            ¡Ha sucedido un error!
          </Typography>
          <Typography color={defaultMessageColor} style={{textAlign: "center"}}>
            vuelve a intentarlo en un momento
          </Typography>
        </Column>
      )}

      {body && body}
    </Container>
  );
};
