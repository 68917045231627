import {Pathname} from "interfaces";
import {IconProps} from "react-feather";
import {Award, Home, User, Users} from "react-feather";

type Tab = {
  label: string;
  path: Pathname;
  icon: React.FC<IconProps>;
};

export const tabs: Tab[] = [
  {
    icon: Home,
    path: "/",
    label: "Sorteo",
  },
  {
    icon: Award,
    path: "/ranking",
    label: "Posiciones",
  },
  {
    icon: Users,
    path: "/testimonials",
    label: "Testimon.",
  },
  {
    icon: User,
    path: "/profile",
    label: "Perfil",
  },
];
