import {get} from "./thunks";
import {initialState} from "./initial-state";
import {createSlice} from "@reduxjs/toolkit";
import {setRequestStatus} from "@redux/request-status";

export const winnersSlice = createSlice({
  name: "winners",
  initialState,
  reducers: {
    alreadySeenIt: function (state) {
      state["seen"] = true;
    },
  },
  extraReducers: function (builder) {
    builder.addCase(get.fulfilled, function (state, {payload}) {
      const {data} = payload;

      state["winners"] = data;
      state["request"]["winners"] = setRequestStatus("success");
    });
    builder.addCase(get.rejected, function (state) {
      state["request"]["winners"] = setRequestStatus("error");
    });
  },
});

export const {reducer: winners} = winnersSlice;
export const {alreadySeenIt} = winnersSlice["actions"];
