import {breakpointsTheme, colors} from "styles";
import {ThemeMode} from "interfaces";
import {DefaultTheme} from "styled-components";

export const buildTheme = ({
  mode,
  isDark,
}: Readonly<{mode: ThemeMode; isDark: boolean}>) => {
  const theme: DefaultTheme = {
    ...breakpointsTheme,
    mode,
    isDark,
    frozen: "#8db39f",
    reserved: "rgba(0,0,0, .05)",
    gray: colors["gray"],
    white: colors["white"],
    heart: colors["heart"],
    error: colors["heart"],
    black: colors["black"],
    primary: colors["primary"],
    success: colors["success"],
    textGray: colors["textGray"],
    secondary: colors["secondary"],
    buttonHover: colors["buttonHover"],
    body: colors[isDark ? "dark" : "light"],
    container: colors[isDark ? "dark2" : "white"],
    avatarBorderColor: colors["avatarBorderColor"],
    blackWhite: colors[isDark ? "white" : "black"],
    line: colors[isDark ? "darkLine" : "lightLine"],
    primaryTransparent: colors["primaryTransparent"],
    title: colors[isDark ? "darkTitle" : "lightTitle"],
    inputTextColor: colors[isDark ? "white" : "black"],
    adminBackgroundMessage: colors["adminBackgroundMessage"],
    navHrefTextColor: colors[isDark ? "white08" : "black08"],
    disabledIconColor: colors[isDark ? "white04" : "black04"],
    toggleBackground: colors[isDark ? "secondary" : "black01"],
    zoomModalBackground: colors[isDark ? "white03" : "black03"],
    placeholderTextColor: colors[isDark ? "white05" : "black05"],
    disabledButtonTextColor: colors[isDark ? "white04" : "black04"],
    hover: isDark ? "rgba(255, 255, 255, .02)" : "rgba(0, 0, 0, .01)",
    skeletonBaseColor:
      colors[isDark ? "darkSkeletonBaseColor" : "lightSkeletonBaseColor"],
    skeletonHighlightColor:
      colors[
        isDark ? "darkSkeletonHighlightColor" : "lightSkeletonHighlightColor"
      ],

    // THESE WILL BE DELETED
    white01: colors["white01"],
    black05: colors["black05"],
    white05: colors["white05"],
    black01: colors["black01"],
    black06: colors["black06"],
    white06: colors["white06"],
    black03: colors["black03"],
    white004: colors["white004"],
    black004: colors["black004"],
    black003: colors["black003"],
    white003: colors["white003"],
  };
  return theme;
};
