import {State} from "./types";
import {setRequestStatus} from "@redux/request-status";

export const initialState: State = {
  live: undefined,
  previousLive: undefined,
  request: {
    live: setRequestStatus("initial"),
    previousLive: setRequestStatus("initial"),
  },
};
