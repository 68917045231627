import {SvgProps} from "interfaces";

export const Shake = ({color = "#000"}: SvgProps) => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none">
    <path
      d="M10.1834 16.5417C10.0334 16.6917 9.76674 16.7167 9.59174 16.5417C9.44174 16.3917 9.41674 16.125 9.59174 15.95L12.4167 13.125L11.2417 11.95L8.41674 14.775C8.25841 14.9417 7.99174 14.9333 7.82508 14.775C7.65008 14.6 7.67508 14.3333 7.82508 14.1833L10.6501 11.3583L9.47508 10.1833L6.65008 13.0083C6.50008 13.1583 6.23341 13.1833 6.05841 13.0083C5.90008 12.85 5.90008 12.5833 6.05841 12.4167L8.88341 9.59166L7.70008 8.41666L4.87508 11.2417C4.72508 11.3917 4.45841 11.4167 4.28341 11.2417C4.12508 11.075 4.12508 10.8167 4.28341 10.65L7.93341 7L9.49174 8.55C10.2834 9.34166 11.6501 9.33333 12.4417 8.55C13.2584 7.73333 13.2584 6.41666 12.4417 5.6L10.8917 4.05L11.1251 3.81666C11.7751 3.16666 12.8334 3.16666 13.4834 3.81666L17.0167 7.35C17.6667 8 17.6667 9.05833 17.0167 9.70833L10.1834 16.5417ZM18.1917 10.8917C19.4917 9.59166 19.4917 7.48333 18.1917 6.175L14.6584 2.64166C13.3584 1.34166 11.2501 1.34166 9.94174 2.64166L9.70841 2.875L9.47508 2.64166C8.17508 1.34166 6.06674 1.34166 4.75841 2.64166L1.80841 5.59166C0.625077 6.775 0.516743 8.61666 1.47508 9.91666L2.68341 8.70833C2.35841 8.08333 2.46674 7.29166 2.99174 6.76666L5.94174 3.81666C6.59174 3.16666 7.65008 3.16666 8.30008 3.81666L11.2667 6.78333C11.4167 6.93333 11.4417 7.2 11.2667 7.375C11.0917 7.55 10.8251 7.525 10.6751 7.375L7.93341 4.64166L3.10008 9.46666C2.28341 10.275 2.28341 11.6 3.10008 12.4167C3.42508 12.7417 3.84174 12.9417 4.28341 13C4.34174 13.4333 4.53341 13.85 4.86674 14.1833C5.20008 14.5167 5.61674 14.7083 6.05008 14.7667C6.10841 15.2 6.30008 15.6167 6.63341 15.95C6.96674 16.2833 7.38341 16.475 7.81674 16.5333C7.87508 16.9833 8.07508 17.3917 8.40008 17.7167C8.79174 18.1083 9.31674 18.325 9.87508 18.325C10.4334 18.325 10.9584 18.1083 11.3501 17.7167L18.1917 10.8917Z"
      fill={color}
    />
  </svg>
);
