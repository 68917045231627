import {Fragment} from "react";
import {Column} from "components";
import {useAppSelector} from "hooks";
import {PaymentMethod} from "interfaces";
import {Bank, Electronic, MobilePayment, ScreenShot} from "./lib";

interface PaymentFormProps {}

function renderUI(method: PaymentMethod): JSX.Element {
  switch (method) {
    case "zelle":
    case "binance":
      return <Electronic />;
    case "bank":
      return <Bank />;
    case "mobile-payment":
      return <MobilePayment />;

    default:
      return <Fragment />;
  }
}

export const PaymentForm: React.FC<PaymentFormProps> = () => {
  const {paymentMethod} = useAppSelector("payment");
  return (
    <Column gap={5} marginBottom={50} width="100%">
      {renderUI(paymentMethod as PaymentMethod)}
      <ScreenShot />
    </Column>
  );
};
