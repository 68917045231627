import {Formik} from "formik";
import {useEffect} from "react";
import {colors} from "styles";
import {buildSelectOptions} from "utils";
import {useAppDispatch, useAppSelector} from "hooks";
import {verifyExtraData} from "@redux/slices/auth/thunks";
import {initialValues, verifyExtraDataSchema} from "./form.schema";
import {
  getStates,
  getCities,
  getCountries,
} from "@redux/slices/location/thunks";
import {
  Row,
  Line,
  Image,
  Input,
  Column,
  Select,
  Screen,
  Button,
  Stepper,
  Typography,
} from "components";

export const VerifyExtraData = () => {
  const dispatch = useAppDispatch();
  const {countries, states, cities, request} = useAppSelector("location");

  useEffect(() => {
    dispatch(getCountries());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Screen bodyStyle={{padding: 20}}>
      <Stepper steps={4} current={1} />
      <Row marginTop={10}>
        <Image
          width={45}
          height={45}
          objectFit="contain"
          src="/assets/user-verification.png"
        />
        <Typography fontFamily="Poppins-SemiBold" fontSize={18}>
          Actualicemos tu perfil
        </Typography>
      </Row>
      <Line marginTop={10} marginBottom={15} />
      <Formik
        enableReinitialize
        validateOnChange={true}
        initialValues={initialValues}
        validationSchema={verifyExtraDataSchema}
        onSubmit={(payload) => {
          dispatch(verifyExtraData(payload));
        }}
      >
        {({
          dirty,
          values,
          errors,
          isValid,
          touched,
          handleBlur,
          handleSubmit,
          handleChange,
        }) => {
          const disabled = !(dirty && isValid);

          return (
            <Column paddingBottom={50}>
              <Column gap={5} width="100%">
                <Input
                  autoFocus
                  type="email"
                  label="Correo"
                  autoComplete="off"
                  autoCapitalize="none"
                  value={values["email"]}
                  placeholder="tu@correo.com"
                  touched={touched["email"]}
                  onBlur={handleBlur("email")}
                  helperErrorText={errors["email"]}
                  onChange={handleChange("email")}
                />
                <Input
                  inputMode="numeric"
                  value={values["dni"]}
                  placeholder="20111222"
                  touched={touched["dni"]}
                  onBlur={handleBlur("dni")}
                  helperErrorText={errors["dni"]}
                  label="Cédula de Identidad (DNI)"
                  onChange={handleChange("dni")}
                />
                <Select
                  label="País"
                  touched={touched["countryId"]}
                  onBlur={handleBlur("countryId")}
                  helperErrorText={errors["countryId"]}
                  value={values["countryId"] as string}
                  onChange={({target}) => {
                    const value = target["value"];
                    dispatch(getStates(value as string));
                    handleChange({target: {name: "countryId", value}});
                    handleChange({target: {name: "stateId", value: ""}});
                    handleChange({target: {name: "cityId", value: ""}});
                  }}
                  options={buildSelectOptions(countries, "Selecciona tu país", {
                    keyLabel: "name",
                    keyValue: "_id",
                  })}
                />
                <Select
                  label="Estado"
                  touched={touched["stateId"]}
                  onBlur={handleBlur("stateId")}
                  helperErrorText={errors["stateId"]}
                  value={values["stateId"] as string}
                  disabled={
                    !(values["countryId"] && request["states"]["success"])
                  }
                  onChange={({target}) => {
                    const value = target["value"];
                    dispatch(getCities(value as string));
                    handleChange({target: {name: "stateId", value}});
                    handleChange({target: {name: "cityId", value: ""}});
                  }}
                  options={buildSelectOptions(states, "Selecciona tu estado", {
                    keyValue: "_id",
                    keyLabel: "name",
                  })}
                />
                <Select
                  label="Ciudad"
                  touched={touched["cityId"]}
                  onBlur={handleBlur("cityId")}
                  helperErrorText={errors["cityId"]}
                  value={values["cityId"] as string}
                  disabled={
                    !(values["stateId"] && request["cities"]["success"])
                  }
                  onChange={({target}) => {
                    const value = target["value"];
                    handleChange({target: {name: "cityId", value}});
                  }}
                  options={buildSelectOptions(cities, "Selecciona tu ciudad", {
                    keyValue: "_id",
                    keyLabel: "name",
                  })}
                />
              </Column>
              <Button
                label="Guardar"
                disabled={disabled}
                style={{width: "100%"}}
                loaderId="verify-extra-data"
                onClick={() => handleSubmit()}
                backgroundColor={colors["primary"]}
              />
            </Column>
          );
        }}
      </Formik>
    </Screen>
  );
};
