import {useState} from "react";
import {rankColor} from "utils";
import {Characteristic} from "./lib";
import {useAppSelector} from "hooks";
import {AwardModel} from "interfaces";
import {Award, Info} from "react-feather";
import {useTheme} from "styled-components";
import {Row, Column, Typography, Absolute} from "components";

interface AwardsProps {}

export const Awards: React.FC<AwardsProps> = () => {
  const {primary, white} = useTheme();
  const {awards} = useAppSelector("lottery")["lottery"];
  const [award, setAward] = useState<AwardModel>(awards[0]);
  const {media, characteristics = [], position} = award;

  return (
    <Column
      width="100%"
      marginTop={75}
      marginBottom={100}
      position="relative"
      backgroundColor={primary}
    >
      <Row padding="0 1rem">
        <Info width={20} height={20} color={white} />
        <Typography fontFamily="Poppins-Medium" fontSize={16} color={white}>
          Al comprar un ticket participas por
        </Typography>
      </Row>
      <Row
        width="100%"
        marginTop={10}
        padding="0 1rem"
        justifyContent="space-between"
      >
        <Row>
          <Row
            width={35}
            height={35}
            borderRadius={100}
            justifyContent="center"
            backgroundColor={rankColor[1]}
          >
            <Award width={22} strokeWidth={3} height={22} color="#fff" />
          </Row>
          <Typography fontFamily="Poppins-Medium" fontSize={16} color={white}>
            #{position} Lugar
          </Typography>
        </Row>
        <Row>
          {awards.map((item, key) => (
            <div
              key={key}
              onClick={() => setAward(item)}
              style={{
                width: 42,
                height: 42,
                display: "flex",
                borderRadius: 5,
                alignItems: "center",
                justifyContent: "center",
                backgroundColor:
                  Number(item["position"]) === Number(award["position"])
                    ? rankColor[Number(item["position"])]
                    : "transparent",
              }}
            >
              <Typography color={white}>#{item["position"]}</Typography>
            </div>
          ))}
        </Row>
      </Row>
      <Row justifyContent="center" width="100%" padding="1rem 0">
        <img alt="" src={media[0]} style={{objectFit: "cover", width: "90%"}} />
      </Row>
      {!!characteristics["length"] && (
        <Column>
          {characteristics.map((characteristic, key) => (
            <Characteristic
              key={key}
              characteristic={characteristic}
              showLine={key + 1 !== characteristics["length"]}
            />
          ))}
        </Column>
      )}
      <Absolute
        left={0}
        bottom="-84px"
        height={100}
        width="100vw"
        rotate="180deg"
        position="absolute"
        transform={"translateY(-1px)"}
      >
        <svg viewBox="0 0 1440 320">
          <path
            fill={primary}
            fillOpacity="1"
            d="M0,128L48,112C96,96,192,64,288,69.3C384,75,480,117,576,112C672,107,768,53,864,37.3C960,21,1056,43,1152,53.3C1248,64,1344,64,1392,64L1440,64L1440,320L1392,320C1344,320,1248,320,1152,320C1056,320,960,320,864,320C768,320,672,320,576,320C480,320,384,320,288,320C192,320,96,320,48,320L0,320Z"
          />
        </svg>
      </Absolute>
      <Absolute
        left={0}
        top="-84px"
        height={100}
        width="100vw"
        position="absolute"
        transform={"translateY(-1px)"}
      >
        <svg viewBox="0 0 1440 320">
          <path
            fill={primary}
            fillOpacity="1"
            d="M0,128L48,112C96,96,192,64,288,69.3C384,75,480,117,576,112C672,107,768,53,864,37.3C960,21,1056,43,1152,53.3C1248,64,1344,64,1392,64L1440,64L1440,320L1392,320C1344,320,1248,320,1152,320C1056,320,960,320,864,320C768,320,672,320,576,320C480,320,384,320,288,320C192,320,96,320,48,320L0,320Z"
          />
        </svg>
      </Absolute>
    </Column>
  );
};
