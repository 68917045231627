import {Column, Row, Skeleton} from "components";

export const SkeletonScreen = () => (
  <Column width="100%">
    <Column width="100%">
      <Skeleton highlightMode="dark" height={50} width="100%" />
      <Column width="100%">
        <Row justifyContent="space-between" padding="0 1rem">
          <Row>
            <Skeleton
              height={45}
              width={45}
              borderRadius={100}
              highlightMode="dark"
            />
            <Column gap={0} width="100%">
              <Skeleton height={20} width={100} highlightMode="dark" />
              <Skeleton height={20} width={150} highlightMode="dark" />
            </Column>
          </Row>
          <Skeleton
            height={40}
            width={80}
            borderRadius={100}
            highlightMode="dark"
          />
        </Row>
        <Skeleton height={500} width="100%" highlightMode="dark" />
      </Column>
    </Column>
    <Column width="100%">
      <Skeleton highlightMode="dark" height={50} width="100%" />
      <Column width="100%">
        <Row justifyContent="space-between" padding="0 1rem">
          <Row>
            <Skeleton
              height={45}
              width={45}
              borderRadius={100}
              highlightMode="dark"
            />
            <Column gap={0} width="100%">
              <Skeleton height={20} width={100} highlightMode="dark" />
              <Skeleton height={20} width={150} highlightMode="dark" />
            </Column>
          </Row>
          <Skeleton
            width={80}
            height={40}
            borderRadius={100}
            highlightMode="dark"
          />
        </Row>
        <Skeleton height={500} width="100%" highlightMode="dark" />
      </Column>
    </Column>
  </Column>
);
