import {Style} from "interfaces";
import {CSSProperties, Fragment} from "react";
import UISkeleton from "react-loading-skeleton";

interface SkeletonProps extends CSSProperties, Style {
  count?: number;
  highlightMode?: "light" | "dark";
}

export const Skeleton = (props: SkeletonProps) => {
  const {count = 1, style, highlightMode, ...CSSstyle} = props;
  const darkMode = highlightMode === "dark";
  const highlightColor = darkMode
    ? "rgba(0, 0, 0, .05)"
    : "rgba(255, 255, 255, .2)";
  const backgroundColor = darkMode
    ? "rgba(0, 0, 0, .03)"
    : "rgba(255, 255, 255, .1)";
  const items = Array(count)
    .fill(null)
    .map((_, key) => key);
  return (
    <Fragment>
      {items.map((key) => (
        <div style={{...CSSstyle, ...style}} key={key}>
          <UISkeleton
            width="100%"
            height="100%"
            baseColor={backgroundColor}
            highlightColor={highlightColor}
          />
        </div>
      ))}
    </Fragment>
  );
};
