import moment from "moment";
import {colors} from "styles";
import {Shake, Trophy} from "svg";
import {showAlert} from "@redux/slices";
import {isVideo, rankColor} from "utils";
import {useTheme} from "styled-components";
import {useNavigate} from "react-router-dom";
import {useAppDispatch, useAppSelector} from "hooks";
import {TestimonialModel, UserModel} from "interfaces";
import {congratulate} from "@redux/slices/testimonials/thunks";
import {
  Row,
  Chip,
  Image,
  Column,
  Avatar,
  Container,
  Typography,
} from "components";

interface TestimonialProps {
  user: UserModel;
  awardId: string;
  position: string;
  lotteryId: string;
  testimonial: TestimonialModel;
}

export const Testimonial: React.FC<TestimonialProps> = ({
  user,
  awardId,
  position,
  lotteryId,
  testimonial,
}) => {
  const {white} = useTheme();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const {name, lastname, picture} = user;
  const {isLogged, user: loggedUser} = useAppSelector("auth");
  const {media, created_at, tocongratulate, message} = testimonial;
  const iLiked =
    typeof tocongratulate.find(
      ({user: userId}) => userId === loggedUser["_id"]
    ) !== "undefined";
  const buttonColor = iLiked ? colors["primary"] : "#F2F2F2";
  const buttonTextColor = iLiked ? colors["white"] : colors["black"];
  const src = media[0];

  return (
    <Container backgroundColor={white} flexDirection="column" display="flex">
      <Row gap={15} width="100%" padding="1rem" justifyContent="space-between">
        <Row>
          <Avatar size={35} src={picture} />
          <Column gap={0}>
            <Typography fontFamily="Poppins-Medium">
              {name} {lastname}
            </Typography>
            <Typography fontSize={11} color="rgba(0, 0, 0, .6)">
              publicado {moment(created_at).fromNow()}
            </Typography>
          </Column>
        </Row>
        <Chip padding="0 1rem" backgroundColor={rankColor[Number(position)]}>
          <Row>
            <Trophy color={colors["white"]} />
            <Typography
              fontSize={16}
              color={colors["white"]}
              fontFamily="Poppins-Medium"
            >
              #{position}
            </Typography>
          </Row>
        </Chip>
      </Row>
      <Container position="relative">
        {isVideo(src) ? (
          <video
            muted
            autoPlay
            controls
            src={media[0]}
            style={{width: "100%", height: "100%"}}
          />
        ) : (
          <Image src={src} width="100%" height="100%" />
        )}
      </Container>
      <Column
        gap={5}
        paddingTop={10}
        padding=".5rem 1rem 0"
        alignItems="flex-start"
      >
        <Row width="100%">
          <Image
            width={30}
            height={30}
            objectFit="contain"
            src="/assets/dice.png"
          />
          <Typography
            fontSize={14}
            fontFamily="Poppins-Medium"
            style={{fontStyle: "italic"}}
          >
            {message.trimEnd()}
          </Typography>
        </Row>
      </Column>
      <Row padding={15} gap={10} paddingTop={10} width="100%">
        <Row
          padding={10}
          cursor="pointer"
          borderRadius={100}
          backgroundColor={buttonColor}
          onClick={() => {
            if (isLogged) {
              dispatch(congratulate({awardId, lotteryId, celebrated: !iLiked}));
            } else {
              dispatch(
                showAlert({
                  type: "error",
                  title: "Necesitas ingresar en tu cuenta primero",
                })
              );
              navigate("/auth/login");
            }
          }}
        >
          <Shake color={buttonTextColor} />
          <Typography color={buttonTextColor}>
            {iLiked ? "Felicitación enviada" : "Felicitar"}
          </Typography>
        </Row>
        <Row>
          <Typography fontSize={15} fontFamily="Poppins-Medium">
            {tocongratulate["length"]} felicitaciones
          </Typography>
        </Row>
      </Row>
    </Container>
  );
};
