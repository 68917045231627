import * as Yup from "yup";
import {ChangePasswordWithCodePayload} from "interfaces";

export const initialValues: ChangePasswordWithCodePayload = {
  code: "",
  phone: "",
  password: "",
  confirm_password: "",
};

export const chagePasswordSchema = Yup.object().shape({
  password: Yup.string().required("El código es requerido"),
  confirm_password: Yup.string()
    .oneOf([Yup.ref("password")], "Las contraseñas deben coincidir")
    .required("Este campo es requerido"),
});
