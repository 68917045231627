import {createSlice} from "@reduxjs/toolkit";
import {initialState} from "./initial-state";
import {getLive, getPreviousLive} from "./thunks";
import {setRequestStatus} from "@redux/request-status";

export const liveSlice = createSlice({
  name: "lives",
  initialState,
  reducers: {},
  extraReducers: function (builder) {
    builder.addCase(getLive.fulfilled, function (state, {payload}) {
      const {data} = payload;
      const live = data[0];
      state["live"] = live;
      state["request"]["live"] = setRequestStatus("success");
    });
    builder.addCase(getLive.rejected, function (state) {
      state["request"]["live"] = setRequestStatus("error");
    });

    builder.addCase(getPreviousLive.pending, function (state) {
      state["request"]["previousLive"] = setRequestStatus("initial");
    });
    builder.addCase(getPreviousLive.fulfilled, function (state, {payload}) {
      const {data} = payload;
      const previousLive = data[0];
      state["previousLive"] = previousLive;
      state["request"]["previousLive"] = setRequestStatus("success");
    });
    builder.addCase(getPreviousLive.rejected, function (state) {
      state["request"]["previousLive"] = setRequestStatus("error");
    });
  },
});

export const {reducer: lives} = liveSlice;
