import styled from "styled-components";

export const Image = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 10px;
`;

export const Upload = styled.div`
  width: 95px;
  height: 110px;
  padding: 8px;
  display: flex;
  border-width: 1px;
  align-items: center;
  position: relative;
  border-style: dashed;
  border-radius: 15px;
  border-color: #cbd2d9;
  justify-content: center;
`;

export const Close = styled.div`
  top: -5px;
  z-index: 2;
  right: -5px;
  width: 30px;
  height: 30px;
  display: flex;
  position: absolute;
  align-items: center;
  border-radius: 100px;
  justify-content: center;
  box-shadow: 0px 2px 3px 1px rgba(0, 0, 0, 0.3);
  background-color: ${({theme}) => theme["white"]};
`;
