import {SvgProps} from "interfaces";

export const Ticket = ({color = "white"}: SvgProps) => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
    <path
      fill={color}
      d="M20 12C20 10.9 20.9 10 22 10V6C22 4.9 21.1 4 20 4H4C2.9 4 2.01 4.9 2.01 6V10C3.11 10 4 10.9 4 12C4 13.1 3.11 14 2 14V18C2 19.1 2.9 20 4 20H20C21.1 20 22 19.1 22 18V14C20.9 14 20 13.1 20 12ZM15.58 16.8L12 14.5L8.42 16.8L9.5 12.68L6.21 9.99L10.45 9.74L12 5.8L13.54 9.75L17.78 10L14.49 12.69L15.58 16.8Z"
    />
  </svg>
);
