import {Fragment} from "react";
import {LotteryUIVariant} from "interfaces";
import {
  OnLive,
  Initial,
  Subscribed,
  WaitingForLive,
  NoActiveLottery,
} from "./variants";

export function loadUIVariant(status: LotteryUIVariant): JSX.Element {
  switch (status) {
    case "initial":
      return <Initial />;
    case "no-active-lottery":
      return <NoActiveLottery />;
    case "on-live":
      return <OnLive />;
    case "subscribed":
      return <Subscribed />;
    case "waiting-for-live":
      return <WaitingForLive />;
    default:
      return <Fragment />;
  }
}
