import {useEffect} from "react";
import {useAppDispatch, useAppSelector} from "hooks";
import {Input, Column, Row, Select} from "components";
import {onChangeUserPayloadReport} from "@redux/slices";
import {buildSelectOptions, dniPrefix, totalVES} from "utils";

interface MobilePaymentProps {}

export const MobilePayment: React.FC<MobilePaymentProps> = () => {
  const dispatch = useAppDispatch();
  const {banks} = useAppSelector("payment");
  const {participatedNumbers, userPayloadReport} = useAppSelector("payment");
  const {dni: defaultDNI, phone: defaultPhone} = useAppSelector("auth")["user"];
  const {dni, phone} = userPayloadReport;

  useEffect(() => {
    dispatch(onChangeUserPayloadReport({key: "dni", value: defaultDNI}));
    dispatch(
      onChangeUserPayloadReport({
        key: "phone",
        value: defaultPhone.split("+")[1],
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Column width="100%" gap={0}>
      <Input
        disabled
        label="Monto (Bs)"
        value={totalVES(participatedNumbers)}
      />
      <Column width="100%" gap={0}>
        <Select
          label="Banco emisor"
          options={buildSelectOptions(banks, "Selecciona el banco", {
            keyLabel: "name",
            keyValue: "code",
          })}
          onChange={({target}) => {
            dispatch(
              onChangeUserPayloadReport({
                key: "codeBank",
                value: target["value"],
              })
            );
          }}
        />
        <Input
          inputMode="numeric"
          placeholder="0000000000"
          label="Nro de referencia"
          containerStyle={{width: "100%"}}
          onChange={({target}) =>
            dispatch(
              onChangeUserPayloadReport({key: "code", value: target["value"]})
            )
          }
        />
        <Input
          value={phone}
          inputMode="numeric"
          placeholder="584121112233"
          containerStyle={{width: "100%"}}
          label="Teléfono asociado al banco emisor"
          onChange={({target}) =>
            dispatch(
              onChangeUserPayloadReport({key: "phone", value: target["value"]})
            )
          }
        />
        <Row width="100%">
          <Select
            label="Tipo"
            options={dniPrefix}
            containerStyle={{width: "20%"}}
            onChange={({target}) => {
              dispatch(
                onChangeUserPayloadReport({
                  key: "dniPrefix",
                  value: target["value"],
                })
              );
            }}
          />
          <Input
            value={dni}
            label="Cédula"
            inputMode="numeric"
            placeholder="90111222"
            containerStyle={{width: "78.5%"}}
            onChange={({target}) =>
              dispatch(
                onChangeUserPayloadReport({key: "dni", value: target["value"]})
              )
            }
          />
        </Row>
      </Column>
    </Column>
  );
};
