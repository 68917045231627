import {useCallback} from "react";
import {VideoModal} from "components";
import {useModal, useAppSelector} from "hooks";

export const usePromotionalVideo = () => {
  const {openModal} = useModal();
  const {lottery} = useAppSelector("lottery");
  const {media} = lottery;

  const showPromotionalVideo = useCallback(() => {
    openModal(
      <VideoModal
        source={media[0]}
        message="Cargando promoción del sorteo..."
      />,
      {
        backgroundColor: "rgba(0,0,0, .8)",
        closeButton: {show: true, defaultIconColor: "#fff"},
      }
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [media]);

  return {showPromotionalVideo};
};
