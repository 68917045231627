export const WhatsApp = ({size = 38}: {size: number}) => (
  <svg width={size} height={size} viewBox="0 0 23 23" fill="none">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M18.89 3.88134C10.1223 -4.59389 -3.63223 5.89063 2.32682 16.5178L0.833752 21.9443L6.41252 20.4882C17.0858 26.1057 27.3037 12.4919 18.89 3.88134Z"
      fill="#F1F1F1"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.4463 20.1629C9.71419 20.169 8.03041 19.6513 6.57817 18.7334L3.20285 19.6143L4.10394 16.3394C3.09152 14.8458 2.52053 13.094 2.52828 11.281C2.51232 3.43522 12.2064 -0.564966 17.7546 5.00987C23.3473 10.5372 19.3301 20.1802 11.4463 20.1629Z"
      fill="#66E066"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16.3374 13.5162C16.0693 13.3827 14.7512 12.737 14.5055 12.648C14.2599 12.559 14.081 12.5145 13.9024 12.7818C13.7235 13.0488 13.2103 13.6497 13.0538 13.8275C12.8975 14.0058 12.741 14.028 12.473 13.8945C11.8938 13.9715 8.92244 11.4701 8.82571 10.7221C8.66945 10.4552 8.80906 10.3106 8.94343 10.1775C9.1768 9.92248 9.47222 9.60183 9.61343 9.26505C9.70285 9.08679 9.65814 8.93101 9.59107 8.79748C9.524 8.66395 9.00297 7.3441 8.76458 6.81657C8.56383 6.37216 8.35236 6.3633 8.16142 6.35535C7.70905 6.36239 7.35888 6.24544 6.93274 6.68304C5.33496 8.21386 6.0677 10.3651 7.08901 11.6688C7.22292 11.8468 8.94365 14.6273 11.6686 15.6969C13.9332 16.586 14.394 16.4091 14.8856 16.3646C15.3772 16.3201 16.4717 15.7192 16.6951 15.096C16.9184 14.4729 16.9184 13.9388 16.8516 13.8273C16.7843 13.7165 16.6054 13.6497 16.3374 13.5162Z"
      fill="#F1F1F1"
    />
  </svg>
);
