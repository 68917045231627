import moment from "moment";
import {colors} from "styles";
import {Testimonial} from "./lib";
import {ThumbsUp} from "react-feather";
import {Fragment, useEffect} from "react";
import {useNavigate} from "react-router-dom";
import {SkeletonScreen} from "./SkeletonScreen";
import {useAppDispatch, useAppSelector} from "hooks";
import {get} from "@redux/slices/testimonials/thunks";
import {
  Row,
  Avatar,
  Column,
  Screen,
  Typography,
  ComponentLoader,
  CommonOutlinedErrorBody,
} from "components";

export const Testimonials = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const {request, testimonials} = useAppSelector("testimonials");

  useEffect(() => {
    dispatch(get());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Screen
      header={{onBack: () => navigate(-1), title: "Testimonios de ganadores"}}
    >
      <ComponentLoader
        skeleton={<SkeletonScreen />}
        requestStatus={request["testimonials"]}
        errorUI={{
          body: <CommonOutlinedErrorBody callback={() => dispatch(get())} />,
        }}
        emptyUI={{
          data: testimonials,
          icon: <ThumbsUp width={40} height={40} color={colors["black"]} />,
          message:
            "No hay testimonios que mostrar.\nVuelve pronto y conoce los próximos ganadores",
        }}
      >
        {testimonials.map(({awards, lottery}, key) => (
          <Fragment key={key}>
            <Column padding="0 1rem" gap={0}>
              <Row gap={0}>
                {awards.map(({number}, key) => (
                  <Avatar
                    key={key}
                    size={30}
                    borderWidth={2}
                    borderColor={colors["white"]}
                    src={number["userFound"]["picture"]}
                    transform={`translateX(${-(key * 15)})`}
                  />
                ))}
              </Row>
              <Column gap={0}>
                <Typography fontFamily="Poppins-Medium">
                  Ganadores del concurso #{lottery["number"]}
                </Typography>
                <Typography>
                  inicio{" "}
                  <Typography fontFamily="Poppins-SemiBold">
                    {moment(lottery["start_date"]).format("DD MMMM")}
                  </Typography>{" "}
                  - Cierre{" "}
                  <Typography fontFamily="Poppins-SemiBold">
                    {moment(lottery["end_date"]).format("DD MMMM")}
                  </Typography>
                </Typography>
              </Column>
            </Column>
            <Column gap={5} backgroundColor="#F2F2F2">
              {awards.map(({number, position, _id}, key) => (
                <Testimonial
                  key={key}
                  awardId={_id}
                  position={position}
                  lotteryId={lottery["_id"]}
                  user={number["userFound"]}
                  testimonial={number["testimonial"]}
                />
              ))}
            </Column>
          </Fragment>
        ))}
      </ComponentLoader>
    </Screen>
  );
};
