import {Formik} from "formik";
import {colors} from "styles";
import {useAppDispatch} from "hooks";
import {useNavigate, useParams} from "react-router-dom";
import {Input, Column, Screen, Button} from "components";
import {validateRecoverCode} from "@redux/slices/auth/thunks";
import {initialValues, validateRecoverCodeSchema} from "./form.schema";

export const ValidateRecoverCode = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const {phone} = useParams() as {phone: string};

  return (
    <Screen
      bodyStyle={{padding: "1rem"}}
      header={{
        onBack: () => navigate(-1),
        title: "Validación de código",
        guide: "Introduce el código que recibirás por SMS",
      }}
    >
      <Formik
        validateOnChange={true}
        initialValues={{...initialValues, phone}}
        validationSchema={validateRecoverCodeSchema}
        onSubmit={(payload) => {
          dispatch(validateRecoverCode(payload));
        }}
      >
        {({
          dirty,
          values,
          errors,
          isValid,
          touched,
          handleBlur,
          handleSubmit,
          handleChange,
        }) => {
          const disabled = !(dirty && isValid);
          return (
            <Column>
              <Input
                autoFocus
                maxLength={7}
                inputMode="numeric"
                placeholder="000111"
                value={values["code"]}
                touched={touched["code"]}
                onBlur={handleBlur("code")}
                label="Código de validación"
                style={{textAlign: "center"}}
                onChange={handleChange("code")}
                helperErrorText={errors["code"]}
              />
              <Button
                disabled={disabled}
                label="Validar código"
                style={{width: "100%"}}
                onClick={() => handleSubmit()}
                loaderId="validate-recover-code"
                backgroundColor={colors["primary"]}
              />
            </Column>
          );
        }}
      </Formik>
    </Screen>
  );
};
