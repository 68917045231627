import {WhatsApp} from "svg";
import {colors} from "styles";
import {useAppDispatch} from "hooks";
import {useTheme} from "styled-components";
import {MessageCircle} from "react-feather";
import {useNavigate} from "react-router-dom";
import {setVerificationSMSProvider} from "@redux/slices";
import {
  Line,
  Column,
  Screen,
  Button,
  Image,
  Stepper,
  Typography,
} from "components";

export const SelectSMSProvider = () => {
  const {primary} = useTheme();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  return (
    <Screen bodyStyle={{padding: 20}}>
      <Stepper steps={4} current={2} />
      <Column gap={0} marginTop={10}>
        <Image
          width={125}
          height={130}
          objectFit="contain"
          src="/assets/sms-verification.png"
        />
        <Column gap={5}>
          <Typography fontFamily="Poppins-SemiBold" fontSize={26}>
            Verificación de número
          </Typography>
          <Typography fontSize={16} fontFamily="Poppins-Medium">
            Así sabremos que eres tú y nadie más. Además de permitirnos tener
            contacto contigo.
          </Typography>
        </Column>
      </Column>
      <Line margin="1rem 0" />
      <Column>
        <Button
          style={{width: "100%"}}
          label="Verificar con WhatsApp"
          leftIcon={<WhatsApp size={25} />}
          backgroundColor={colors["primary"]}
          onClick={() => {
            dispatch(setVerificationSMSProvider("whatsapp"));
            navigate("/verification/verify-sms");
          }}
        />
        <Button
          variant="outlined"
          borderColor={primary}
          label="Verificar con SMS"
          style={{borderColor: primary, width: "100%"}}
          typographyProps={{color: primary}}
          leftIcon={<MessageCircle color={primary} />}
          onClick={() => {
            dispatch(setVerificationSMSProvider("sms"));
            navigate("/verification/verify-sms");
          }}
        />
      </Column>
    </Screen>
  );
};
