import {colors} from "styles";
import {Column, Row, Typography} from "components";

interface StepperProps {
  steps: number;
  current: number;
}

export const Stepper: React.FC<StepperProps> = ({steps, current}) => {
  const UI = Array.from(Array(steps).keys());
  return (
    <Column>
      <Typography fontFamily="Poppins-SemiBold" fontSize={18}>
        Paso {current}/{steps}
      </Typography>
      <Row justifyContent="space-between" width="100%">
        {UI.map((key) => (
          <div
            key={key}
            style={{
              height: 6,
              borderRadius: 10,
              backgroundColor:
                current >= key + 1 ? colors["primary"] : "rgba(0, 0, 0, .1)",
              width: `${100 / steps - (steps > 4 ? 1.5 : 1.05)}%`,
            }}
          />
        ))}
      </Row>
    </Column>
  );
};
