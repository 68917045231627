import * as Yup from "yup";
import {VerifyExtraDataPayload} from "interfaces";

export const initialValues: VerifyExtraDataPayload = {
  dni: "",
  email: "",
  cityId: "",
  stateId: "",
  countryId: "",
};

export const verifyExtraDataSchema = Yup.object().shape({
  dni: Yup.string().required("Tu DNI es requerido"),
  cityId: Yup.string().required("Este campo es requerido"),
  stateId: Yup.string().required("Este campo es requerido"),
  countryId: Yup.string().required("Este campo es requerido"),
  email: Yup.string()
    .email("Debe ser un correo válido")
    .matches(/@[^.]*\./, "Debe ser un correo válido")
    .required("El correo es requerido"),
});
