import styled from "styled-components";

export const Container = styled.div`
  width: 50px;
  height: 50px;
  display: flex;
  border-radius: 5px;
  position: relative;
  align-items: center;
  justify-content: center;
  background-color: ${({theme}) => theme["white"]};
`;

export const Absolute = styled.div`
  top: -8px;
  right: -8px;
  z-index: 2;
  width: 22px;
  height: 22px;
  display: flex;
  position: absolute;
  align-items: center;
  border-radius: 100px;
  justify-content: center;
  background-color: ${({theme}) => theme["secondary"]};
`;
