import {initialState} from "./initial-state";
import {setRequestStatus} from "@redux/request-status";
import {createSlice, current, PayloadAction} from "@reduxjs/toolkit";
import {getBanks, getMethods, reportPayment, subscribe} from "./thunks";
import {LotteryNumber, PaymentMethod, UserPayloadReport} from "interfaces";

export const paymentSlice = createSlice({
  name: "payment",
  initialState,
  reducers: {
    pickTicket: function (state, action: PayloadAction<LotteryNumber>) {
      let {ticketsSelected} = state;
      const {payload} = action;
      let nextTickets: number[] = [];
      const found = ticketsSelected.find(
        (ticket) => ticket === payload["number"]
      );

      if (found) {
        nextTickets = ticketsSelected.filter(
          (ticket) => ticket !== payload["number"]
        );
      } else {
        nextTickets = [...current(ticketsSelected), payload["number"]];
      }
      state["ticketsSelected"] = nextTickets;
    },
    setPaymentMethod: function (
      state,
      action: PayloadAction<{methodId: string; method: PaymentMethod}>
    ) {
      state["methodId"] = action["payload"]["methodId"];
      state["paymentMethod"] = action["payload"]["method"];
    },
    clearPurchase: function (state) {
      state["ticketsSelected"] = [];
      state["methodId"] = undefined;
      state["participatedNumbers"] = [];
      state["showSupportWarning"] = false;
      state["userPayloadReport"] = initialState["userPayloadReport"];
    },
    onChangeUserPayloadReport: function (
      state,
      action: PayloadAction<{
        key: keyof UserPayloadReport;
        value: any;
      }>
    ) {
      const {key, value} = action["payload"];
      state["userPayloadReport"][key] = value;
    },
  },
  extraReducers: function (builder) {
    builder.addCase(getMethods.fulfilled, function (state, {payload}) {
      state["methods"] = payload;
      state["request"]["methods"] = setRequestStatus("success");
    });
    builder.addCase(getMethods.rejected, function (state) {
      state["request"]["methods"] = setRequestStatus("error");
    });

    builder.addCase(getBanks.fulfilled, function (state, {payload}) {
      state["banks"] = payload;
      state["request"]["banks"] = setRequestStatus("success");
    });

    builder.addCase(subscribe.fulfilled, function (state, {payload}) {
      state["participatedNumbers"] = payload;
    });

    builder.addCase(reportPayment.rejected, function (state) {
      state["showSupportWarning"] = true;
    });
  },
});

export const {reducer: payment} = paymentSlice;
export const {
  pickTicket,
  clearPurchase,
  setPaymentMethod,
  onChangeUserPayloadReport,
} = paymentSlice["actions"];
