import styled from "styled-components";

export const Container = styled.div`
  width: 140px;
  margin: auto;
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;
`;
