import moment from "moment";
import {colors} from "styles";
import {formatDate} from "utils";
import {Ticket, Trophy} from "svg";
import {useTheme} from "styled-components";
import {useNavigate} from "react-router-dom";
import {About, MyBoard} from "pages/Home/lib";
import {setLotteryUIVariant} from "@redux/slices";
import {usePromotionalVideo} from "pages/Home/hooks";
import {ArrowRight, Film, Clock} from "react-feather";
import {useAppDispatch, useAppSelector} from "hooks";
import {Row, Chip, Button, Column, Countdown, Typography} from "components";

interface SubscribedProps {}

export const Subscribed: React.FC<SubscribedProps> = () => {
  const navigate = useNavigate();
  const {black, white} = useTheme();
  const dispatch = useAppDispatch();
  const {user} = useAppSelector("auth");
  const {showPromotionalVideo} = usePromotionalVideo();
  const {lottery} = useAppSelector("lottery");
  const {end_date, numbers, totalTicketsSold, _id} = lottery;
  const lotteryCompleted = numbers === totalTicketsSold;

  return (
    <Column gap={0}>
      <Column gap={15} width="100%">
        <MyBoard />
        <Column gap={0} width="100%">
          <Column gap={0} alignItems="center" width="100%">
            <Chip backgroundColor={"transparent"}>
              <Row gap={3}>
                <Clock width={16} height={16} color={colors["white"]} />
                <Typography color={colors["white"]} fontSize={13}>
                  Tiempo restante para anunciar ganadores
                </Typography>
              </Row>
            </Chip>
            <Countdown
              style={{gap: 5}}
              icon={<Trophy width={25} height={25} color={colors["white"]} />}
              onEnded={() => dispatch(setLotteryUIVariant("waiting-for-live"))}
              timestamp={moment(moment(formatDate(end_date))).diff(
                moment(),
                "seconds"
              )}
              typographyStyle={{
                fontFamily: "Poppins-SemiBold",
                fontSize: 15.5,
              }}
            />
          </Column>
        </Column>
      </Column>
      <Column marginTop={20} gap={5} width="100%">
        <Button
          style={{width: "100%"}}
          onClick={() => navigate(`/lottery/${_id}`)}
          rightIcon={<ArrowRight color={colors["white"]} />}
          label={`Ver detalles del sorteo #${lottery["number"]}`}
        />

        {!lotteryCompleted && (
          <Button
            leftIcon={<Ticket />}
            label="Comprar más Tickets"
            backgroundColor="#e4c072"
            style={{width: "100%"}}
            onClick={() => {
              navigate(
                user["verified"]
                  ? `/lottery/${_id}/tickets`
                  : "InitVerification"
              );
            }}
          />
        )}
        <Button
          backgroundColor={white}
          style={{width: "100%"}}
          label="Video promocional"
          onClick={showPromotionalVideo}
          typographyProps={{color: black}}
          leftIcon={<Film width={20} height={20} color={colors["black"]} />}
        />
      </Column>
      <About />
    </Column>
  );
};
