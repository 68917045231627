import {Tiktok} from "svg";
import {Href} from "./style";
import {Row} from "components";
import {Instagram} from "react-feather";

interface RRSSProps {}

export const RRSS: React.FC<RRSSProps> = () => {
  return (
    <Row justifyContent="center">
      <Href
        target="_blank"
        href="https://www.instagram.com/centrodeapuestaoficial"
      >
        <Instagram color="rgba(0, 0, 0, .5)" width={25} height={25} />
      </Href>
      <Href target="_blank" href="https://www.tiktok.com/@centrodeapuesta">
        <Tiktok color="rgba(0, 0, 0, .5)" width={25} height={25} />
      </Href>
    </Row>
  );
};
