import * as Yup from "yup";
import {LoginPayload} from "interfaces";

export const initialValues: LoginPayload = {
  phone: "",
  email: "",
  password: "",
};

export const loginSchema = Yup.object().shape({
  phone: Yup.string().required("Tu teléfono es requerido"),
  password: Yup.string().required("La contraseña es requerida"),
});
