import {Input, Column} from "components";
import {useAppDispatch, useAppSelector} from "hooks";
import {onChangeUserPayloadReport} from "@redux/slices";

interface ElectronicProps {}

export const Electronic: React.FC<ElectronicProps> = () => {
  const dispatch = useAppDispatch();
  const {lottery} = useAppSelector("lottery");
  const {ticketsSelected, paymentMethod} = useAppSelector("payment");
  const USDTotal = ticketsSelected["length"] * lottery["price"];
  const isBinance = paymentMethod === "binance";

  return (
    <Column width="100%">
      <Input
        disabled
        label="Monto (USD)"
        value={`$${USDTotal.toLocaleString("US")}`}
      />
      <Column>
        <Input
          containerStyle={{width: "100%"}}
          inputMode={isBinance ? "numeric" : undefined}
          placeholder={isBinance ? "302191364454066427" : "JGM97ake3oll"}
          label={
            isBinance
              ? "ID de la orden (Binance)"
              : "Código de referencia (Zelle)"
          }
          onChange={({target}) =>
            dispatch(
              onChangeUserPayloadReport({key: "code", value: target["value"]})
            )
          }
        />
      </Column>
    </Column>
  );
};
