import {Ticket} from "svg";
import {colors} from "styles";
import {useEffect} from "react";
import {ArrowRight} from "react-feather";
import {Button, Column} from "components";
import {useNavigate} from "react-router-dom";
import {About, Awards} from "pages/Home/lib";
import {useAppDispatch, useAppSelector} from "hooks";
import {usePromotionalVideo} from "pages/Home/hooks";
import {togglePromotionalVideoDisplayed} from "@redux/slices";

interface InitialProps {}

export const Initial: React.FC<InitialProps> = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const {lottery} = useAppSelector("lottery");
  const {user, isLogged} = useAppSelector("auth");
  const {showPromotionalVideo} = usePromotionalVideo();
  const {promotionalVideoDisplayed} = useAppSelector("layout");

  useEffect(() => {
    if (lottery["_id"] && !promotionalVideoDisplayed) {
      showPromotionalVideo();
      dispatch(togglePromotionalVideoDisplayed(true));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lottery, promotionalVideoDisplayed]);

  return (
    <Column gap={0}>
      <Awards />
      <Column gap={5} width="100%">
        <Button
          style={{width: "100%"}}
          onClick={() => navigate(`/lottery/${lottery["_id"]}`)}
          rightIcon={<ArrowRight color={colors["white"]} />}
          label={`Ver detalles del sorteo #${lottery["number"]}`}
        />
        {isLogged && (
          <Button
            leftIcon={<Ticket />}
            label="Comprar Tickets"
            backgroundColor="#e4c072"
            style={{width: "100%"}}
            onClick={() => {
              navigate(
                user["verified"]
                  ? `/lottery/${lottery["_id"]}/tickets`
                  : "/verification/init"
              );
            }}
          />
        )}
      </Column>
      <About />
    </Column>
  );
};
