import * as Yup from "yup";
import {ValidateAccountVerificationCodePayload} from "interfaces";

export const initialValues: ValidateAccountVerificationCodePayload = {
  code: "",
};

export const schema = Yup.object().shape({
  code: Yup.string().required("El código es requerido"),
});
