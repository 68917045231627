import {colors} from "styles";
import {Container} from "./style";
import {Edit2} from "react-feather";
import {UpdateAvatarPreview} from "./lib";
import {Avatar, Absolute} from "components";
import {useModal, useAppSelector} from "hooks";

interface EditableAvatarProps {}

export const EditableAvatar: React.FC<EditableAvatarProps> = () => {
  const {openModal} = useModal();
  const {picture} = useAppSelector("auth")["user"];
  return (
    <Container onClick={() => openModal(<UpdateAvatarPreview />)}>
      <Avatar size={100} src={picture} />
      <Absolute
        right={5}
        bottom={5}
        width={35}
        height={35}
        borderWidth={2}
        borderRadius={100}
        display="flex"
        alignItems="center"
        justifyContent="center"
        borderColor={colors["white"]}
        backgroundColor={colors["primary"]}
      >
        <Edit2 width={18} height={18} color={colors["white"]} />
      </Absolute>
    </Container>
  );
};
