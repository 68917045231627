import styled from "styled-components";

export const Container = styled.div`
  top: 0;
  left: 0;
  z-index: 2;
  width: 100%;
  position: absolute;
  background: rgb(0, 0, 0);
  background: linear-gradient(
    0deg,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0.8911939775910365) 42%
  );
`;
