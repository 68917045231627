import {colors} from "styles";
import {Skeleton} from "./Skeleton";
import {Repeat} from "react-feather";
import {BottomTab, Header} from "./lib";
import {useHomeUIVariant} from "./hooks";
import {useTheme} from "styled-components";
import {LotteryUIVariant} from "interfaces";
import {loadUIVariant} from "./load-ui-variant";
import {get} from "@redux/slices/lottery/thunks";
import {useAppDispatch, useAppSelector} from "hooks";
import {Button, ComponentLoader, Screen} from "components";

const height100VH: LotteryUIVariant[] = [
  "on-live",
  "loading",
  "waiting-for-live",
];

interface HomeProps {}

export const Home: React.FC<HomeProps> = () => {
  useHomeUIVariant();
  const dispatch = useAppDispatch();
  const {error, primary} = useTheme();
  const {request, UIVariant} = useAppSelector("lottery");
  const onLiveBackgroundColor = UIVariant === "on-live" ? error : primary;

  return (
    <Screen
      bodyStyle={{backgroundColor: onLiveBackgroundColor}}
      style={{
        padding: "1rem",
        paddingTop: "1.5rem",
        paddingBottom: "5rem",
        backgroundColor: onLiveBackgroundColor,
        height:
          height100VH.includes(UIVariant) || request["lottery"]["error"]
            ? "100vh"
            : "auto",
      }}
    >
      <Header />
      <ComponentLoader
        skeleton={<Skeleton />}
        requestStatus={request["lottery"]}
        errorUI={{
          defaultMessageColor: colors["white"],
          body: (
            <Button
              label="Intentar otra vez"
              onClick={() => dispatch(get())}
              style={{width: "auto", marginTop: 10}}
              leftIcon={
                <Repeat width={18} height={18} color={colors["white"]} />
              }
            />
          ),
        }}
      >
        {loadUIVariant(UIVariant)}
      </ComponentLoader>
      <BottomTab />
    </Screen>
  );
};
