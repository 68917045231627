import {colors} from "styles";
import {Fragment, useEffect} from "react";
import {Award, Youtube} from "react-feather";
import {useNavigate} from "react-router-dom";
import {Award as EmptyIcon} from "react-feather";
import {get} from "@redux/slices/ranking/thunks";
import {Participant, Winner, WinnerAward} from "./lib";
import {getPreviousLive} from "@redux/slices/lives/thunks";
import {useAppDispatch, useAppSelector, useModal} from "hooks";
import {
  Row,
  Column,
  Screen,
  Warning,
  Skeleton,
  Typography,
  EmptyUIState,
  ComponentLoader,
  CommonOutlinedErrorBody,
} from "components";

export const Ranking = () => {
  const {openModal} = useModal();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const {ranking, request} = useAppSelector("ranking");
  const {previousLive, request: livesRequest} = useAppSelector("lives");

  useEffect(() => {
    dispatch(get());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    if (ranking && ranking["_id"]) dispatch(getPreviousLive(ranking["_id"]));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ranking]);

  return (
    <Screen header={{title: "Tabla de posiciones", onBack: () => navigate(-1)}}>
      {ranking ? (
        <ComponentLoader
          requestStatus={request["ranking"]}
          errorUI={{
            body: <CommonOutlinedErrorBody callback={() => dispatch(get())} />,
          }}
          skeleton={
            <Column>
              <Row justifyContent="center" gap={10} width="100%">
                <Skeleton
                  width={90}
                  height={90}
                  borderRadius={100}
                  highlightMode="dark"
                />
                <Skeleton
                  width={120}
                  height={120}
                  borderRadius={100}
                  highlightMode="dark"
                />
                <Skeleton
                  width={90}
                  height={90}
                  borderRadius={100}
                  highlightMode="dark"
                />
              </Row>
              <Skeleton width="100%" height={120} highlightMode="dark" />
              <Column>
                <Skeleton width="50%" height={30} highlightMode="dark" />
                <Column gap={1}>
                  <Skeleton
                    count={20}
                    width="100%"
                    height={50}
                    highlightMode="dark"
                  />
                </Column>
              </Column>
            </Column>
          }
        >
          <Row
            gap={10}
            marginTop={20}
            marginBottom={-20}
            justifyContent="space-evenly"
          >
            {ranking["awards"].map(({number, position}, key, arr) => {
              return number ? (
                <Winner
                  size={90}
                  key={key}
                  rank={Number(position)}
                  ticket={number["number"]}
                  user={number["userFound"]}
                  onClick={() => openModal(<WinnerAward award={arr[key]} />)}
                />
              ) : (
                <Fragment key={key} />
              );
            })}
          </Row>
          <ComponentLoader
            requestStatus={livesRequest["previousLive"]}
            skeleton={
              <Skeleton
                width="90%"
                height={165}
                margin="1rem auto"
                borderRadius={15}
                highlightMode="dark"
              />
            }
          >
            <Warning
              title="Aviso"
              style={{width: "90%", margin: "1rem auto"}}
              message="Estos resultados fueron transmitidos en vivo para garantizar su veracidad"
              button={{
                style: {width: "90%"},
                label: "Ver transmisión pasada",
                backgroundColor: colors["primary"],
                leftIcon: <Youtube color={colors["white"]} />,
                onClick: () => {
                  if (previousLive) window.open(previousLive["url"], "_blank");
                },
              }}
            />
          </ComponentLoader>
          <EmptyUIState
            data={ranking["users"]}
            icon={<Award color={colors["black"]} width={40} height={40} />}
            message="No hubo jugadores en este sorteo"
          >
            <Column
              gap={15}
              width="100%"
              marginBottom={20}
              padding=".5rem 1rem"
            >
              <Typography fontFamily="Poppins-Medium" fontSize={16}>
                Jugadores que participaron
              </Typography>
              <Column gap={15} width="100%">
                {ranking["users"].map((user, key) => (
                  <Participant rank={key + 1} participant={user} key={key} />
                ))}
              </Column>
            </Column>
          </EmptyUIState>
        </ComponentLoader>
      ) : (
        <EmptyUIState
          data={[]}
          style={{marginTop: -15}}
          children={<Fragment />}
          message={`Actualmente no hay tabla de posiciones. Esta se creará al finalizar el primer sorteo.\n¡Participa!`}
          icon={<EmptyIcon width={40} height={40} color={colors["black"]} />}
        />
      )}
    </Screen>
  );
};
