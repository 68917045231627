import {colors} from "styles";
import {Container, Radio, Selected} from "./style";
import {Column, Row, Image, Typography} from "components";

export type MethodProps = {
  title: string;
  value: string;
  image: string;
  selected: boolean;
  description: string;
  onPress: (value: string) => void;
};

export const Method: React.FC<MethodProps> = (props) => {
  const {onPress, value, title, description, image, selected} = props;
  return (
    <Row gap={10} onClick={() => onPress(value)} width="100%">
      <Radio
        children={
          <Selected
            style={{
              backgroundColor: selected ? colors["secondary"] : "transparent",
            }}
          />
        }
      />
      <Container
        style={{
          border: `1px solid ${
            selected ? colors["secondary"] : "rgba(0,0,0, .1)"
          }`,
        }}
      >
        <Row gap={10}>
          <Image src={image} size={45} />
          <Column gap={0} width="80%">
            <Typography fontFamily="Poppins-SemiBold">{title}</Typography>
            <Typography fontSize={12}>{description}</Typography>
          </Column>
        </Row>
      </Container>
    </Row>
  );
};
