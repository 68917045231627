import {Ticket} from "./lib";
import {colors} from "styles";
import {useAppSelector} from "hooks";
import {Check, Clock} from "react-feather";
import {Column, Line, Row, Typography} from "components";

interface MyBoardProps {}

export const MyBoard: React.FC<MyBoardProps> = () => {
  const {myNumbers} = useAppSelector("lottery");
  const empty = myNumbers["length"] === 0;
  const waiting = myNumbers.filter(
    (number) => number["payment"] && number["payment"]["paymentStatus"] === 0
  );
  const reserved = myNumbers.filter(
    (number) => number["payment"] && number["payment"]["paymentStatus"] === 1
  );

  return empty ? (
    <Column>
      <Typography
        fontSize={16}
        color={colors["white"]}
        style={{textAlign: "center"}}
        fontFamily="Poppins-SemiBold"
      >
        😢 No compraste tickets para este sorteo
      </Typography>
      <Typography
        fontSize={13}
        color={colors["white"]}
        style={{textAlign: "center"}}
      >
        No te preocupes. Luego de dar a conocer los ganadores; vendrán más
        oportunidades de ganar premios y cumplir tu sueño 💪🏻
      </Typography>
    </Column>
  ) : (
    <Column width="100%">
      {reserved["length"] > 0 && (
        <Column gap={10}>
          <Row justifyContent="center">
            <Check
              width={18}
              height={18}
              strokeWidth={2.5}
              color={colors["white"]}
            />
            <Typography
              fontSize={16}
              color={colors["white"]}
              style={{textAlign: "center"}}
              fontFamily="Poppins-SemiBold"
            >
              Tickets comprados
            </Typography>
          </Row>
          <Row>
            {reserved.map((ticket, key) => (
              <Ticket key={key} ticket={ticket} />
            ))}
          </Row>
        </Column>
      )}
      {!!waiting["length"] && !!reserved["length"] && (
        <Line margin=".3rem 0" backgroundColor="rgba(255, 255, 255, .5)" />
      )}
      {waiting["length"] > 0 && (
        <Column gap={10}>
          <Row justifyContent="center">
            <Clock
              width={18}
              height={18}
              strokeWidth={2.5}
              color={colors["white"]}
            />
            <Typography
              fontSize={16}
              color={colors["white"]}
              style={{textAlign: "center"}}
              fontFamily="Poppins-SemiBold"
            >
              Tickets en espera
            </Typography>
          </Row>
          <Row>
            {waiting.map((ticket, key) => (
              <Ticket key={key} ticket={ticket} Icon={Clock} />
            ))}
          </Row>
        </Column>
      )}
    </Column>
  );
};
