import {Column, Row, Skeleton as UISkeleton} from "components";

export const Skeleton = () => (
  <Column>
    <UISkeleton width="100%" height={310} />
    <UISkeleton width="100%" height={50} borderRadius={100} />
    <Row marginTop={5} justifyContent="space-between">
      <UISkeleton width={120} height={40} />
      <UISkeleton width={80} height={40} borderRadius={100} />
    </Row>
    <UISkeleton width="100%" height={350} />
  </Column>
);
