import moment from "moment";
import {useAppSelector} from "hooks";
import {useTheme} from "styled-components";
import {dateDiff, formatDate} from "utils";
import {Calendar, Clock, Info} from "react-feather";
import {
  Row,
  Chip,
  Line,
  Column,
  InfoField,
  Typography,
  ProgressBar,
  Subscribers,
} from "components";

interface AboutProps {}

export const About: React.FC<AboutProps> = () => {
  const {black} = useTheme();
  const {numbers, title, description, totalTicketsSold, start_date, end_date} =
    useAppSelector("lottery")["lottery"];

  return (
    <Column marginBottom={25} width="100%">
      <Row marginTop={20} justifyContent="space-between" width="100%">
        <Row width="65%">
          <Info size={20} color="#fff" />
          <Typography color="#fff" fontSize={13} fontFamily="Poppins-Medium">
            {title}
          </Typography>
        </Row>
        <Chip>En curso</Chip>
      </Row>
      <Column
        width="100%"
        borderRadius={10}
        padding="20px 15px"
        backgroundColor="#fff"
      >
        <Column gap={0}>
          <Typography color="rgba(0, 0, 0, .5)" fontSize={12}>
            Más sobre el sorteo
          </Typography>
          <Typography fontFamily="Poppins-Medium">{description}</Typography>
        </Column>
        <Column width="100%">
          <Subscribers />
          <Typography fontFamily="Poppins-Light" fontSize={14}>
            Personas inscritas en el sorteo
          </Typography>
        </Column>
        <Line margin={0} />
        <Column width="100%">
          <ProgressBar value={totalTicketsSold} max={numbers} />
          <Typography fontFamily="Poppins-Light" fontSize={14}>
            Progreso del sorteo
          </Typography>
        </Column>
        <Line margin={0} />
        <Column width="100%">
          <InfoField
            guide="Fecha de inicio"
            title={moment(start_date).format("DD MMMM")}
            icon={<Calendar width={25} height={25} color={black} />}
          />
          <InfoField
            guide="Tiempo transcurrido"
            icon={<Clock width={25} height={25} color={black} />}
            title={dateDiff(formatDate(start_date) as unknown as Date)}
          />
          <InfoField
            title={moment(formatDate(end_date)).format("DD/MM/YYYY hh:mm A")}
            guide="Fecha de cierre | Anuncio del ganador"
            icon={<Calendar width={25} height={25} color={black} />}
          />
        </Column>
      </Column>
    </Column>
  );
};
