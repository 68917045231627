import {LotteryWinnersModel} from "interfaces";

export const lotteryWinnerInitialState: LotteryWinnersModel = {
  _id: "",
  won: false,
  title: "",
  number: 0,
  status: 0,
  numbers: 0,
  end_date: "",
  draw_date: "",
  start_date: "",
  description: "",
  awards: [],
};
