import {Bank} from "svg";
import moment from "moment";
import {colors} from "styles";
import {useReport} from "./useReport";
import {Fragment, useEffect} from "react";
import {useTheme} from "styled-components";
import {toggleDisableState} from "./helper";
import {useNavigate} from "react-router-dom";
import {PaymentForm, PaymentInfo} from "./lib";
import {useAppDispatch, useAppSelector} from "hooks";
import {getBanks} from "@redux/slices/payment/thunks";
import {clearPurchase, showAlert} from "@redux/slices";
import {Check, HelpCircle, Info} from "react-feather";
import {countdownLabelStyle, countdownStyle} from "./style";
import {PaymentMethod, PaymentMethodModel} from "interfaces";
import {MINUTES_TO_DECLINE_PAYMENT} from "../PaymentMethods/useNext";
import {paymentMethodVariants} from "constants/payment-method-variants";
import {
  Row,
  Chip,
  Line,
  Column,
  Screen,
  Warning,
  Countdown,
  Typography,
} from "components";

export const PaymentReport = () => {
  const {error} = useTheme();
  const {report} = useReport();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const {
    methods,
    methodId,
    paymentMethod,
    userPayloadReport,
    showSupportWarning,
  } = useAppSelector("payment");

  const method = methods.find(
    ({_id}) => methodId === _id
  ) as PaymentMethodModel;

  const disabled = toggleDisableState(
    paymentMethod as PaymentMethod,
    userPayloadReport
  );

  useEffect(() => {
    dispatch(getBanks());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Screen
      header={{
        title: "Reportar pago",
        onBack: () => navigate(-1),
        guide: "¡Último paso, ya casi!",
        rightButton: {
          disabled,
          onClick: report,
          label: "Reservar",
          loaderId: "report-payment",
          style: {padding: "0 15px"},
          rightIcon: (
            <Check
              width={22}
              height={22}
              color={disabled ? colors["textDisabled"] : colors["white"]}
            />
          ),
        },
      }}
    >
      <Column padding="0 20px">
        {showSupportWarning ? (
          <Column gap={0} marginBottom={5}>
            <Warning
              title="Hubo un error de Reporte"
              message={`Recomendamos que verifiques tus datos e intentes nuevamente. \nNOTA: Si crees que fue un error de nuestra parte, crea un ticket con nuestro Equipo de Soporte.`}
              button={{
                label: "Ir a crear ticket",
                style: {backgroundColor: error},
                leftIcon: <HelpCircle color={colors["white"]} />,
                onClick: () => {
                  navigate("Home");
                  dispatch(clearPurchase());
                  setTimeout(() => {
                    navigate("Support");
                  }, 700);
                },
              }}
            />
            <Line />
          </Column>
        ) : (
          <Fragment />
        )}
        <Column>
          <Row gap={5} marginTop={10} marginBottom={10} justifyContent="center">
            <Countdown
              style={countdownStyle}
              typographyStyle={countdownLabelStyle}
              timeLabel={{d: "", m: "m ", s: "s", h: ""}}
              onEnded={() => {
                navigate("Lottery");
                dispatch(clearPurchase());
                dispatch(
                  showAlert({
                    type: "error",
                    title: "Se ha cancelado la reservación",
                    message: "Por favor, vuelve a reservar tus tickets",
                  })
                );
              }}
              timestamp={moment(
                moment().add(MINUTES_TO_DECLINE_PAYMENT, "minutes")
              ).diff(moment(), "seconds")}
            />
            <Chip padding="0 10px" backgroundColor="#017CCC">
              <Row gap={3}>
                <Info width={18} height={18} color={colors["white"]} />
                <Typography color={colors["white"]} fontSize={12}>
                  Tiempo restante
                </Typography>
              </Row>
            </Chip>
          </Row>
        </Column>
        <Row marginTop={5} marginBottom={10}>
          <Bank />
          <Typography fontFamily="Poppins-Medium" fontSize={15}>
            Datos de{" "}
            {method ? paymentMethodVariants[method["type"]]["label"] : ""}
          </Typography>
        </Row>
        <PaymentInfo data={method} />
        <Line margin="10px 0" />
        <PaymentForm />
      </Column>
    </Screen>
  );
};
