import {congratulate, get} from "./thunks";
import {createSlice} from "@reduxjs/toolkit";
import {initialState} from "./initial-state";
import {setRequestStatus} from "@redux/request-status";

export const testimonialsSlice = createSlice({
  name: "testimonials",
  initialState,
  reducers: {},
  extraReducers: function (builder) {
    builder.addCase(get.pending, function (state) {
      state["request"]["testimonials"] = setRequestStatus("initial");
    });
    builder.addCase(get.fulfilled, function (state, {payload}) {
      const {data} = payload;
      state["testimonials"] = data;
      state["request"]["testimonials"] = setRequestStatus("success");
    });
    builder.addCase(get.rejected, function (state) {
      state["request"]["testimonials"] = setRequestStatus("error");
    });

    builder.addCase(congratulate.fulfilled, function (state, {payload}) {
      const lotteryUpdated = payload;
      const testimonialsUpdated = state["testimonials"];
      const index = testimonialsUpdated.findIndex(
        ({lottery}) => lottery["_id"] === lotteryUpdated["_id"]
      );
      testimonialsUpdated[index]["lottery"] = lotteryUpdated;
      testimonialsUpdated[index]["awards"] = lotteryUpdated["awards"];

      state["testimonials"] = testimonialsUpdated;
    });
  },
});

export const {reducer: testimonials} = testimonialsSlice;
