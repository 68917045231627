import {Route} from "interfaces";
import {
  Home,
  Login,
  SignUp,
  Invite,
  Lottery,
  Page404,
  Success,
  Ranking,
  Support,
  Profile,
  Winners,
  VerifySMS,
  MyReferrals,
  EditProfile,
  Testimonials,
  SupportTicket,
  PaymentReport,
  SelectTickets,
  Notifications,
  PaymentMethods,
  ChangePassword,
  VerifyExtraData,
  RecoverPassword,
  InitVerification,
  SelectSMSProvider,
  TermsAndConditions,
  ValidateRecoverCode,
  CreateSupportTicket,
  ParticipationHistory,
  WaitingForAdminApproval,
  ValidateVerificationCode,
} from "pages";

export const routes: Route[] = [
  {
    path: "/auth/recover-password",
    element: <RecoverPassword />,
    label: "Recuperar contraseña",
  },
  {
    path: "/auth/sign-up",
    element: <SignUp />,
    label: "Registro",
  },
  {
    path: "/auth/login",
    element: <Login />,
    label: "Inicio de sesión",
  },
  {
    path: "/auth/change-password/:code/:phone",
    element: <ChangePassword />,
    label: "Código de Recuperación",
  },
  {
    path: "/auth/validate-recover-code/:phone",
    element: <ValidateRecoverCode />,
    label: "Código de Recuperación",
  },
  {
    path: "/verification/init",
    element: <InitVerification />,
    label: "Verificación",
  },
  {
    path: "/verification/select-sms-provider",
    element: <SelectSMSProvider />,
    label: "Verificación",
  },
  {
    path: "/verification/validate-code",
    element: <ValidateVerificationCode />,
    label: "Verificación",
  },
  {
    path: "/verification/verify-extra-data",
    element: <VerifyExtraData />,
    label: "Verificación",
  },
  {
    path: "/verification/verify-sms",
    element: <VerifySMS />,
    label: "Verificación",
  },
  {
    path: "/",
    element: <Home />,
    label: "Inicio",
  },
  {
    path: "/profile",
    element: <Profile />,
    label: "Perfil",
  },
  {
    path: "/invite",
    element: <Invite />,
    label: "Invitar Amigo",
  },
  {
    path: "/profile/edit",
    element: <EditProfile />,
    label: "Editar Perfil",
  },
  {
    path: "/profile/referrals",
    element: <MyReferrals />,
    label: "Mis Referidos",
  },
  {
    path: "/profile/history",
    element: <ParticipationHistory />,
    label: "Historial",
  },
  {
    path: "/profile/edit",
    element: <EditProfile />,
    label: "Editar Perfil",
  },
  {
    path: "/lottery/:id",
    element: <Lottery />,
    label: "Sorteo",
  },
  {
    path: "/lottery/:id/tickets",
    element: <SelectTickets />,
    label: "Tickets",
  },
  {
    path: "/testimonials",
    element: <Testimonials />,
    label: "Testimonios",
  },
  {
    path: "/winners",
    element: <Winners />,
    label: "Ganadores",
  },
  {
    path: "/ranking",
    element: <Ranking />,
    label: "Posiciones",
  },
  {
    path: "/lottery/:id/payment-methods",
    element: <PaymentMethods />,
    label: "Métodos de pago",
  },
  {
    path: "/lottery/:id/waiting",
    element: <WaitingForAdminApproval />,
    label: "En espera de aprobación",
  },
  {
    element: <Success />,
    path: "/lottery/:id/success",
    label: "Éxito",
  },
  {
    path: "/lottery/:id/report",
    element: <PaymentReport />,
    label: "Reportar pago",
  },

  {
    path: "/terms-and-conditions",
    element: <TermsAndConditions />,
    label: "Términos y Condiciones",
  },
  {
    path: "/notifications",
    element: <Notifications />,
    label: "Notificaciones",
  },
  {
    path: "/support",
    element: <Support />,
    label: "Soporte",
  },
  {
    path: "/support/create",
    element: <CreateSupportTicket />,
    label: "Crear Ticket",
  },
  {
    path: "/support/tickets/:ticketId",
    element: <SupportTicket />,
    label: "Ticket",
  },
  /************************************
   * 🛑 404
   ************************************/
  {
    path: "*",
    element: <Page404 />,
    label: "404",
  },
];
