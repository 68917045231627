import {useEffect} from "react";
import {toggleFullScreenLoader} from "@redux/slices";
import {useAppDispatch, useAppSelector} from "hooks";
import {get, getRate} from "@redux/slices/config/thunks";
import {get as getNotifications} from "@redux/slices/notifications/thunks";
import {get as getLottery, getMyNumbers} from "@redux/slices/lottery/thunks";

export const useInitializeApp = () => {
  const dispatch = useAppDispatch();
  const {isLogged} = useAppSelector("auth");
  const {lottery, request} = useAppSelector("lottery");

  useEffect(() => {
    if (isLogged) dispatch(getNotifications());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLogged]);

  useEffect(() => {
    if (isLogged && lottery && request["lottery"]["success"]) {
      dispatch(getMyNumbers(lottery["_id"]));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLogged, lottery, request]);

  useEffect(() => {
    const init = async () => {
      await dispatch(get());
      await dispatch(getRate());
      await dispatch(getLottery());
    };

    init().finally(async () => {
      dispatch(toggleFullScreenLoader(false));
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};
