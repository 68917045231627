import {colors} from "styles";
import {useModal} from "hooks";
import {Container} from "./style";
import {useEffect, useState} from "react";
import {Column, Row, SpinnerLoader, Typography} from "components";

interface VideoModalProps {
  source: string;
  message?: string;
}

export const VideoModal: React.FC<VideoModalProps> = ({
  source,
  message = "Cargando video...",
}) => {
  const {closeModal} = useModal();
  const [showVideo, setShowVideo] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setShowVideo(true);
    }, 1500);
  }, []);

  return (
    <Container>
      {showVideo ? (
        <Column
          position="relative"
          alignItems="center"
          justifyContent="center"
          backgroundColor={colors["black"]}
        >
          <video
            style={{width: "95vw"}}
            controls
            autoPlay
            muted
            src={source}
            onEnded={() => {
              closeModal();
            }}
          />
        </Column>
      ) : (
        <Row>
          <SpinnerLoader size={45} />
          <Typography
            fontSize={16}
            color={colors["white"]}
            fontFamily="Poppins-Medium"
          >
            {message}
          </Typography>
        </Row>
      )}
    </Container>
  );
};
