import {useEffect} from "react";
import {Container} from "./styled";
import {useTheme} from "styled-components";
import {get} from "@redux/slices/lottery/thunks";
import {useAppDispatch, useAppSelector} from "hooks";
import {Actions, Awards, Header, Information} from "./lib";
import {Column, ComponentLoader, Screen, Skeleton} from "components";

interface LotteryProps {}

export const Lottery: React.FC<LotteryProps> = () => {
  const dispatch = useAppDispatch();
  const {black} = useTheme();
  const {lottery, request} = useAppSelector("lottery");

  useEffect(() => {
    dispatch(get());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Screen statusBarColor={black}>
      <ComponentLoader
        requestStatus={request["lottery"]}
        skeleton={
          <Column>
            <Skeleton height={400} />
          </Column>
        }
      >
        <Container>
          <Column position="relative">
            <Header />
            <video
              controls
              src={lottery?.media[0]}
              style={{height: "auto", width: "100%", backgroundColor: black}}
            />
          </Column>
          <Column gap={10} padding="15px">
            <Actions />
            <Information />
          </Column>
          <Awards />
        </Container>
      </ComponentLoader>
    </Screen>
  );
};
