import {Formik} from "formik";
import {Key} from "react-feather";
import {Container} from "./styled";
import {useAppDispatch} from "hooks";
import {useTheme} from "styled-components";
import {useNavigate} from "react-router-dom";
import {login} from "@redux/slices/auth/thunks";
import {initialValues, loginSchema} from "./form.schema";
import {
  Logo,
  Line,
  RRSS,
  Column,
  Screen,
  Button,
  PhoneInput,
  Typography,
  PasswordInput,
} from "components";

interface LoginProps {}

export const Login: React.FC<LoginProps> = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const {white, primary} = useTheme();
  const RECOVER_PASSWORD_COLOR = "rgba(0,0,0, .45)";

  return (
    <Screen
      style={{backgroundColor: primary}}
      header={{
        textColor: white,
        title: "Iniciar sesión",
        onBack: () => navigate(-1),
        guide: "Ingresa para participar",
      }}
    >
      <Column
        paddingTop={15}
        paddingBottom={40}
        alignItems="center"
        padding="15px 20px 0"
      >
        <Logo height={50} width={130} objectFit="cover" />
      </Column>
      <Container>
        <Column width="100%">
          <Formik
            validateOnChange={true}
            initialValues={initialValues}
            validationSchema={loginSchema}
            onSubmit={(payload) => {
              dispatch(login(payload));
            }}
          >
            {({
              dirty,
              values,
              errors,
              isValid,
              touched,
              handleBlur,
              handleSubmit,
              handleChange,
            }) => {
              const disabled = !(dirty && isValid);

              return (
                <Column gap={5} width="100%">
                  <PhoneInput
                    label=""
                    value={values["phone"]}
                    onChangePhoneNumber={(value) => {
                      handleChange({target: {name: "phone", value}});
                    }}
                  />
                  <PasswordInput
                    placeholder="Contraseña"
                    value={values["password"]}
                    touched={touched["password"]}
                    onBlur={handleBlur("password")}
                    onChange={handleChange("password")}
                    helperErrorText={errors["password"]}
                  />
                  <Button
                    loaderId="login"
                    disabled={disabled}
                    label="Iniciar sesión"
                    onClick={() => handleSubmit()}
                    style={{backgroundColor: primary, width: "100%"}}
                  />
                </Column>
              );
            }}
          </Formik>
          <Button
            variant="outlined"
            borderColor="transparent"
            label="Olvidé mi contraseña"
            typographyProps={{color: RECOVER_PASSWORD_COLOR}}
            leftIcon={<Key color={RECOVER_PASSWORD_COLOR} />}
            onClick={() => navigate("/auth/recover-password")}
            style={{
              marginTop: 5,
              width: "100%",
            }}
          />
        </Column>
        <Line marginTop={10} />
        <RRSS />
        <Column width="100%" marginTop={15}>
          <Typography>¿No tienes una cuenta?</Typography>
          <Button
            variant="outlined"
            borderColor={primary}
            label="Crear cuenta"
            style={{width: "100%"}}
            onClick={() => navigate("/auth/sign-up")}
            typographyProps={{color: primary}}
          />
        </Column>
      </Container>
    </Screen>
  );
};
