import moment from "moment";
import {colors} from "styles";
import {Inbox} from "react-feather";
import {Fragment, useEffect} from "react";
import {SkeletonScreen} from "./SkeletonScreen";
import {getById} from "@redux/slices/support/thunks";
import {useAppDispatch, useAppSelector} from "hooks";
import {useNavigate, useParams} from "react-router-dom";
import {
  Row,
  Chip,
  Line,
  Avatar,
  Screen,
  Column,
  Typography,
  EmptyUIState,
  ComponentLoader,
} from "components";

export const SupportTicket = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const {user} = useAppSelector("auth");
  const {ticket, request} = useAppSelector("support");
  const {ticketId} = useParams() as {ticketId: string};
  const {name, lastname, title, description, createdAt, responses} = ticket;

  useEffect(() => {
    dispatch(getById(ticketId));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ticketId]);

  return (
    <Screen
      bodyStyle={{padding: "1rem"}}
      header={{onBack: () => navigate(-1), title: "Detalle Ticket soporte"}}
    >
      <ComponentLoader
        skeleton={<SkeletonScreen />}
        requestStatus={request["ticket"]}
      >
        <Column gap={10}>
          <Row>
            <Avatar size={30} src={user["picture"]} />
            <Column gap={0}>
              <Typography fontFamily="Poppins-Medium">
                {name} {lastname} (Yo)
              </Typography>
              <Typography fontSize={12} color="rgba(0, 0, 0, .6)">
                {moment(createdAt).fromNow()}
              </Typography>
            </Column>
          </Row>
          <Column gap={5}>
            <Typography fontFamily="Poppins-SemiBold" fontSize={13}>
              {title}
            </Typography>
            <Typography color="rgba(0,0,0, .8)">{description}</Typography>
          </Column>
          <Line marginTop={10} marginBottom={5} />
          <EmptyUIState
            data={responses}
            icon={<Inbox width={35} height={35} color={colors["black"]} />}
            message={`Aún no has recibido una respuesta de soporte. \n¡Te mantendremos al tanto!`}
          >
            <Typography fontFamily="Poppins-SemiBold">
              ({responses["length"]}) Respuestas recibidas:
            </Typography>
            <Column gap={15}>
              {responses.map(
                ({name, lastname, createdAt, title, description}, key) => (
                  <Fragment key={key}>
                    <Column>
                      <Column gap={5}>
                        <Typography fontSize={12} color="rgba(0, 0, 0, .6)">
                          {moment(createdAt).fromNow()}
                        </Typography>
                        <Row>
                          <Chip padding="0 1rem">
                            <Row>
                              <Typography fontSize={12} color={colors["white"]}>
                                Admin
                              </Typography>
                            </Row>
                          </Chip>
                          <Typography fontFamily="Poppins-SemiBold">
                            {name} {lastname}
                          </Typography>
                        </Row>
                      </Column>
                      <Column gap={0}>
                        <Typography fontFamily="Poppins-Medium">
                          {title}
                        </Typography>
                        <Typography>{description}</Typography>
                      </Column>
                    </Column>
                    {key + 1 !== responses["length"] && <Line />}
                  </Fragment>
                )
              )}
            </Column>
          </EmptyUIState>
        </Column>
      </ComponentLoader>
    </Screen>
  );
};
