import moment from "moment";
import {dateDiff} from "utils";
import {useAppSelector} from "hooks";
import {useTheme} from "styled-components";
import {Fragment} from "react/jsx-runtime";
import {Calendar, Clock, DollarSign} from "react-feather";
import {
  Line,
  Column,
  InfoField,
  Typography,
  ProgressBar,
  Subscribers,
} from "components";

interface InformationProps {}

export const Information: React.FC<InformationProps> = () => {
  const {black} = useTheme();
  const {rate} = useAppSelector("config");
  const {
    users,
    price,
    number,
    numbers,
    end_date,
    start_date,
    totalTicketsSold,
  } = useAppSelector("lottery")["lottery"];

  return (
    <Column width="100%">
      <Typography fontFamily="Poppins-SemiBold" fontSize={16}>
        Detalles y progreso del sorteo #{number}
      </Typography>
      {!!users["length"] && (
        <Fragment>
          <Column width="100%">
            <Subscribers />
            <Typography fontFamily="Poppins-Light" fontSize={14}>
              Personas inscritas en el sorteo
            </Typography>
          </Column>
          <Line margin={0} />
        </Fragment>
      )}
      <Column width="100%">
        <ProgressBar value={totalTicketsSold} max={numbers} />
        <Typography fontFamily="Poppins-Light" fontSize={14}>
          Progreso del sorteo
        </Typography>
      </Column>
      <Line margin={0} />
      <Column width="100%">
        <InfoField
          guide="Costo de cada ticket"
          icon={<DollarSign width={25} height={25} color="#000" />}
          title={`$${price} ${
            rate["monitors"]["usd"]
              ? `/ ${(rate["monitors"]["usd"]["price"] * price).toLocaleString(
                  "US"
                )} Bs`
              : ""
          }`}
        />
        <InfoField
          guide="Fecha de inicio"
          title={moment(start_date).format("DD MMMM")}
          icon={<Calendar width={25} height={25} color={black} />}
        />
        <InfoField
          guide="Tiempo transcurrido"
          title={dateDiff(start_date)}
          icon={<Clock width={25} height={25} color={black} />}
        />
        <InfoField
          title={moment(end_date).format("DD MMMM")}
          guide="Fecha de cierre | Anuncio del ganador"
          icon={<Calendar width={25} height={25} color={black} />}
        />
      </Column>
    </Column>
  );
};
