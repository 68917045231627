import {getConfig} from "utils";
import {Ticket as TicketIcon} from "svg";
import {useEffect, useState} from "react";
import {useTheme} from "styled-components";
import {SelectedByUser, Ticket} from "./lib";
import {Container, IndicatorGuide} from "./styled";
import {pickTicket, showAlert} from "@redux/slices";
import {LotteryNumber, UserModel} from "interfaces";
import {CheckCircle, Eye, Info, XOctagon} from "react-feather";
import {useAppDispatch, useAppSelector, useModal} from "hooks";
import {
  Row,
  Input,
  Column,
  Typography,
  SwitchButton,
  EmptyUIState,
} from "components";

interface TicketsProps {}

export const Tickets: React.FC<TicketsProps> = () => {
  const {openModal} = useModal();
  const dispatch = useAppDispatch();
  const {configs} = useAppSelector("config");
  const {lottery} = useAppSelector("lottery");
  const {ticketsSelected} = useAppSelector("payment");
  const {reserved, frozen, black, white} = useTheme();
  const [tickets, setTickets] = useState<LotteryNumber[]>([]);
  const [display, setDisplay] = useState<"all" | "available">("all");
  const {value: maxTicketPerUser} = getConfig(configs, "max_ticket_per_user");
  const {numbersArray} = lottery;

  useEffect(() => {
    setTickets(numbersArray);
  }, [numbersArray]);

  return (
    <Column width="100%" margin="0 0 25px" gap={15}>
      <Column gap={0}>
        <Row>
          <IndicatorGuide style={{backgroundColor: white}} />
          <Typography>Disponibles</Typography>
        </Row>
        <Row>
          <IndicatorGuide style={{backgroundColor: reserved}} />
          <Typography>No disponibles</Typography>
        </Row>
        <Row>
          <IndicatorGuide style={{backgroundColor: frozen}} />
          <Typography>Congelados</Typography>
        </Row>
      </Column>
      <Row>
        <Info color="#000" />
        <Typography fontFamily="Poppins-Medium" fontSize={15}>
          Elige uno o más tickets
        </Typography>
      </Row>
      <Input
        inputMode="numeric"
        placeholder="Filtrar tickets"
        style={{fontFamily: "Poppins-Medium", fontSize: 16}}
        onChange={({target}) => {
          const query = target["value"];
          if (query) {
            setTickets(
              numbersArray.filter(({number}) => String(number).includes(query))
            );
          } else {
            setTickets(
              display === "all"
                ? numbersArray
                : numbersArray.filter(({user}) => !user)
            );
          }
        }}
      />
      <Column width="100%" gap={15}>
        <SwitchButton
          onSelect={(value) => {
            setDisplay(value as typeof display);
            setTickets(
              value === "all"
                ? numbersArray
                : numbersArray.filter(({user}) => !user)
            );
          }}
          options={[
            {label: "Ver todos", icon: Eye, value: "all"},
            {label: "Solo disponibles", icon: CheckCircle, value: "available"},
          ]}
        />
      </Column>
      <EmptyUIState
        data={tickets}
        style={{width: "100%"}}
        message="No se encontraron tickets"
        icon={<TicketIcon color={black} />}
      >
        <Container>
          {tickets.map((ticket, key) => (
            <Ticket
              key={key}
              ticket={ticket}
              onClick={(ticket) => {
                if (
                  !ticketsSelected.includes(ticket["number"]) &&
                  ticketsSelected["length"] === Number(maxTicketPerUser)
                ) {
                  dispatch(
                    showAlert({
                      type: "error",
                      title: "No se pueden tomar más tickets",
                      message: `Actualmente sólo se puede comprar ${maxTicketPerUser} ticket por persona`,
                    })
                  );
                  return;
                }
                const {user, payment, number} = ticket;
                if (payment) {
                  openModal(
                    <SelectedByUser ticket={number} user={user as UserModel} />
                  );
                } else if (user) {
                  openModal(
                    <Column backgroundColor={white}>
                      <Row>
                        <XOctagon color={black} />
                        <Typography fontFamily="Poppins-Medium">
                          Ticket congelado
                        </Typography>
                      </Row>
                      <Typography>
                        Este ticket ({number}) fue congelado por{" "}
                        <Typography fontFamily="Poppins-SemiBold">
                          {user["name"]}
                        </Typography>
                      </Typography>
                      <Typography>
                        Una vez pasado los {0} minutos hábiles volverá a estar
                        disponible si el usuario NO reporta su pago
                      </Typography>
                    </Column>
                  );
                } else {
                  dispatch(pickTicket(ticket));
                }
              }}
            />
          ))}
        </Container>
      </EmptyUIState>
    </Column>
  );
};
