import moment from "moment";
import {colors} from "styles";
import Confetti from "react-confetti";
import {Fragment, useEffect} from "react";
import {alreadySeenIt} from "@redux/slices";
import {useNavigate} from "react-router-dom";
import {get} from "@redux/slices/winners/thunks";
import {ChevronLeft, ThumbsUp} from "react-feather";
import {useAppDispatch, useAppSelector} from "hooks";
import {
  Row,
  Logo,
  Image,
  Avatar,
  Column,
  Screen,
  Button,
  Skeleton,
  Container,
  RankCircle,
  Typography,
  ComponentLoader,
} from "components";

export const Winners = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const {winners, request} = useAppSelector("winners");

  useEffect(() => {
    dispatch(alreadySeenIt());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!winners["_id"]) dispatch(get());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [winners]);

  return (
    <Fragment>
      <Confetti
        gravity={0.015}
        width={window.innerWidth}
        height={window.innerHeight}
      />
      <Screen
        bodyStyle={{padding: "1rem"}}
        style={{backgroundColor: colors["primary"]}}
        header={{
          title: "¡Felicidades!",
          textColor: colors["white"],
          onBack: () => navigate("/"),
          guide: `Ganadores del Sorteo #${winners["number"] ?? ""}`,
        }}
      >
        <ComponentLoader
          requestStatus={request["winners"]}
          skeleton={
            <Column width="100%" alignItems="center">
              <Skeleton width="80%" height={150} />
              <Skeleton width="80%" height={150} />
              <Skeleton width="80%" height={150} />
              <Skeleton width="80%" height={150} />
              <Skeleton width="80%" height={200} />
              <Column gap={2} width="100%" alignItems="center">
                <Skeleton borderRadius={1000} width="80%" height={50} />
                <Skeleton borderRadius={1000} width="80%" height={50} />
              </Column>
            </Column>
          }
        >
          <Column>
            <Column width="100%">
              {winners["awards"].map(
                ({number, position, title, media}, key) => {
                  const {userFound} = number;
                  const {name, lastname, createdAt, picture} = userFound;
                  return (
                    <Column key={key} width="100%" alignItems="center">
                      <Row justifyContent="center">
                        <Container
                          width={55}
                          position="relative"
                          justifyContent="center"
                        >
                          <Image
                            width={45}
                            height={45}
                            objectFit="contain"
                            src="/assets/winner.png"
                          />
                          <Container position="absolute" bottom={-5} right={5}>
                            <RankCircle size={20} rank={Number(position)} />
                          </Container>
                        </Container>
                        <Row>
                          <Avatar size={34} src={picture} />
                          <Column gap={0} maxWidth={150}>
                            <Typography
                              color={colors["white"]}
                              className="line-clamp-1"
                              fontFamily="Poppins-Medium"
                            >
                              {name} {lastname}
                            </Typography>
                            <Typography fontSize={12} color={colors["white"]}>
                              se registró el{" "}
                              {moment(createdAt).format("MM/YYYY")}
                            </Typography>
                          </Column>
                        </Row>
                      </Row>
                      <Column alignItems="center" gap={0}>
                        <Typography color={colors["white"]} fontSize={14}>
                          Has ganado el premio #{position}
                        </Typography>
                        <Typography
                          fontSize={16}
                          color={colors["white"]}
                          fontFamily="Poppins-Medium"
                        >
                          {title}
                        </Typography>
                        <Image
                          width={180}
                          height={180}
                          src={media[0]}
                          objectFit="contain"
                        />
                      </Column>
                    </Column>
                  );
                }
              )}
            </Column>
            <Column gap={5} padding="0 1rem">
              <Typography color={colors["white"]} fontFamily="Poppins-Medium">
                ¡Felicidades🎉, esperamos que disfruten de sus premios!
              </Typography>
              <Typography color={colors["white"]}>
                Nuestro personal se estará comunicando con los ganadores para su
                entrega personal.
              </Typography>
              <Typography color={colors["white"]} fontFamily="Poppins-Medium">
                Una vez entregados los premios podrás ver los{" "}
                <Typography
                  fontFamily="Poppins-Bold"
                  color={colors["secondary"]}
                >
                  testimonios
                </Typography>{" "}
                de los ganadores
              </Typography>
            </Column>
          </Column>
          <Column margin="1rem auto 0" gap={5} width="100%">
            <Button
              style={{width: "100%"}}
              label="Ver testimonios"
              onClick={() => navigate("/testimonials")}
              leftIcon={
                <ThumbsUp color={colors["white"]} width={20} height={20} />
              }
            />
            <Button
              style={{width: "100%"}}
              label="Volver al inicio"
              variant="outlined"
              borderColor={colors["white"]}
              onClick={() => navigate("/")}
              typographyProps={{color: colors["white"]}}
              leftIcon={
                <ChevronLeft color={colors["white"]} width={20} height={20} />
              }
            />
          </Column>
          <Row justifyContent="center" marginTop={20} marginBottom={30}>
            <Logo />
          </Row>
        </ComponentLoader>
      </Screen>
    </Fragment>
  );
};
