import {Ticket} from "svg";
import {Row, Typography} from "../";
import {useTheme} from "styled-components";
import {Container, Progress} from "./styled";

interface ProgressBarProps {
  max: number;
  value: number;
  progressBarColor?: string;
  progressBarLabel?: string;
}

export const ProgressBar: React.FC<ProgressBarProps> = ({
  max,
  value,
  progressBarColor,
  progressBarLabel = "Tickets",
}) => {
  const {secondary} = useTheme();
  const progress = (value * 100) / max;
  return (
    <Container>
      <Progress
        style={{
          width: `${progress}%`,
          backgroundColor: progressBarColor ?? secondary,
        }}
      />
      <Row
        top={0}
        left={0}
        right={0}
        bottom={0}
        width="100%"
        height="100%"
        borderRadius={100}
        position="absolute"
        justifyContent="center"
      >
        <Ticket />
        <Typography color="#fff" fontFamily="Poppins-SemiBold">
          {value}/{max} {progressBarLabel}
        </Typography>
      </Row>
    </Container>
  );
};
