import {Formik} from "formik";
import {colors} from "styles";
import {useAppDispatch, useAppSelector} from "hooks";
import {initialValues, verifySMSSchema} from "./form.schema";
import {sendVerificationSMS} from "@redux/slices/auth/thunks";
import {Input, Column, Screen, Button, Stepper, Typography} from "components";

export const VerifySMS = () => {
  const dispatch = useAppDispatch();
  const {user} = useAppSelector("auth");
  return (
    <Screen bodyStyle={{padding: 20}}>
      <Stepper steps={4} current={3} />
      <Formik
        enableReinitialize
        validationSchema={verifySMSSchema}
        initialValues={{...initialValues, phone: user["phone"]}}
        onSubmit={(payload) => {
          dispatch(sendVerificationSMS(payload));
        }}
      >
        {({
          values,
          errors,
          isValid,
          touched,
          handleBlur,
          handleSubmit,
          handleChange,
        }) => {
          const disabled = !isValid;
          return (
            <Column gap={15} marginTop={10}>
              <Column>
                <Input
                  autoFocus
                  disabled={true}
                  inputMode="numeric"
                  value={values["phone"]}
                  placeholder="04122221122"
                  label="Número Telefónico"
                  touched={touched["phone"]}
                  onBlur={handleBlur("phone")}
                  onChange={handleChange("phone")}
                  helperErrorText={errors["phone"]}
                />
                <Typography style={{textAlign: "center"}}>
                  Enviaremos un código a tu número telefónico el cual deberás
                  ingresar
                </Typography>
              </Column>
              <Button
                disabled={disabled}
                label="Enviar código"
                style={{width: "100%"}}
                loaderId="verify-with-sms"
                onClick={() => handleSubmit()}
                backgroundColor={colors["primary"]}
              />
            </Column>
          );
        }}
      </Formik>
    </Screen>
  );
};
