import {colors} from "styles";
import {Number} from "./styled";
import {UserModel} from "interfaces";
import {useTheme} from "styled-components";
import {Avatar, Column, Row, Typography} from "components";

interface SelectedByUserProps {
  ticket: number;
  user: UserModel;
}

export const SelectedByUser: React.FC<SelectedByUserProps> = ({
  user,
  ticket,
}) => {
  const {white} = useTheme();
  const {name, lastname, picture, address} = user;
  return (
    <Column
      backgroundColor={white}
      padding="1.5rem"
      borderRadius={10}
      alignItems="center"
    >
      <Row justifyContent="center">
        <Typography fontFamily="Poppins-Medium">Ticket</Typography>
        <Number>
          <Typography
            fontSize={16}
            color={colors["white"]}
            fontFamily="Poppins-SemiBold"
          >
            {ticket}
          </Typography>
        </Number>
        <Typography fontFamily="Poppins-Medium">ha sido reservado</Typography>
      </Row>
      <Column gap={5} alignItems="center">
        <Avatar src={picture} />
        <Column gap={0} alignItems="center">
          <Typography fontFamily="Poppins-Medium">
            {name} {lastname}
          </Typography>
          <Typography fontSize={12} color="#999999">
            {address}
          </Typography>
        </Column>
      </Column>
    </Column>
  );
};
