import {useState} from "react";
import "react-international-phone/style.css";
import {Column, Typography} from "components";
import {PhoneInput as Phone} from "react-international-phone";

interface PhoneInputProps {
  value: string;
  label?: string;
  helperText?: string;
  onChangePhoneNumber: (value: string) => void;
}

function buildPlaceholder(code: string = "+58"): string {
  const obj: Record<string, string> = {
    "+58": "... ... ....",
    "+1": "... ... ....",
    "+57": "... ... ....",
    "+54": "... ... ....",
    "+51": "... ... ...",
    "+593": ".. ... ....",
  };
  return obj[code];
}

export const PhoneInput: React.FC<PhoneInputProps> = ({
  value,
  helperText,
  onChangePhoneNumber,
  label = "Teléfono móvil",
}) => {
  const [selectedCountry, setSelectedCountry] = useState<string>();
  const placeholder = buildPlaceholder(selectedCountry);

  return (
    <Column gap={5} width="100%">
      {label && <Typography fontSize={13}>{label}</Typography>}
      <Phone
        forceDialCode
        value={value}
        defaultCountry="ve"
        style={{width: "100%"}}
        defaultMask={placeholder}
        inputStyle={{
          width: "100%",
          height: "57px",
          fontWeight: 400,
          fontSize: "1rem",
          fontFamily: "Poppins",
          borderRadius: "0 15px 15px 0",
        }}
        countrySelectorStyleProps={{
          buttonStyle: {
            height: "57px",
            padding: "0 1rem",
            borderRadius: "15px 0 0 15px",
          },
        }}
        onChange={(phone, {country}) => {
          onChangePhoneNumber(phone);
          setSelectedCountry(`+${country["dialCode"]}`);
        }}
      />
      {helperText && (
        <Typography style={{textAlign: "right"}} fontSize={12}>
          {helperText}
        </Typography>
      )}
    </Column>
  );
};
