import {State} from "./types";
import {setRequestStatus} from "@redux/request-status";

export const initialState: State = {
  banks: [],
  methods: [],
  ticketsSelected: [],
  methodId: undefined,
  participatedNumbers: [],
  paymentMethod: undefined,
  showSupportWarning: false,
  userPayloadReport: {
    dni: "",
    code: "",
    email: "",
    phone: "",
    codeBank: "",
    dniPrefix: "V",
    screenshot: undefined,
  },
  request: {
    banks: setRequestStatus("initial"),
    methods: setRequestStatus("initial"),
  },
};
