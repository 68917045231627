import {colors} from "styles";
import {useAppSelector} from "hooks";
import {useTheme} from "styled-components";
import {DollarSign, Info} from "react-feather";
import {Row, Column, Typography} from "components";

interface SummaryProps {}

export const Summary: React.FC<SummaryProps> = () => {
  const {black} = useTheme();
  const {lottery} = useAppSelector("lottery");
  const {monitors} = useAppSelector("config")["rate"];
  const {ticketsSelected} = useAppSelector("payment");
  const USDTotal = ticketsSelected["length"] * lottery["price"];
  const VESTotal = USDTotal * monitors["usd"]["price"];
  return (
    <Column gap={20} width="100%">
      <Row>
        <Info color={black} />
        <Typography fontFamily="Poppins-Medium" fontSize={15}>
          Resumen de compra
        </Typography>
      </Row>
      <Column
        width="100%"
        padding={15}
        borderRadius={10}
        backgroundColor={colors["primary"]}
        boxShadow="1px 1px 1px 1px rgba(0,0,0, .1)"
      >
        <Column gap={2}>
          <Typography color="#fff" fontSize={19} fontFamily="Poppins-Medium">
            {ticketsSelected["length"]} tickets en el sorteo #
            {lottery["number"]}
          </Typography>
          <Typography color="#fff" fontSize={12}>
            Descripción de compra
          </Typography>
        </Column>
        <div
          style={{
            width: "100%",
            borderWidth: 1,
            borderRadius: 1,
            borderColor: "#fff",
            borderStyle: "dotted",
          }}
        />
        <Column gap={2} alignItems="flex-end" width="100%">
          <Row gap={10}>
            <Row gap={4}>
              <DollarSign width={20} height={20} color="#fff" />
              <Typography
                color="#fff"
                fontSize={25}
                fontFamily="Poppins-Medium"
              >
                {USDTotal}
              </Typography>
            </Row>
            <Typography color="#fff">/</Typography>
            <Row gap={4}>
              <Typography color="#fff" fontSize={16}>
                Bs
              </Typography>
              <Typography
                color="#fff"
                fontSize={25}
                fontFamily="Poppins-Medium"
              >
                {VESTotal.toLocaleString("US")}
              </Typography>
            </Row>
          </Row>
          <Typography color="#fff" fontSize={12}>
            Total a pagar
          </Typography>
        </Column>
      </Column>
    </Column>
  );
};
