import {Container} from "./styled";
import {CSSProperties} from "react";
import {Button, ButtonProps, Column, Row, Typography} from "components";

interface WarningProps {
  title: string;
  message: string;
  button?: ButtonProps;
  style?: CSSProperties;
}

export const Warning: React.FC<WarningProps> = ({
  title,
  button,
  message,
  style: customStyle,
}) => {
  return (
    <Container style={customStyle}>
      <Row
        padding={14}
        borderTopLeftRadius={15}
        borderTopRightRadius={15}
        backgroundColor="rgba(50, 28, 129, 0.07)"
      >
        <Typography fontFamily="Poppins-Medium" fontSize={16}>
          {title}
        </Typography>
      </Row>
      <Column
        gap={10}
        padding={12}
        backgroundColor="#fff"
        borderBottomLeftRadius={15}
        borderBottomRightRadius={15}
        boxShadow="0px 2px 3px 1px rgba(0,0,0, .1)"
      >
        <Typography>{message}</Typography>
        {button && (
          <Row justifyContent="center" width="100%">
            <Button {...button} />
          </Row>
        )}
      </Column>
    </Container>
  );
};
