import {Bank} from "svg";
import {useEffect} from "react";
import {useNext} from "./useNext";
import {Skeleton} from "./Skeleton";
import {Method, Summary} from "./lib";
import {useTheme} from "styled-components";
import {useNavigate} from "react-router-dom";
import {setPaymentMethod} from "@redux/slices";
import {useAppDispatch, useAppSelector} from "hooks";
import {ChevronRight, CreditCard} from "react-feather";
import {getMethods} from "@redux/slices/payment/thunks";
import {paymentMethodVariants} from "constants/payment-method-variants";
import {
  Row,
  Column,
  Screen,
  Typography,
  ComponentLoader,
  CommonOutlinedErrorBody,
} from "components";

interface PaymentMethodsProps {}

export const PaymentMethods: React.FC<PaymentMethodsProps> = () => {
  const {next} = useNext();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const {methodId, methods, request} = useAppSelector("payment");
  const {disabledButtonTextColor, primary, white, black} = useTheme();
  const disabled = typeof methodId === "undefined";

  useEffect(() => {
    dispatch(getMethods());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Screen
      bodyStyle={{paddingBottom: 50}}
      header={{
        title: "Métodos de pago",
        onBack: () => navigate(-1),
        rightButton: {
          disabled,
          onClick: next,
          label: "Siguiente",
          style: {padding: "0 15px"},
          rightIcon: (
            <ChevronRight
              width={22}
              height={22}
              color={disabled ? disabledButtonTextColor : white}
            />
          ),
        },
      }}
    >
      <Column width="100%" gap={5} padding="0px 15px">
        <Summary />
        <Column gap={20} width="100%">
          <Row>
            <Bank />
            <Column gap={0}>
              <Typography fontFamily="Poppins-Medium" fontSize={15}>
                Métodos de págo
              </Typography>
              <Typography fontFamily="Poppins-Light" fontSize={13}>
                Selecciona el que más te guste
              </Typography>
            </Column>
          </Row>
          <ComponentLoader
            skeleton={<Skeleton />}
            requestStatus={request["methods"]}
            emptyUI={{
              data: methods,
              message: "No hay métodos de pago por el momento",
              icon: <CreditCard color={black} width={40} height={40} />,
              body: (
                <CommonOutlinedErrorBody
                  color={primary}
                  callback={() => dispatch(getMethods())}
                />
              ),
            }}
            errorUI={{
              body: (
                <CommonOutlinedErrorBody
                  callback={() => dispatch(getMethods())}
                />
              ),
            }}
          >
            <Column width="100%">
              {methods.map(({type, _id, typeString}, key) => (
                <Method
                  key={key}
                  value={_id}
                  title={typeString}
                  selected={_id === methodId}
                  image={paymentMethodVariants[type]["image"]}
                  description={paymentMethodVariants[type]["description"]}
                  onPress={(value) =>
                    dispatch(
                      setPaymentMethod({
                        methodId: value,
                        method: paymentMethodVariants[type]["UIMethod"],
                      })
                    )
                  }
                />
              ))}
            </Column>
          </ComponentLoader>
        </Column>
      </Column>
    </Screen>
  );
};
