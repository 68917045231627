import styled from "styled-components";

export const Radio = styled.div`
  width: 25px;
  height: 25px;
  display: flex;
  align-items: center;
  border-radius: 100px;
  justify-content: center;
  background-color: #f2f2f2;
`;

export const Selected = styled.div`
  width: 20px;
  height: 20px;
  border-radius: 100px;
`;

export const Container = styled.div`
  width: 100%;
  padding: 10px;
  border-radius: 10px;
`;
