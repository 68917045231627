import {createSlice} from "@reduxjs/toolkit";
import {initialState} from "./initial-state";
import {createTicket, get, getById} from "./thunks";
import {setRequestStatus} from "@redux/request-status";

export const supportSlice = createSlice({
  name: "support",
  initialState,
  reducers: {},
  extraReducers: function (builder) {
    builder.addCase(get.pending, function (state) {
      state["request"]["tickets"] = setRequestStatus("initial");
    });
    builder.addCase(get.fulfilled, function (state, {payload}) {
      const {data} = payload;
      state["tickets"] = data;
      state["request"]["tickets"] = setRequestStatus("success");
    });
    builder.addCase(get.rejected, function (state) {
      state["request"]["tickets"] = setRequestStatus("error");
    });

    builder.addCase(getById.pending, function (state) {
      state["request"]["ticket"] = setRequestStatus("initial");
    });
    builder.addCase(getById.fulfilled, function (state, {payload}) {
      const {data} = payload;
      state["ticket"] = data;
      state["request"]["ticket"] = setRequestStatus("success");
    });
    builder.addCase(getById.rejected, function (state) {
      state["request"]["ticket"] = setRequestStatus("error");
    });

    builder.addCase(createTicket.fulfilled, function (state, {payload}) {
      state["tickets"] = [...state["tickets"], payload];
    });
  },
});

export const {reducer: support} = supportSlice;
