import {Formik} from "formik";
import {Fragment} from "react";
import {colors} from "styles";
import {useAppDispatch} from "hooks";
import {useNavigate} from "react-router-dom";
import {createTicket} from "@redux/slices/support/thunks";
import {Input, Column, Screen, Button, Textarea} from "components";
import {initialValues, createSupportTicketSchema} from "./form.schema";

export const CreateSupportTicket = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  return (
    <Screen
      bodyStyle={{padding: "1rem"}}
      header={{
        title: "Crear Ticket",
        onBack: () => navigate(-1),
        guide: "Notifica a nuestro equipo de soporte qué sucede",
      }}
    >
      <Column width="100%" gap={0}>
        <Formik
          validateOnChange={true}
          initialValues={initialValues}
          validationSchema={createSupportTicketSchema}
          onSubmit={(payload) => {
            dispatch(createTicket(payload));
          }}
        >
          {({
            dirty,
            values,
            errors,
            isValid,
            touched,
            handleBlur,
            handleSubmit,
            handleChange,
          }) => {
            const disabled = !(dirty && isValid);

            return (
              <Fragment>
                <Input
                  autoFocus
                  label="Título"
                  value={values["title"]}
                  touched={touched["title"]}
                  onBlur={handleBlur("title")}
                  helperErrorText={errors["title"]}
                  placeholder="Sucedió un error con"
                  onChange={handleChange("title")}
                />
                <Textarea
                  label="Descripción"
                  value={values["description"]}
                  touched={touched["description"]}
                  onBlur={handleBlur("description")}
                  helperErrorText={errors["description"]}
                  onChange={handleChange("description")}
                  placeholder="Estaba reportando un pago y..."
                />
                <Button
                  disabled={disabled}
                  label="Crear Ticket"
                  style={{width: "100%"}}
                  onClick={() => handleSubmit()}
                  loaderId="create-support-ticket"
                  backgroundColor={colors["primary"]}
                />
              </Fragment>
            );
          }}
        </Formik>
      </Column>
    </Screen>
  );
};
