import {showAlert} from "@redux/slices";
import {subscribe} from "@redux/slices/payment/thunks";
import {Column, Confirm, Typography} from "components";
import {useModal, useAppDispatch, useAppSelector} from "hooks";

export const MINUTES_TO_DECLINE_PAYMENT = 10;

export const useNext = () => {
  const {openModal} = useModal();
  const dispatch = useAppDispatch();
  const {tickets} = useAppSelector("auth")["myCredit"];
  const {_id: lotteryId} = useAppSelector("lottery")["lottery"];
  const {paymentMethod, ticketsSelected: numbers} = useAppSelector("payment");

  function next() {
    switch (paymentMethod) {
      case "bank":
      case "zelle":
      case "binance":
      case "mobile-payment":
        openModal(
          <Confirm
            body={
              <Column>
                <Typography fontSize={16} fontFamily="Poppins-SemiBold">
                  ¿Seguro que deseas continuar?
                </Typography>
                <Typography>
                  Tendrás un máximo de{" "}
                  <Typography fontFamily="Poppins-SemiBold">
                    {MINUTES_TO_DECLINE_PAYMENT} minutos
                  </Typography>{" "}
                  para transferir el dinero y reportar tu comprobante
                </Typography>
                <Typography fontSize={14}>
                  Si el tiempo se termina y no has reportado tu pago, se
                  cancelará tu resesrvación
                </Typography>
              </Column>
            }
            actions={[
              {
                loaderId: "subscribe",
                label: "Sí, continuar",
                onClick: () => {
                  dispatch(subscribe({lotteryId, numbers}));
                },
              },
            ]}
          />
        );
        break;

      default:
        if (numbers["length"] > tickets) {
          dispatch(
            showAlert({
              type: "error",
              title: "No tienes cupones suficientes",
              message: "Invita amigos al Centro de Apuesta para ganar cupones",
            })
          );
          return;
        }
        openModal(
          <Confirm
            body={
              <Column>
                <Typography fontSize={15} fontFamily="Poppins-SemiBold">
                  ¿Seguro que deseas continuar?
                </Typography>
                <Typography>
                  Actualmente tienes {tickets} cupones. Luego de la compra te
                  quedaran {tickets - numbers["length"]} cupones disponibles.
                </Typography>
              </Column>
            }
            actions={[
              {
                loaderId: "report-payment",
                label: "Sí, continuar",
                onClick: () => {
                  dispatch(
                    subscribe({
                      numbers,
                      lotteryId,
                      showAlert: false,
                    })
                  );
                },
              },
            ]}
          />
        );
    }
  }

  return {next};
};
