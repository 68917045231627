import moment from "moment";
import {formatDate} from "utils";

/**
 * Checks if the given end date has expired.
 * @param endDate - The end date to check.
 * @returns True if the current time is after the end date, false otherwise.
 */
export const timeIsExpired = (endDate: Date): boolean => {
  const formattedEndDate = formatDate(endDate);
  return moment().isAfter(formattedEndDate);
};
