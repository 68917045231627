import {useAppSelector} from "hooks";
import {MyTickets} from "../MyTickets";
import {useTheme} from "styled-components";
import {useNavigate} from "react-router-dom";
import {Bell, Menu, User} from "react-feather";
import {Button, IconButton, Logo, Row, Typography} from "components";

interface HeaderProps {}

export const Header: React.FC<HeaderProps> = () => {
  const navigate = useNavigate();
  const {isLogged} = useAppSelector("auth");
  const {white, black, secondary} = useTheme();
  const {unread} = useAppSelector("notifications");

  return (
    <Row marginBottom={20} justifyContent="space-between">
      <Logo objectFit="cover" height={35} width={90} />
      {isLogged ? (
        <Row gap={8}>
          <MyTickets />
          <IconButton
            size={45}
            backgroundColor={white}
            icon={<Bell color={black} />}
            onClick={() => navigate("/notifications")}
            indicator={{
              style: {backgroundColor: "#FF7900"},
              value: (
                <Typography color={white} fontFamily="Poppins-Medium">
                  {unread}
                </Typography>
              ),
            }}
          />
          <IconButton
            size={45}
            backgroundColor={secondary}
            icon={<Menu color={white} />}
            onClick={() => navigate("/profile")}
          />
        </Row>
      ) : (
        <Button
          label="Iniciar sesión"
          onClick={() => navigate("/auth/login")}
          leftIcon={<User color={white} width={20} height={20} />}
        />
      )}
    </Row>
  );
};
