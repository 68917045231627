import {api} from "api";
import {createAsyncThunk} from "@reduxjs/toolkit";
import {ConfigModel, RateModel} from "interfaces";
import {thunkBadRequest} from "@redux/thunk-error";

export const getRate = createAsyncThunk(
  "config/get-rate",
  async (_, {dispatch, rejectWithValue}) => {
    try {
      return await api.Get<RateModel>("/rate/get");
    } catch (error) {
      thunkBadRequest(dispatch, error);
      return rejectWithValue(error);
    }
  }
);

export const get = createAsyncThunk(
  "config/get",
  async (_, {dispatch, rejectWithValue}) => {
    try {
      return await api.Get<ConfigModel[]>(
        "/config/getAll?limit=1000&position=0&search="
      );
    } catch (error) {
      thunkBadRequest(dispatch, error);
      return rejectWithValue(error);
    }
  }
);
