import {State} from "./types";
import {lotteryInitialState} from "@redux/initial-states";
import {setRequestStatus} from "@redux/request-status";

export const initialState: State = {
  ranking: lotteryInitialState,
  request: {
    ranking: setRequestStatus("initial"),
  },
};
