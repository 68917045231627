import {Image} from "./styled";
import {CSSProperties} from "react";

interface LogoProps extends CSSProperties {
  variant?: "light" | "dark";
}

export const Logo: React.FC<LogoProps> = ({variant = "light", ...props}) => {
  return (
    <Image
      width={35}
      height={35}
      src={variant === "light" ? "/assets/logo.png" : "/assets/logo-dark.png"}
      style={{...props}}
    />
  );
};
