import {rankColor} from "utils";
import {UserModel} from "interfaces";
import {Clickable} from "interfaces";
import {Row, Avatar, Column, RankCircle, Typography} from "components";

interface WinnerProps extends Clickable {
  size?: number;
  ticket: string;
  user: UserModel;
  rank: keyof typeof rankColor;
}

export const Winner: React.FC<WinnerProps> = ({
  rank,
  user,
  ticket,
  size = 55,
  ...clickableProps
}) => {
  return (
    <Column
      gap={0}
      alignItems="center"
      position="relative"
      justifyContent="center"
      {...clickableProps}
    >
      <Avatar
        size={size}
        src={user["picture"]}
        border={`4px solid ${rankColor[rank]}`}
      />
      <Row
        left={0}
        top={-22}
        width="100%"
        position="absolute"
        justifyContent="center"
      >
        <Typography
          fontSize={12}
          color="#6A6A6A"
          className="line-clamp-1"
          style={{textAlign: "center"}}
        >
          {user["name"]} {user["lastname"]}
        </Typography>
      </Row>
      <Column alignItems="center" gap={4} transform="translateY(-15px)">
        <RankCircle rank={rank} />
        <Typography fontSize={16} fontFamily="Poppins-Medium">
          #{ticket}
        </Typography>
      </Column>
    </Column>
  );
};
