import {State} from "./types";
import {setRequestStatus} from "@redux/request-status";
import {lotteryWinnerInitialState} from "@redux/initial-states";

export const initialState: State = {
  seen: false,
  winners: lotteryWinnerInitialState,
  request: {
    winners: setRequestStatus("initial"),
  },
};
