import * as Yup from "yup";
import {RecoverPasswordPayload} from "interfaces";

export const initialValues: RecoverPasswordPayload = {
  phone: "",
};

export const recoverPasswordSchema = Yup.object().shape({
  phone: Yup.string().required("Tu teléfono es requerido"),
});
