import {get, getRate} from "./thunks";
import {initialState} from "./initial-state";
import {createSlice} from "@reduxjs/toolkit";
import {setRequestStatus} from "@redux/request-status";

export const configSlice = createSlice({
  name: "config",
  initialState,
  reducers: {},
  extraReducers: function (builder) {
    builder.addCase(get.fulfilled, function (state, {payload}) {
      const {data} = payload;

      state["configs"] = data;
      state["request"]["configs"] = setRequestStatus("success");
    });
    builder.addCase(get.rejected, function (state) {
      state["request"]["configs"] = setRequestStatus("error");
    });

    builder.addCase(getRate.fulfilled, function (state, {payload}) {
      const {data} = payload;

      state["rate"] = data;
      state["request"]["rate"] = setRequestStatus("success");
    });
    builder.addCase(getRate.rejected, function (state) {
      state["request"]["rate"] = setRequestStatus("error");
    });
  },
});

export const {reducer: config} = configSlice;
