import {api} from "api";
import {State} from "./types";
import {createAsyncThunk} from "@reduxjs/toolkit";
import {thunkSuccess} from "@redux/thunk-success";
import {thunkBadRequest} from "@redux/thunk-error";
import {CongratulatePayload, LotteryModel} from "interfaces";

export const get = createAsyncThunk(
  "testimonials/get",
  async (_, {dispatch, rejectWithValue}) => {
    try {
      return await api.Get<State["testimonials"]>(
        "/lottery/getTestimonial?limit=10000&position=0"
      );
    } catch (error) {
      thunkBadRequest(dispatch, error);
      return rejectWithValue(error);
    }
  }
);

export const congratulate = createAsyncThunk(
  "testimonials/congratulate",
  async (
    {lotteryId, awardId, celebrated}: CongratulatePayload,
    {dispatch, rejectWithValue}
  ) => {
    try {
      const {data} = await api.Get<LotteryModel>(
        `/lottery/tocongratulate/${lotteryId}/${awardId}`
      );

      if (celebrated) {
        thunkSuccess(dispatch, {title: "Felicitación enviada con éxito"});
      }
      return data;
    } catch (error) {
      thunkBadRequest(dispatch, error);
      return rejectWithValue(error);
    }
  }
);
