import styled from "styled-components";

export const Href = styled.a`
  width: 38px;
  height: 38px;
  display: flex;
  border-radius: 100;
  align-items: center;
  justify-content: center;
`;
