import {Formik} from "formik";
import {useAppDispatch} from "hooks";
import {useTheme} from "styled-components";
import {useNavigate, useParams} from "react-router-dom";
import {Input, Column, Screen, Button} from "components";
import {initialValues, chagePasswordSchema} from "./form.schema";
import {changePasswordWithCode} from "@redux/slices/auth/thunks";

export const ChangePassword = () => {
  const {primary} = useTheme();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const {phone, code} = useParams() as {phone: string; code: string};

  return (
    <Screen
      bodyStyle={{padding: "1rem"}}
      header={{
        onBack: () => navigate(-1),
        title: "Cambio de contraseña",
      }}
    >
      <Formik
        validateOnChange={true}
        validationSchema={chagePasswordSchema}
        initialValues={{...initialValues, phone, code}}
        onSubmit={(payload) => {
          dispatch(changePasswordWithCode(payload));
        }}
      >
        {({
          dirty,
          values,
          errors,
          isValid,
          touched,
          handleBlur,
          handleSubmit,
          handleChange,
        }) => {
          const disabled = !(dirty && isValid);
          return (
            <Column>
              <Input
                autoFocus
                label="Contraseña"
                value={values["password"]}
                touched={touched["password"]}
                placeholder="***********"
                onBlur={handleBlur("password")}
                helperErrorText={errors["password"]}
                onChange={handleChange("password")}
              />
              <Input
                label="Confirmar contraseña"
                value={values["confirm_password"]}
                touched={touched["confirm_password"]}
                placeholder="***********"
                onBlur={handleBlur("confirm_password")}
                helperErrorText={errors["confirm_password"]}
                onChange={handleChange("confirm_password")}
              />
              <Button
                disabled={disabled}
                label="Cambiar contraseña"
                onClick={() => handleSubmit()}
                loaderId="change-password-with-code"
                style={{backgroundColor: primary, width: "100%"}}
              />
            </Column>
          );
        }}
      </Formik>
    </Screen>
  );
};
