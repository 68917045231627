import {FC} from "react";
import {colors} from "styles";
import {rankColor} from "utils";
import {NotificationModel} from "interfaces";
import {Column, Container, Row, Typography} from "components";
import {
  X,
  Tv,
  Zap,
  Plus,
  Award,
  Check,
  Calendar,
  UserCheck,
  IconProps,
} from "react-feather";

interface NotificationProps {
  notification: NotificationModel;
}

export const Notification: React.FC<NotificationProps> = ({notification}) => {
  const {type, message, read} = notification;

  let backgroundColor: string = colors["secondary"];
  let Icon: FC<IconProps>;

  switch (type) {
    case "friend-referral":
      Icon = UserCheck;
      backgroundColor = colors["black"];
      break;
    case "lottery-ended":
      Icon = Calendar;
      backgroundColor = colors["textDisabled"];
      break;
    case "lottery-hurry-up":
      Icon = Zap;
      backgroundColor = colors["tertiary"];
      break;
    case "lottery-win":
      Icon = Award;
      backgroundColor = rankColor[1];
      break;
    case "new-lottery":
      Icon = Plus;
      backgroundColor = colors["primary"];
      break;
    case "on-live":
      Icon = Tv;
      backgroundColor = colors["error"];
      break;
    case "ticket-purchase-failed":
      Icon = X;
      backgroundColor = colors["error"];
      break;
    default:
      Icon = Check;
      break;
  }

  return (
    <Row
      gap={10}
      padding=".7rem 1.5rem"
      backgroundColor={read ? undefined : colors["unread"]}
    >
      <Container
        width={30}
        height={30}
        borderRadius={100}
        justifyContent="center"
        backgroundColor={backgroundColor}
      >
        <Icon
          width={20}
          height={20}
          strokeWidth={2.5}
          color={colors["white"]}
        />
      </Container>
      <Column width="85%">
        <Typography fontFamily={read ? "Poppins" : "Poppins-Medium"}>
          {message}
        </Typography>
      </Column>
    </Row>
  );
};
